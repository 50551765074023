import { CommonModule, JsonPipe } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import Swal from 'sweetalert2';
import { ReplaceCommasPipe } from '../../../shared/pipes/currency-pipe';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PageId } from '../../../constants/enums';
import {
  DownloadType,
  FormatType,
  GateWayType,
  PaymentStatus,
  POStatusType,
  PurchaseFormatType,
} from '../../../enums/enums';
import { getDateYYYYMMDD } from '../../../utlity/utility';
import { SellerReportsService } from '../services/seller-reports.service';
import { AppLoaderService } from '../../../shared/app-loader/app-loader.service';

@Component({
  selector: 'app-download-reports',
  standalone: true,

  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    MatSelectModule,
    MatCardModule,
    MatTableModule,
    ReplaceCommasPipe,
  ],
  templateUrl: './download-reports.component.html',
  styleUrl: './download-reports.component.scss',
})
export class DownloadReportsComponent extends BaseListComponent {
  formatType: any = Object.values(FormatType).filter(
    (value) => typeof value === 'number'
  );
  gatewayTypes: any = Object.values(GateWayType).filter(
    (value) => typeof value === 'number'
  );
  paymentStatuses: any = Object.values(PaymentStatus).filter(
    (value) => typeof value === 'number'
  );
  DownloadType = DownloadType;
  form: FormGroup;
  dataSource: any;
  maxDate: Date = new Date();
  displayedColumns: string[] = [
    'sellerName',
    'startDate',
    'endDate',
    'sale',
    'return',
    'qwipoCommission',
    'tcs',
    'tds',
    'netPayable',
  ];
  constructor(
    private dialog: MatDialog,
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    private sellerReportService: SellerReportsService,
    private loader: AppLoaderService
  ) {
    super(auth, router, route);
    this.form = this.fb.group({
      srFromDate: '',
      srToDate: '',
      registerType: '',
      formatType: '',
      invFromDate: '',
      invToDate: '',
      status: '',
      invFormatType: '',
      skuFromDate: '',
      skuToDate: '',
      skuFormatType: '',
      prFromDate: '',
      prToDate: '',
      prFormatType: '',
      prrFromDate: '',
      prrToDate: '',
      prrFormatType: '',
      mrFromDate: '',
      mrToDate: '',
      mrFormatType: '',
      collectionFormatType: '',
      collectionToDate: '',
      collectionFromDate: '',
      apFromDate: [null],
      apToDate: [null],
      provider: [null],
      stckFromDate: '',
      stckToDate: '',
      // status: [null],
    });
  }
  override async ngOnInit() {
    await super.ngOnInit(PageId.seller_download);
  }

  async download(type: DownloadType) {
    try {
      this.loader.open();
      if (type === DownloadType.SaleRegister) {
        const fromDate = this.form.value?.srFromDate
          ? getDateYYYYMMDD(new Date(this.form.value.srFromDate))
          : '';
        const toDate = this.form.value?.srToDate
          ? getDateYYYYMMDD(new Date(this.form.value.srToDate))
          : '';
        console.log('formdateee', fromDate);
        console.log('todateee', toDate);
        const formateType = this.form.value.formatType;
        const body = {
          reportType: DownloadType.SaleRegister,
          saleRegister: {
            startDate: fromDate,
            endDate: toDate,
            vendorId: this.vendorId,
            formatType: formateType,
          },
        };
        console.log('body is the ', body);
        const res = await this.sellerReportService.downloadRegisterReports(
          body
        );
        Swal.fire(
          'SUCCESS',
          'Sales Register downloaded successfully',
          'success'
        );
      }

      if (type === DownloadType.AdminProviderReconciliation) {
        const fromDate = this.form.value?.srFromDate
          ? getDateYYYYMMDD(new Date(this.form.value.srFromDate))
          : '';
        const toDate = this.form.value?.srToDate
          ? getDateYYYYMMDD(new Date(this.form.value.srToDate))
          : '';
        const provider = this.form?.get('provider')?.value;
        const status = this.form?.get('status')?.value;
        const body = {
          reportType: DownloadType.AdminProviderReconciliation,
          adminProviderReconciliation: {
            startDate: fromDate,
            endDate: toDate,
            provider: provider,
            status: status,
          },
        };
        console.log(body, 'body.......');

        const res = await this.sellerReportService.downloadRegisterReports(
          body
        );
        Swal.fire(
          'SUCCESS',
          'Admin Provider Reconciliation downloaded successfully',
          'success'
        );
      }

      if (type === DownloadType.CreditNoteRegister) {
        const fromDate = this.form.value?.srFromDate
          ? getDateYYYYMMDD(new Date(this.form.value.srFromDate))
          : '';
        const toDate = this.form.value?.srToDate
          ? getDateYYYYMMDD(new Date(this.form.value.srToDate))
          : '';
        console.log('formdateee', fromDate);
        console.log('todateee', toDate);

        const formateType = this.form.value.formatType;
        const body = {
          reportType: DownloadType.CreditNoteRegister,
          creditNoteRegister: {
            startDate: fromDate,
            endDate: toDate,
            vendorId: this.vendorId,
            formatType: formateType,
          },
        };

        console.log('body is the ', body);
        const res = await this.sellerReportService.downloadRegisterReports(
          body
        );
        Swal.fire(
          'SUCCESS',
          'Credit Note Register downloaded successfully',
          'success'
        );
      }

      if (type === DownloadType.AdminSellerPayout) {
        const fromDate = this.form.value?.srFromDate
          ? getDateYYYYMMDD(new Date(this.form.value.srFromDate))
          : '';
        const toDate = this.form.value?.srToDate
          ? getDateYYYYMMDD(new Date(this.form.value.srToDate))
          : '';
        const body = {
          reportType: DownloadType.AdminSellerPayout,
          adminSellerPayout: {
            startDate: fromDate,
            endDate: toDate,
            vendorId: this.vendorId,
          },
        };
        const res = await this.sellerReportService.downloadRegisterReports(
          body
        );
        Swal.fire(
          'SUCCESS',
          'Admin Seller Payout downloaded successfully',
          'success'
        );
      }

      //InvoiceSettlement

      if (type === DownloadType.InvoiceSettlement) {
        const fromDate = this.form.value?.invFromDate
          ? getDateYYYYMMDD(new Date(this.form.value.invFromDate))
          : '';
        const toDate = this.form.value?.invToDate
          ? getDateYYYYMMDD(new Date(this.form.value.invToDate))
          : '';
        const formateType = this.form.value.invFormatType;
        const body = {
          reportType: DownloadType.InvoiceSettlement,
          invoiceSettlement: {
            startDate: fromDate,
            endDate: toDate,
            vendorId: this.vendorId,
            formatType: formateType,
          },
        };
        console.log('body is the ', body);
        const res = await this.sellerReportService.downloadRegisterReports(
          body
        );
        Swal.fire(
          'SUCCESS',
          'Sales Register downloaded successfully',
          'success'
        );
        // Swal.fire("warning", "Please implement the function to download the Invoice Settlement", "warning")
      }
      if (type === DownloadType.SkuLevelSettlement) {
        const fromDate = this.form.value?.srFromDate
          ? getDateYYYYMMDD(new Date(this.form.value.srFromDate))
          : '';
        const toDate = this.form.value?.srToDate
          ? getDateYYYYMMDD(new Date(this.form.value.srToDate))
          : '';
        const body = {
          reportType: DownloadType.SkuLevelSettlement,
          skuLevelSettlement: {
            startDate: fromDate,
            endDate: toDate,
            vendorId: this.vendorId,
          },
        };
        console.log('body is the ', body);
        const res = await this.sellerReportService.downloadRegisterReports(
          body
        );
        Swal.fire(
          'SUCCESS',
          'Sales Register downloaded successfully',
          'success'
        );
        // Swal.fire("warning", "Please implement the function to download the Invoice Settlement", "warning")
      }

      if (type === DownloadType.AdminCollectionReconciliation) {
        const fromDate = this.form.value?.srFromDate
          ? getDateYYYYMMDD(new Date(this.form.value.srFromDate))
          : '';
        const toDate = this.form.value?.srToDate
          ? getDateYYYYMMDD(new Date(this.form.value.srToDate))
          : '';
        const body = {
          reportType: DownloadType.AdminCollectionReconciliation,
          adminCollectionReconciliation: {
            startDate: fromDate,
            endDate: toDate,
          },
        };
        console.log('body is the ', body);
        const res = await this.sellerReportService.downloadRegisterReports(
          body
        );
        Swal.fire(
          'SUCCESS',
          'AdminCollectionReconciliation downloaded successfully',
          'success'
        );
        // Swal.fire("warning", "Please implement the function to download the Invoice Settlement", "warning")
      }
      if (type === DownloadType.PurchaseRegister) {
        const fromDate = this.form.value?.srFromDate
          ? getDateYYYYMMDD(new Date(this.form.value.srFromDate))
          : '';
        const toDate = this.form.value?.srToDate
          ? getDateYYYYMMDD(new Date(this.form.value.srToDate))
          : '';
        const formateType = this.form.value.formatType;
        const statusType = this.form.value.status;
        if (!fromDate || !toDate) {
          Swal.fire(
            'warning',
            'Please select both From and To dates',
            'warning'
          );
          return;
        }
        if (new Date(fromDate) > new Date(toDate)) {
          Swal.fire('warning', 'Please provide valid date range', 'warning');
          return;
        }

        if (!statusType) {
          Swal.fire('warning', 'Please select a Date Type', 'warning');
          return;
        }
        if (!formateType) {
          Swal.fire('warning', 'Please select a Format Type', 'warning');
          return;
        }

        // Validate that the "From Date" is earlier than or equal to the "To Date"

        const body = {
          reportType: DownloadType.PurchaseRegister,
          purchaseRegister: {
            startDate: fromDate,
            endDate: toDate,
            vendorId: this.vendorId,
            formatType: formateType,
            prStatus: statusType,
          },
        };
        console.log('body is the ', body);
        const res = await this.sellerReportService.downloadRegisterReports(
          body
        );
        Swal.fire(
          'SUCCESS',
          'Purchase Register downloaded successfully',
          'success'
        );
      }
      if (type === DownloadType.PurchaseReturnRegister) {
        const fromDate = this.form.value?.prrFromDate
          ? getDateYYYYMMDD(new Date(this.form.value.prrFromDate))
          : '';
        const toDate = this.form.value?.srToDate
          ? getDateYYYYMMDD(new Date(this.form.value.srToDate))
          : '';
        Swal.fire(
          'warning',
          'Please implement the function to download the Purchase Returns',
          'warning'
        );
      }
      if (type === DownloadType.PurchaseForecast) {
        const body = {
          reportType: DownloadType.PurchaseForecast,
          purchaseForecast: {
            vendorId: this.vendorId,
          },
        };
        console.log('body is the ', body);
        const res = await this.sellerReportService.downloadRegisterReports(
          body
        );
        Swal.fire(
          'SUCCESS',
          'PurchaseForecast downloaded successfully',
          'success'
        );
      }
      if (type == DownloadType.Margin) {
        const fromDate = this.form.value?.srFromDate
          ? getDateYYYYMMDD(new Date(this.form.value.srFromDate))
          : '';
        const toDate = this.form.value?.srToDate
          ? getDateYYYYMMDD(new Date(this.form.value.srToDate))
          : '';
        const body = {
          reportType: DownloadType.Margin,
          skuMargin: {
            startDate: fromDate,
            endDate: toDate,
            vendorId: this.vendorId,
          },
        };
        console.log('body is the ', body);
        const res = await this.sellerReportService.downloadRegisterReports(
          body
        );
        Swal.fire('SUCCESS', 'SKUMargin downloaded successfully', 'success');
      }
      if (type === DownloadType.StockReport) {
        const fromDate = this.form.value?.stckFromDate
          ? getDateYYYYMMDD(new Date(this.form.value.stckFromDate))
          : '';
        const toDate = this.form.value?.stckToDate
          ? getDateYYYYMMDD(new Date(this.form.value.stckToDate))
          : '';
        console.log('frm date is the', fromDate);
        console.log('to date is the', toDate);

        if (!fromDate || !toDate) {
          Swal.fire('warning', 'Please Select the date', 'warning');
          return;
        }

        if (new Date(fromDate) > new Date(toDate)) {
          Swal.fire('warning', 'please give the valid dates', 'warning');
          return;
        }
        const result = await Swal.fire({
          title: 'Would you like to download the Excel file?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        });

        if (!result.isConfirmed) {
          return;
        }

        const body = {
          reportType: DownloadType.StockReport,
          stockReport: {
            startDate: fromDate,
            endDate: toDate,
            vendorId: this.vendorId,
          },
        };
        const res = await this.sellerReportService.downloadRegisterReports(
          body
        );
      }
    } catch (error) {
      this.loader.close();
      Swal.fire('warning', 'Something went wrong', 'warning');
    } finally {
      this.loader.close();
    }
  }

  getFormatTypeName(type: number): string {
    return FormatType[type];
  }

  getPurchaseFormatTypeName(type: number): string {
    return PurchaseFormatType[type];
  }
  getPOStatusTypeName(type: number): string {
    return POStatusType[type];
  }

  getProviderName(type: number): string {
    return GateWayType[type];
  }

  getPaymentStatusName(type: number): string {
    return PaymentStatus[type];
  }
  async clearFilters() {
    this.form = this.fb.group({
      stckFromDate: '',
      stckToDate: '',
    });
  }
}
