<div class="container">
  <form [formGroup]="form">
    <section id="content">

      <mat-card>
        <div class="card-body card-padding">
          <div class="col-md-6 m-3">
            <span class="header">
              <h2 class="title">Net Sale Registry</h2>
            </span>
          </div>
          <div class="row m-3">

            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="netSaleFromDate" placeholder="From Date"
                  [matDatepicker]="appDatepicker007">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker007"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker007></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="netSaleToDate" placeholder="To Date"
                  [matDatepicker]="appDatepicker008">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker008"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker008></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="button-container text-center mb-3">
            <button mat-raised-button color="primary" (click)="downloadNetSale()">Download</button>
          </div>
        </div>
      </mat-card>

      <mat-card>
        <div class="card-body card-padding">
          <div class="col-md-6 m-3">
            <span class="header">
              <h2 class="title">Collection Reconciliation</h2>
            </span>
          </div>
          <div class="row m-3">

            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="crFromDate" placeholder="From Date" [matDatepicker]="appDatepicker5">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker5"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker5></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="crToDate" placeholder="To Date" [matDatepicker]="appDatepicker4">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker4"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker4></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="button-container text-center mb-3">
            <button mat-raised-button color="primary"
              (click)="download(DownloadType.AdminCollectionReconciliation)">Download</button>
          </div>
        </div>
      </mat-card>
      <mat-card class="mt-1">
        <div class="card-body card-padding">
          <div class="col-md-6 m-3">
            <span class="header">
              <h2 class="title">Provider Reconciliation</h2>
            </span>
          </div>
          <div class="row m-3">

            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="prFromDate" placeholder="From Date" [matDatepicker]="appDatepicker">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="prToDate" placeholder="To Date" [matDatepicker]="appDatepicker1">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker1"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker1></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Provider</mat-label>
                <mat-select formControlName="provider">
                  <mat-option *ngFor="let provider of gatewayTypes" [value]="provider">
                    {{getProviderName(provider)}}
                  </mat-option>

                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Status</mat-label>
                <mat-select formControlName="status">
                  <mat-option *ngFor="let status of paymentStatuses" [value]="status">
                    {{ getPaymentStatusName(status) }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>UTR Number</mat-label>
                <input matInput formControlName="utrNumber" name="utrNumber" placeholder="Sku Name" />
              </mat-form-field>
            </div>
          </div>
          <div class="button-container text-center mb-3">
            <button mat-raised-button color="primary"
              (click)="download(DownloadType.AdminProviderReconciliation)">Download</button>
          </div>
        </div>
      </mat-card>

      <mat-card class="mt-1">
        <div class="card-body card-padding">
          <div class="col-md-6 m-3">
            <span class="header">
              <h2 class="title">Providers Transaction Report</h2>
            </span>
          </div>
          <div class="row m-3">

            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="ptStartDate" placeholder="From Date" [matDatepicker]="appDatepicker11">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker11"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker11></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="ptEndDate" placeholder="To Date" [matDatepicker]="appDatepicker10">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker10"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker10></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="button-container text-center mb-3">
            <button mat-raised-button color="primary"
              (click)="download(DownloadType.ProvidersTransactionReport)">Download</button>
          </div>
        </div>
      </mat-card>

      <mat-card class="mt-1">
        <div class="card-body card-padding">
          <div class="col-md-6 m-3">
            <span class="header">
              <h2 class="title">Seller Payout</h2>
            </span>
          </div>
          <div class="row m-3">

            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="sellerPayoutStartDate" placeholder="From Date"
                  [matDatepicker]="appDatepicker70" [max]="maxDate">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker70"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker70></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="sellerPayoutEndDate" placeholder="To Date"
                  [matDatepicker]="appDatepicker71" [max]="maxDate">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker71"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker71></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Status</mat-label>
                <mat-select formControlName="providerPaymentStatus">
                  <mat-option *ngFor="let providerPaymentStatus of sellerPayoutTypes" [value]="providerPaymentStatus">
                    {{sellerPayoutStatus(providerPaymentStatus)}}
                  </mat-option>

                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="button-container text-center mb-3">
            <button mat-raised-button color="primary"
              (click)="download(DownloadType.AdminSellerPayout)">Download</button>
            <button mat-raised-button color="primary" (click)="updateUtr()">Update UTR</button>
          </div>
        </div>
      </mat-card>

      <mat-card class="mt-1">
        <div class="card-body card-padding">
          <div class="col-md-6 m-3">
            <span class="header">
              <h2 class="title">Margins</h2>
            </span>
          </div>
          <div class="row m-3">

            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="marginStartDate" placeholder="From Date"
                  [matDatepicker]="appDatepicker72" [max]="maxDate">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker72"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker72></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="marginEndDate" placeholder="To Date" [matDatepicker]="appDatepicker73"
                  [max]="maxDate">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker73"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker73></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Seller Type</mat-label>
                <mat-select formControlName="marginBusinessType">
                  <mat-option *ngFor="let businessType of marginBusinessTypes" [value]="businessType">
                    {{marginBusinessType(businessType)}}
                  </mat-option>

                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="button-container text-center mb-3">
            <button mat-raised-button color="primary" (click)="download(DownloadType.Margin)">Download</button>
          </div>
        </div>
      </mat-card>

      <mat-card class="mt-1">
        <div class="card-body card-padding">
          <div class="col-md-6 m-3">
            <span class="header">
              <h2 class="title">Seller Modifications Report</h2>
            </span>
          </div>
          <div class="row m-3">

            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="smrFromDate" placeholder="From Date" [matDatepicker]="appDatepicker9"
                  [readonly]="true" [max]="maxDate">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker9"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker9></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="smrToDate" placeholder="To Date" [matDatepicker]="appDatepicker8"
                  [readonly]="true" [max]="maxDate">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker8"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker8></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="button-container text-center mb-3">
            <button mat-raised-button color="primary" (click)="downloadToExcel()">Download</button>
            <button mat-raised-button color="primary" (click)="clearFilters()">Clear Filters</button>
          </div>
        </div>
      </mat-card>

      <mat-card class="mt-1">
        <div class="card-body card-padding">
          <div class="col-md-6 m-3">
            <span class="header">
              <h2 class="title">Customer Insights</h2>
            </span>
          </div>

          <div class="row m-3">
            <div class="col-md-12" style="text-align: center;">
              <button mat-raised-button color="primary"  (click)="download(DownloadType.CustomerInsights)">Download</button>
            </div>

              <!-- <mat-form-field class="example-full-width">
                <mat-label>Select Duration</mat-label>
                <mat-select formControlName="selectedDuration" (selectionChange)="onDurationChange($event.value)">
                  <mat-option *ngFor="let option of durationOptions" [value]="option.value">
                    {{ option.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field> -->
            <!-- <div class="col-md-2"></div>
            <div class="col-md-4 ml-mt">
            <button mat-raised-button color="primary"  (click)="download(DownloadType.CustomerLastOrdersReport)">Download</button>
          </div> -->
          </div>
        </div>
      </mat-card>
      <mat-card class="mt-1">
      <div class="card-body card-padding">
        <div class="col-md-6 m-3">
          <span class="header">
            <h2 class="title">Sellers Stock & Price Details</h2>
          </span>
        </div>

        <div class="row m-3">
          <div class="col-md-12" style="text-align: center;">
            <button mat-raised-button color="primary" (click)="download(DownloadType.SellersStockPrice)">Download</button>
          </div>
        </div>
      </div>
    </mat-card>
    </section>
  </form>
</div>
