import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormBuilder,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  takeUntil,
} from 'rxjs';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import {
  containsOnlyAlphanumeric,
  getDateFormat,
  getDeeplinksType,
  guid,
  isEmptyValue,
} from '../../../utlity/utility';
import Swal from 'sweetalert2';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { AuthService } from '../../../services/auth.service';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { PageId } from '../../../constants/enums';
import {
  DeepLinkType,
  LookUpType,
  SwalMessageTypes,
  Targets,
} from '../../../enums/enums';
import { PromotionsService } from '../../promotion-kpi-trendingsku/services/promotions.service';
import { LookUpTypeService } from '../../../services/lookup-type.service';
import { AppLoaderService } from '../../../shared/app-loader/app-loader.service';
import { NotificationService } from '../services/notification.service';
import imageCompression from 'browser-image-compression';
import { CreateNotification } from '../models/notification.model';

export interface CategoryWiseInput {
  minimumCategory: any | null;
  minimumCategoryDiscountPercent: number;
}
@Component({
  selector: 'app-create-notification',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    RouterLink,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
  ],
  templateUrl: './create-notification.component.html',
  styleUrl: './create-notification.component.scss',
})
export class CreateNotificationComponent extends BaseListComponent {
  dataSource: any;
  deepLinkTypeList: Array<any> = [];
  selectedDeeplinkType: number = 1;
  promotionRes: any = [];
  SearchResults: any[] = [];
  brandsResults: any[] = [];
  vendorResults: any[] = [];
  skuList: Array<any> = [];
  searchPerformed = false;
  merchantList: Array<any> = [];
  merchantSearchList: Array<any> = [];
  deepLinkId: string = '';
  categoryControl = new FormControl();
  brandControl = new FormControl();
  vendorControl = new FormControl();
  skuControl = new FormControl();
  merchantListSearchInput: LookUpType = this.initializeLookUpTypeSearchInput();
  promotionListSearchInput: any;
  previewImage: any;
  uploadedFiles: any = {};
  image_url: string = '';

  constructor(
    private fb: FormBuilder,
    router: Router,
    route: ActivatedRoute,
    auth: AuthService,
    private loader: AppLoaderService,
    private notificationService: NotificationService,
    private lookupService: LookUpTypeService,
    public readonly promotionService: PromotionsService
  ) {
    super(auth, router, route, PageId.bms_notifications);
    this.dataSource = this.fb.group({
      deepLinkType: ['', [Validators.required]],
      promotionSelect: [],
      promoKpiSelect: [],
      selectCategory: [],
      selectBrand: [],
      selectVendor: [],
      selectSku: [],
      title: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  override async ngOnInit() {
    await super.ngOnInit();
    await this.setupBrandSearch();
    await this.setupCategorySearch();
    await this.getMerchants();
    this.deepLinkTypeList = getDeeplinksType();
    this.deepLinkId = guid();
  }

  async onDeeplinkTypeChange(event: any) {
    this.selectedDeeplinkType = event.value;

    switch (this.selectedDeeplinkType) {
      case 1:
        await this.getPromotions(this.selectedDeeplinkType);
        break;
      case 2:
        await this.getPromotions(this.selectedDeeplinkType);
        break;
      case 3:
        await this.setupCategorySearch();
        break;
      case 4:
        await this.setupBrandSearch();
        break;
      case 5:
        await this.getMerchants();
        break;
      case 6:
        await this.setupSkuSearch();
        break;
    }
  }
  async getPromotions(deeplinkid: number) {
    const promotionBody = this.initializeSearchInput(deeplinkid);
    const response = await this.promotionService.getPromotions(promotionBody);
    this.promotionRes = response['promotionList'];
    this.dataSource = this.initializeForm();
  }
  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }
  private initializeSearchInput(deeplinkId: number) {
    this.promotionListSearchInput = {
      promoType: deeplinkId,
      vendorId: [],
      status: true,
      pageNumber: this.pageNumber,
      pageSize: 1000,
    };
    return this.promotionListSearchInput;
  }

  async setupCategorySearch() {
    this.categoryControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter((value) => value && value.length >= 3),
        switchMap((value) => this.searchCategory(value))
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((results: any[]) => {
        this.SearchResults = results;
        this.searchPerformed = true;
      });
    this.dataSource = this.initializeForm();
  }
  async searchCategory(searchTerm: string) {
    const searchText = searchTerm.trim();
    const response = await this.notificationService.getCategories(searchText);
    return response.data;
  }
  async setupBrandSearch() {
    this.brandControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter((value) => value.length >= 3),
        switchMap((value) => this.searchBrand(value))
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((results: any[]) => {
        this.brandsResults = results;
        // this.searchPerformed = true;
      });
    this.dataSource = this.initializeForm();
  }
  async searchBrand(searchTerm: string) {
    const searchText = searchTerm.trim();
    const response = await this.notificationService.getBrands(searchText);
    return response.data;
  }
  async getMerchants() {
    const response = await this.lookupService.getLookUpTypes(
      this.merchantListSearchInput
    );
    this.merchantList = response.data[0].data;
    this.merchantSearchList = response.data[0].data;
    this.dataSource = this.initializeForm();
  }

  private initializeLookUpTypeSearchInput(): LookUpType {
    return {
      tableName: 'vendor',
      lookupType: 'vendor',
      idColumnName: 'id',
      valueColumnName: 'business_name',
    };
  }
  onKey(value: any) {
    const searchKeyword = value.target.value || '';
    this.merchantSearchList = this.search(searchKeyword);
  }
  search(value: string) {
    let filter = value.toLowerCase();
    return this.merchantList.filter((option) =>
      option.value.toLowerCase().includes(filter)
    );
  }
  async setupSkuSearch() {
    this.skuControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter((value) => value.length >= 3),
        switchMap((value) => this.searchSku(value))
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((results: any[]) => {
        this.skuList = results;
        // this.searchPerformed = true;
      });
    this.dataSource = this.initializeForm();
  }
  async searchSku(searchTerm: string) {
    const searchText = searchTerm.trim();
    const response = await this.notificationService.getSkus(searchText);

    return response.data;
  }
  async saveDeeplink() {
    this.loader.open();
    try {
      if (this.dataSource.invalid) {
        this.showMessage(
          'Please fill all required fields.',
          SwalMessageTypes.Warning
        );
        return;
      }

      const isInputValid = this.validateInput();

      if (isInputValid) {

        const deepLink = this.dataSource.value;
        const deepLinkType = deepLink.deepLinkType;
        const deepLinkName = this.getDeepLinkType(deepLinkType);
        let message = '';
        let selectedIds: any = [];
        switch (deepLinkType) {
          case 1:
            selectedIds = [deepLink.promotionSelect];
            message = 'Promotion';
            break;
          case 2:
            selectedIds = [deepLink.promoKpiSelect];
            message = 'Promotion KPI';
            break;
          case 3:
            selectedIds = deepLink.selectCategory ? deepLink.selectCategory : [];
            message = 'Category';
            break;
          case 4:
            selectedIds = deepLink.selectBrand ? deepLink.selectBrand : [];
            message = 'Brands';
            break;
          case 5:
            selectedIds = deepLink.selectVendor ? deepLink.selectVendor : [];
            message = 'Vendor';
            break;
          case 6:
            selectedIds = deepLink.selectSku ? deepLink.selectSku : [];
            message = 'Sku';
            break;
        }
        if (selectedIds.includes(null) || selectedIds.length === 0) {
          this.loader.close();

          this.showMessage(
            `Please select ${message} Ids.`,
            SwalMessageTypes.Warning
          );
          return;
        }
        const notificationImage =
          this.uploadedFiles?.fileName || '';

        if (isEmptyValue(notificationImage)) {
          this.loader.close();
          this.showMessage('Please upload image.', SwalMessageTypes.Warning);
          return;
        }
        await this.uploadFiles();
        const body = {
          type: deepLinkType,
          ids: selectedIds,
          socialMetaTagInfo: {
            socialImageLink: this.image_url ? this.image_url : '',
            socialDescription: deepLink.description,
            socialTitle: deepLink.title,
          },
        };
        const res = await this.notificationService.createDeepLink(body);
        let generatedLInk = res.data;
        if (res && res.success) {
          const today = new Date();
          const expiryDate = new Date(today);
          expiryDate.setDate(today.getDate() + 2);
          const formattedExpiryDate = expiryDate.toISOString().split('T')[0];
          const notificationBody: CreateNotification = {
            title: deepLink.title,
            description: deepLink.description,
            deepLink: generatedLInk,
            type: deepLinkType,
            typeIds: JSON.stringify(selectedIds),
            targetGroup: Targets.ActiveCustomers,
            targetIds: JSON.stringify([]),
            sentCount: 0,
            imageUrl: this.image_url ? this.image_url : '',
            status: true,
            expiryDate: formattedExpiryDate
          }
          await this.notificationService.insertNotifications(notificationBody);
          this.loader.close();

          this.router.navigateByUrl(`/dashboard/notifications`);
          this.showMessage('Notification Created Successfully', SwalMessageTypes.Success);
        }
      }

    } catch (error) {
      console.error('Error occurred during deep link creation:', error);
      this.showMessage('Notification Create Failed', SwalMessageTypes.Warning);
    }
  }

  private validateInput() {
    const title = this.dataSource.get('title').value;
    const description = this.dataSource.get('description').value;

    if (!title) {
      this.loader.close();
      this.showMessage('Please enter title .', SwalMessageTypes.Warning);
      return false;
    }
    //|| containsOnlyAlphanumeric(description)
    if (!description) {
      this.loader.close();
      this.showMessage('Please enter description .', SwalMessageTypes.Warning);
      return false;
    }

    return true;
  }


  getDeepLinkType(status: number): string {
    return DeepLinkType[status];
  }

  private initializeForm() {
    return this.fb.group({
      deepLinkType: this.dataSource.value.deepLinkType,
      promotionSelect: [],
      promoKpiSelect: [],
      selectCategory: [],
      selectBrand: [],
      selectVendor: [],
      selectSku: [],
      title: this.dataSource.value.title ? this.dataSource.value.title : '',
      description: this.dataSource.value.description ? this.dataSource.value.description : '',
    });
  }

  async onFileChange(event: any) {
    if (event.target.files) {
      const file = event.target.files[0];
      const filename = file.name;
      const reader = new FileReader();
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      reader.onload = async (event) => {
        const result = event?.target?.result;
        this.previewImage = result;
        if (typeof result === 'string') {
          const base64String: string = await this.compressAndConvertToBase64(
            file,
            options
          );
          this.uploadedFiles = {
            fileName: "notifications",
            fileExtension: this.getFileExtension(filename),
            fileData: base64String,
          };
        } else if (result instanceof ArrayBuffer) {
          console.error('Unsupported file type');
          // Handle this case if needed
        }
      };

      reader.readAsDataURL(file);
    }
  }

  async compressAndConvertToBase64(file: File, options: any): Promise<string> {
    try {
      const compressedFile = await imageCompression(file, options);
      const base64String: string = await imageCompression.getDataUrlFromFile(
        compressedFile
      );
      return base64String.split(',')[1];
    } catch (error) {
      console.error('Error compressing image:', error);
      throw error;
    }
  }

  private getFileExtension(filename: any) {
    return filename.split('.').pop();
  }

  async uploadFiles() {
    try {
      const requestImageBody = this.uploadedFiles;
      const res: any =
        await this.notificationService.uploadNotificationImageDocs(
          requestImageBody.fileName,
          requestImageBody.fileData,
          requestImageBody.fileExtension
        );
      this.image_url = res.data.url;
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }
}
