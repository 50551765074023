import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import Swal from 'sweetalert2';
import { AuthService } from '../../../../../../services/auth.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseListComponent } from '../../../../../../shared/core/base.list.component';
import { PageId } from '../../../../../../constants/enums';
import { AppStateService } from '../../../../../../services/appstate.service';
import { takeUntil } from 'rxjs';
import { MappedTrip } from '../../../../../../entities/trip-info';
import { convertCasesPicesToPices, getItemQtyInCasesPieces, integerValidator } from '../../../../../../utlity/utility';

interface ProductForm {
  orderedQtyCss: FormControl<number | null>;
  orderedQtyPcs: FormControl<number | null>;
  availableQtyCss: FormControl<number | null>;
  availableQtyPcs: FormControl<number | null>;
  damageQtyCss: FormControl<number | null>;
  damageQtyPcs: FormControl<number | null>;
}

@Component({
  selector: 'app-product-edit',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatIconModule,
    CommonModule,
    FormsModule,
  ],
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss'],
})
export class ProductEditComponent extends BaseListComponent {
  currentTrip: MappedTrip | null = null;
  dataSource: FormGroup<ProductForm>;
  selectedItem!: {
    name: string;
    orderedQty: number;
    upc: number
  };
  isCasesZero: boolean = true;

  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    private appState: AppStateService,
    public dialogRef: MatDialogRef<ProductEditComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      name: string;
      orderedQty: number;
      upc: number
    }
  ) {
    super(auth, router, route, PageId.logistics_trips);
    this.mapToCurrentTrip();
    this.selectedItem = this.data;
    const { pieces, cases } = getItemQtyInCasesPieces(+this.data.upc, +this.data.orderedQty);
    this.isCasesZero = +this.data.upc == 0 ? true : false;
    this.dataSource = this.fb.group({
      orderedQtyCss: new FormControl(cases || 0),
      orderedQtyPcs: new FormControl(pieces || 0),
      availableQtyCss: new FormControl(0, [
        Validators.required,
        integerValidator,
      ]),
      availableQtyPcs: new FormControl(0, [
        Validators.required,
        integerValidator,
      ]),
      damageQtyCss: new FormControl(0, [
        Validators.required,
        integerValidator,
      ]),
      damageQtyPcs: new FormControl(0, [
        Validators.required,
        integerValidator,
      ]),
    });
  }

  override async ngOnInit() {
    super.ngOnInit();

    // Check if requestedQuantity exists before patching
    if (this.dataSource.controls.orderedQtyCss) {
      this.dataSource.patchValue({
        orderedQtyCss: this.dataSource.value.orderedQtyCss, // Assuming requestedQuantity exists
        orderedQtyPcs: this.dataSource.value.orderedQtyPcs,
      });
    }
  }

  private mapToCurrentTrip() {
    this.appState.selectedTrip$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((trip) => {
        if (trip) {
          this.currentTrip = trip;
        } else {
          const localStorageTrip: string | null =
            localStorage.getItem('tripDetails');
          const trip = localStorageTrip && JSON.parse(localStorageTrip);
          this.appState.setSelectedTrip(trip);
          this.currentTrip = trip;
        }
      });
  }

  save() {
    const orderQty = +this.data.orderedQty;

    const availableQtyCss = parseFloat(
      this.dataSource.value.availableQtyCss?.toString() || `0`
    );
    const availableQtyPcs = parseFloat(
      this.dataSource.value.availableQtyPcs?.toString() || `0`
    );

    const damageQtyCss = parseFloat(
      this.dataSource.value.damageQtyCss?.toString() || `0`
    );
    const damageQtyPcs = parseFloat(
      this.dataSource.value.damageQtyPcs?.toString() || `0`
    );

    if (this.checkValidations(availableQtyCss, availableQtyPcs, damageQtyCss, damageQtyPcs)) {
      const availableQty = convertCasesPicesToPices(+availableQtyCss, +availableQtyPcs, this.data.upc);
      const damageQty = convertCasesPicesToPices(+damageQtyCss, +damageQtyPcs, this.data.upc);

      const qty = availableQty + damageQty;

      if (orderQty <= availableQty) {
        Swal.fire(
          '',
          'Available qty value should be lesser than ordered qty value',
          'warning'
        );

        return;
      }

      this.dialogRef.close({
        availableQty,
        damageQty,
      });
    }

  }

  private checkValidations(availableQtyCss: number, availableQtyPcs: number, damageQtyCss: number, damageQtyPcs: number) {


    if (+availableQtyCss < 0) {
      Swal.fire(
        '',
        'Please enter valid Available stock quantity cases',
        'warning'
      );
      return false;
    }

    if (+availableQtyPcs < 0) {
      Swal.fire(
        '',
        'Please enter valid Available stock quantity pieces',
        'warning'
      );
      return false;
    }


    if (+availableQtyCss % 1 !== 0) {
      Swal.fire(
        '',
        'Please enter valid Available stock quantity cases',
        'warning'
      );
      return false;
    }

    if (+availableQtyPcs % 1 !== 0) {
      Swal.fire(
        '',
        'Please enter valid Available stock quantity pieces',
        'warning'
      );
      return false;
    }


    if (+damageQtyCss % 1 !== 0) {
      Swal.fire(
        '',
        'Please enter valid Damage quantity cases',
        'warning'
      );
      return false;
    }


    if (+damageQtyPcs % 1 !== 0) {
      Swal.fire(
        '',
        'Please enter valid Damage quantity pieces',
        'warning'
      );
      return false;
    }

    if (+damageQtyCss < 0) {
      Swal.fire(
        '',
        'Please enter valid Damage quantity cases',
        'warning'
      );
      return false;
    }

    if (+damageQtyPcs < 0) {
      Swal.fire(
        '',
        'Please enter valid Damage quantity pieces',
        'warning'
      );
      return false;
    }



    return true;
  }



}
