<div class="container">
  <form [formGroup]="form">
    <section id="content">
      <mat-card>
        <div class="card-body card-padding">
          <div class="col-md-6 m-3">
            <span class="header">
              <h2 class="title">Sale Register</h2>
            </span>
          </div>
          <div class="row m-3">
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="srFromDate" placeholder="From Date" [matDatepicker]="appDatepicker">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="srToDate" placeholder="To Date" [matDatepicker]="appDatepickers">
                <mat-datepicker-toggle matSuffix [for]="appDatepickers"></mat-datepicker-toggle>
                <mat-datepicker #appDatepickers></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Format Type</mat-label>
                <mat-select formControlName="formatType">
                  <mat-option *ngFor="let status of formatType" [value]="status">
                    {{ getFormatTypeName(status) }}
                  </mat-option>

                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="button-container text-center mb-3">
            <button mat-raised-button color="primary" (click)="download(DownloadType.SaleRegister)">Download</button>
          </div>
        </div>
      </mat-card>

      <mat-card class="mt-1">
        <div class="card-body card-padding">
          <div class="col-md-6 m-3">
            <span class="header">
              <h2 class="title">Credit Note Register</h2>
            </span>
          </div>
          <div class="row m-3">
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="srFromDate" placeholder="From Date" [matDatepicker]="appDatepicker11">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker11"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker11></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="srToDate" placeholder="To Date" [matDatepicker]="appDatepickers12">
                <mat-datepicker-toggle matSuffix [for]="appDatepickers12"></mat-datepicker-toggle>
                <mat-datepicker #appDatepickers12></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Format Type</mat-label>
                <mat-select formControlName="formatType">
                  <mat-option *ngFor="let status of formatType" [value]="status">
                    {{ getFormatTypeName(status) }}
                  </mat-option>

                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="button-container text-center mb-3">
            <button mat-raised-button color="primary"
              (click)="download(DownloadType.CreditNoteRegister)">Download</button>
          </div>
        </div>
      </mat-card>

      <mat-card class="mt-1">
        <div class="card-body card-padding">
          <div class="col-md-6 m-3">
            <span class="header">
              <h2 class="title">Invoice level Settlement Report</h2>
            </span>
          </div>
          <div class="row m-3">
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="invFromDate" placeholder="From Date" [matDatepicker]="appDatepicker1">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker1"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker1></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="invToDate" placeholder="To Date" [matDatepicker]="appDatepicker2">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker2"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker2></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Format Type</mat-label>
                <mat-select formControlName="invFormatType">
                  <mat-option *ngFor="let status of formatType" [value]="status">
                    {{ getFormatTypeName(status) }}
                  </mat-option>

                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="button-container text-center mb-3">
            <button mat-raised-button color="primary"
              (click)="download(DownloadType.InvoiceSettlement)">Download</button>
          </div>
        </div>
      </mat-card>
      <mat-card class="mt-1" style="display:none">
        <div class="card-body card-padding">
          <div class="col-md-6 m-3">
            <span class="header">
              <h2 class="title">SKU level Settlement Report</h2>
            </span>
          </div>
          <div class="row m-3">
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="srFromDate" placeholder="From Date" [matDatepicker]="appDatepicker3">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker3"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker3></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="srToDate" placeholder="To Date" [matDatepicker]="appDatepicker4">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker4"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker4></mat-datepicker>
              </mat-form-field>
            </div>

          </div>
          <div class="button-container text-center mb-3">
            <button mat-raised-button color="primary"
              (click)="download(DownloadType.SkuLevelSettlement)">Download</button>
          </div>
        </div>
      </mat-card>
      <mat-card class="mt-1">
        <div class="card-body card-padding">
          <div class="col-md-6 m-3">
            <span class="header">
              <h2 class="title">Purchase Register</h2>
            </span>
          </div>
          <div class="row m-3">
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="srFromDate" placeholder="From Date" [matDatepicker]="appDatepicker01">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker01"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker01></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="srToDate" placeholder="To Date" [matDatepicker]="appDatepicker02">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker02"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker02></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Date</mat-label>
                <mat-select formControlName="status">
                  <mat-option *ngFor="let status of formatType" [value]="status">
                    {{ getPOStatusTypeName(status) }}
                  </mat-option>

                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Format Type</mat-label>
                <mat-select formControlName="formatType">
                  <mat-option *ngFor="let status of formatType" [value]="status">
                    {{ getPurchaseFormatTypeName(status) }}
                  </mat-option>

                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="button-container text-center mb-3">
            <button mat-raised-button color="primary"
              (click)="download(DownloadType.PurchaseRegister)">Download</button>
          </div>
        </div>
      </mat-card>

      <mat-card class="mt-1">
        <div class="card-body card-padding">
          <div class="col-md-6 m-3">
            <span class="header">
              <h2 class="title">Purchase Forecast</h2>
            </span>
          </div>
          <div class="row m-3"></div>
          <div class="button-container text-center mb-3">
            <button mat-raised-button color="primary"
              (click)="download(DownloadType.PurchaseForecast)">Download</button>
          </div>
        </div>
      </mat-card>

      <!-- we take that to vendor level -->
      <mat-card class="mt-1">
        <div class="card-body card-padding">
          <div class="col-md-6 m-3">
            <span class="header">
              <h2 class="title">Stock Report</h2>
            </span>
          </div>
          <div class="row m-3">

            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="stckFromDate" placeholder="From Date" [matDatepicker]="appDatepicker88"
                  [readonly]="true" [max]="maxDate">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker88"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker88></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="full-width">
                <input matInput formControlName="stckToDate" placeholder="To Date" [matDatepicker]="appDatepicker99"
                  [readonly]="true" [max]="maxDate">
                <mat-datepicker-toggle matSuffix [for]="appDatepicker99"></mat-datepicker-toggle>
                <mat-datepicker #appDatepicker99></mat-datepicker>
              </mat-form-field>
            </div>`
          </div>
          <div class="button-container text-center mb-3">
            <button mat-raised-button color="primary" (click)="download(DownloadType.StockReport)">Download</button>
            <button mat-raised-button color="primary" (click)="clearFilters()">Clear Filters</button>
          </div>
        </div>
      </mat-card>
    </section>
  </form>
</div>