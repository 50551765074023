import { Component, Inject, OnInit } from '@angular/core';
import {
  FormControl,
  FormBuilder,
  FormGroup,
  FormsModule,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PricingService } from '../services/pricing.service';
import { getStatusTypes } from '../../../utlity/utility';

@Component({
  selector: 'app-filter-popup',
  standalone: true,
  imports: [
    MatAutocompleteModule,
    MatDialogModule,
    MatFormFieldModule,
    MatButtonModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatCardModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
  ],
  templateUrl: './pricing-filter.component.html',
  styleUrls: ['./pricing-filter.component.scss'],
})
export class PricingFilterPopupComponent implements OnInit {
  dataSource: FormGroup;
  companies: any[] = [];
  selectedCompanies: any;
  isSearchWithMultiFields: boolean = false;
  isAllFieldsEmpty: boolean = false;
  statuses = getStatusTypes();
  selectedStatusIds: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<PricingFilterPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder,
    private pricingService: PricingService
  ) {
    this.dataSource = this.skuDetails(data);
  }
  ngOnInit() {}

  getStatus() {
    this.selectedStatusIds = this.dataSource.get('status')?.value || [];
  }

  isValidationMet(dataSource: any) {
    return (
      dataSource.value.skuTerm &&
      dataSource.value.skuTerm.trim() !== '' &&
      dataSource.value.company &&
      dataSource.value.company.length > 0
    );
  }
  isValidationEmpty(dataSource: any) {
    const skuTermNotEmpty =
      dataSource.value.skuTerm && dataSource.value.skuTerm.trim() !== '';
    const companyNotEmpty =
      dataSource.value.company && dataSource.value.company.length > 0;

    return skuTermNotEmpty && companyNotEmpty;
  }

  search() {
    this.isAllFieldsEmpty = false;
    this.isSearchWithMultiFields = false;
    if (this.isValidationEmpty(this.dataSource)) {
      this.isAllFieldsEmpty = true;

      return;
    }
    if (this.isValidationMet(this.dataSource)) {
      this.isSearchWithMultiFields = true;

      return;
    }
    this.dialogRef.close({
      searchSkuInput: this.dataSource.value,
      selectedStatusIds:
        this.selectedStatusIds && this.selectedStatusIds.length
          ? this.selectedStatusIds
          : [],
    });
  }

  private skuDetails(skuPriceDetails: any): FormGroup {
    return this.fb.group({
      vendorId: new FormControl(skuPriceDetails.searchSkuInput.vendorId || ''),
      skuTerm: new FormControl(skuPriceDetails.searchSkuInput.skuTerm || ''),
      company: this.selectedCompanies || [[]],
      status: new FormControl(skuPriceDetails.status || ''),
    });
  }
  async onInputChange(event: Event): Promise<void> {
    const vendorId = this.dataSource.value.vendorId;
    const company = (event.target as HTMLInputElement).value;
    this.companies = await this.pricingService.searchCompany(company, vendorId);
  }
  getSelectedCompanies() {
    this.selectedCompanies = this.dataSource.get('company')?.value || [];
  }
}
