import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import Swal from 'sweetalert2';
import { searchSkuVendorInput } from '../../../../../entities/pricing';
import { getStatusTypes } from '../../../../../utlity/utility';

export interface DialogData {
  skuName: string;
  title: string;
  status: string[];
  searchSkuVendorInput: searchSkuVendorInput;
}

@Component({
  selector: 'Margin',
  standalone: true,
  imports: [
    MatAutocompleteModule,
    MatDialogModule,
    MatFormFieldModule,
    MatButtonModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatCardModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
  ],

  templateUrl: './marginpopup.component.html',
  styleUrl: './marginpopup.component.scss',
})
export class MarginpopUpComponent {
  dataSource: any;
  statuses = getStatusTypes();
  selectedStatusIds: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<MarginpopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public fb: FormBuilder
  ) {
    // Ensure status is always an array
    const initialStatus = Array.isArray(this.data?.status)
      ? this.data.status
      : [];

    this.dataSource = this.fb.group({
      skuName: [this.data?.skuName || ''],
      companies_id: [null],
      brands_id: [null],
      categories_id: [null],
      status: [initialStatus],
      page: [1],
      limit: [25],
    });
  }

  ngOnInit() {
    this.dataSource.patchValue(this.data);
  }

  search() {
    this.dialogRef.close(this.dataSource.value);
  }
  clearFilters() {
    this.dataSource.patchValue({
      skuName: '',
      status: [],
    });
    this.dialogRef.close(this.dataSource.value);
  }
  getStatus() {
    this.selectedStatusIds = this.dataSource.get('status')?.value || [];
  }
}
