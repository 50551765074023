@if(hasView){
<div class="container-fluid p-2">
  <mat-card class="p-0">
    <mat-card-content class="p-0">
      <div class="table-container">
        <mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="skuName">
            <mat-header-cell *matHeaderCellDef> Product Name </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="skuName">
              {{ row.skuName }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="totalQuantity">
            <mat-header-cell *matHeaderCellDef>
              Ordered Quantity
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="totalQuantity">
              {{ row.totalQuantity }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="invoiceQty">
            <mat-header-cell *matHeaderCellDef>
              Invoice Quantity
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="invoiceQty">
              {{ row.invoiceQty }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="availableQty">
            <mat-header-cell *matHeaderCellDef>
              Available Quantity
            </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="availableQty">
              {{ row.availableQty }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="cases">
            <mat-header-cell *matHeaderCellDef> Cases </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="cases">
              {{ row.cases }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="pieces">
            <mat-header-cell *matHeaderCellDef> Pieces </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="pieces">
              {{ row.pieces }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="mrp">
            <mat-header-cell *matHeaderCellDef> MRP </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="mrp">
              {{ row.mrp | replaceCommas }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="sellingPrice">
            <mat-header-cell *matHeaderCellDef> Selling Price </mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="sellingPrice">
              {{ row.sellingPrice | replaceCommas }}
            </mat-cell>
          </ng-container>
          <!-- <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef class="mat-column-actions"> Action </mat-header-cell>
            <mat-cell *matCellDef="let row" class="mat-column-actions">
              <div class="d-flex" *ngIf="row.availableQty > 0">
                <button mat-raised-button color="primary" (click)="openEditPopup(row)">Edit</button>
              </div>
            </mat-cell>
          </ng-container> -->

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>
      <mat-paginator
        [length]="totalProductCount"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        (page)="onPageChange($event)"
      ></mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}
