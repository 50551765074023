import { searchSkuInput } from './../../../../entities/pricing';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule, MatPaginator } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSort } from '@angular/material/sort';
import { MarginpopUpComponent } from './margin/marginpopup.component';
import { MarginEditComponent } from './marginEdit/marginEdit.component';
import { ProductService } from '../../../product/services/product.service';
import Swal from 'sweetalert2';
import { MarginImportPop } from './marginimportpopup/marginimportpop.component';
import { SettingService } from '../../services/settings.service';
import { AuthService } from '../../../../services/auth.service';
import { AppLoaderService } from '../../../../shared/app-loader/app-loader.service';
import { PricingService } from '../../../pricing/services/pricing.service';
import { ReplaceCommasPipe } from '../../../../shared/pipes/currency-pipe';
import { calculateSpWithMargin } from '../../../../utlity/utility';

@Component({
  selector: 'app-margin',
  standalone: true,
  templateUrl: './margin.component.html',
  styleUrl: './margin.component.scss',
  imports: [
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    RouterLink,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    MatTableModule,
    MatTableModule,
    MatSidenavModule,
    MatPaginatorModule,
    CommonModule,
    ReplaceCommasPipe,
  ],
})
export class MarginComponent {
  page_id = 'bms_margin';
  permission: any = false;
  displayedColumns = [
    'SKUId',
    'SkuName',
    'brand',
    'company',
    'sellingprice',
    'mrp',
    'margin',
    'status',
    'edit',
  ];
  vendorSkus: any;
  readonly DEFAULT_PAGE_SIZE = 25;
  pageNumber: number = 1;
  pageSize = this.DEFAULT_PAGE_SIZE;
  totalPages: number = 1;
  searchSkuInput: searchSkuInput = this.initializeSearchInput();
  selectedRowData: any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  dataSource: any;
  vendorId: string = '';
  result: any;
  selectedFile: any;
  productRowsWithError: never[] | undefined;
  productsFromExcel: never[] | undefined;
  excelService: any;
  data: any;
  vendor: any;
  defaultStatus: string[] = ['active'];
  skuName: string = '';

  constructor(
    private dialog: MatDialog,
    private productService: ProductService,
    private route: ActivatedRoute,
    private settingService: SettingService,
    private auth: AuthService,
    private egretLoader: AppLoaderService,
    private pricingService: PricingService
  ) {}
  ngOnInit() {
    this.route.paramMap.subscribe(async (params) => {
      const state = history.state;
      this.vendor = state.merchantDetails;
      await this.loadData();
    });
  }

  async loadData() {
    try {
      this.vendorId = this.vendor.id;
      const payLoad = {
        skuName: this.skuName,
        vendorId: this.vendorId,
        status: this.defaultStatus,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };

      console.log('payLoad', payLoad);

      const resultArray: any = await this.productService.getVendorMarginSkus(
        payLoad
      );
      if (resultArray.totalCount == 0) {
        Swal.fire({
          title: 'No records found',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
        this.dataSource = [];
        this.totalPages = 0;
        return;
      }

      const res = this.mapToVendorSku(resultArray['skusList']);

      this.dataSource = res;

      this.totalPages = resultArray['totalCount'];
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } catch (error) {
      console.error('MarginComponent loadData :', error);
    }
  }

  mapToVendorSku(data: any[]) {
    return data.map((item) => ({
      skus_id: item.skus_id,
      name: item.name,
      brand_name: item.brand_name,
      company_name: item.company_name,
      selling_price: item.selling_price || '',
      maximum_retail_price: item.maximum_retail_price || '',
      margin: item.margin,
      status: item.status == 'active' ? 'Active' : 'Inactive',
    }));
  }

  private initializeSearchInput(): searchSkuInput {
    return {
      vendorId: this.vendorId,
      skuTerm: this.skuName,
      status: this.defaultStatus,
      companyIds: [],
    };
  }

  openDialog(): void {
    console.log(' this.searchSkuInput :------', this.searchSkuInput);
    const popUpInput = {
      skuName: this.skuName,
      status: this.defaultStatus,
    };

    const dialogRef = this.dialog.open(MarginpopUpComponent, {
      data: popUpInput,
    });
    dialogRef.afterClosed().subscribe(async (response: any) => {
      if (response) {
        this.defaultStatus = response.status;
        this.skuName = response.skuName;
        const input = {
          vendorId: this.vendorId,
          companyIds: response.companyId,
          skuTerm: response.skuName,
          categoryId: response.categoryId,
          brandId: response.brandId,
          status: response.status,
        };

        this.handleSearchDialogResult(response, input);
      }
    });
  }

  private handleSearchDialogResult(result: any, input: object) {
    this.searchSkuInput = result;
    this.resetPageAndFetchData(input);
  }

  private resetPageAndFetchData(input: any) {
    this.pageNumber = 1;
    this.pageSize = this.DEFAULT_PAGE_SIZE;
    this.paginator.pageIndex = 0;

    this.loadData();
  }

  async getVendorSkus() {
    this.egretLoader.open();

    try {
      const payLoad = {
        skuName: this.skuName,
        vendorId: this.vendorId,
        status: this.defaultStatus,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      const response: any = await this.productService.getVendorMarginSkus(
        payLoad
      );
      if (response.totalCount == 0) {
        Swal.fire({
          title: 'No records found',
          icon: 'warning',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
      this.totalPages = response.totalCount;
      this.dataSource = response.priceList;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.egretLoader.close();
    } catch (error) {
      this.egretLoader.close();
      console.error('Error:', error);
    }
  }
  // mapToSearchInput(searchSkuInputData:any){
  //   return{
  //     vendorId: this.vendorId,
  //     companyIds: [searchSkuInputData.companyId],
  //     skuTerm: searchSkuInputData.skuName,
  //     categoryId: searchSkuInputData.categoryId,
  //     brandId: searchSkuInputData.brandId,
  //   }
  // }
  onPageChange(event: any) {
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.loadData();
  }

  private validateMargin(mrp: number, sp: number, fsp: number) {
    if (mrp == 0) {
      Swal.fire(
        'warning',
        'You can not add Margin with out adding mrp',
        'warning'
      );
      return false;
    }

    if (sp == 0) {
      Swal.fire(
        'warning',
        'You can not add Margin with out adding seeling price',
        'warning'
      );
      return false;
    }
    if (fsp > mrp) {
      Swal.fire(
        'warning',
        'Warning: Selling Price exceeds MRP. Review and adjust Margin to ensure compliance',
        'warning'
      );

      return false;
    }

    return true;
  }

  editPopUp(
    sellingPrice: number,
    mrp: number,
    margin: number,
    skuId: string
  ): void {
    const dialogRef = this.dialog.open(MarginEditComponent, {
      width: '50%',
      data: {
        updateMarginInput: margin,
      },
    });
    dialogRef.afterClosed().subscribe(async (response: any) => {
      if (response) {
        const updatedMargin = response.updatedMarginValue || 0;

        const finalSellingPrice = calculateSpWithMargin(
          +sellingPrice,
          +updatedMargin
        );

        if (!this.validateMargin(mrp, sellingPrice, finalSellingPrice)) {
          return;
        }

        const inputEntity = {
          vendor_id: this.vendorId,
          sku_id: skuId,
          margin: updatedMargin,
        };
        const updateResponse = await this.settingService.updateSkuMargin(
          inputEntity
        );
        await this.loadData();
        Swal.fire('Success', 'Margin Updated Successfully', 'success');
      }
    });
  }

  async updateMarginInDb(margin: number, id: string, skuId: string) {
    const inputEntity = {
      vendor_id: id,
      sku_id: skuId,
      margin: margin || 0,
    };
    const response = await this.settingService.updateSkuMargin(inputEntity);

    Swal.fire('Success', 'Margin Updated Successfully', 'success');
  }

  marginImport(): void {
    const dialogRef = this.dialog.open(MarginImportPop, {
      width: '50%',
      data: this.vendorId,
    });
    dialogRef.afterClosed().subscribe(async (response: any) => {
      await this.loadData();
    });
  }
  private transformInputData(inputData: any) {
    const transformedData = { ...inputData };
    transformedData.vendorId = this.vendorId;
    if (transformedData.skuName == null) {
      delete transformedData.skuName;
    }
    if (transformedData.skuId == null) {
      delete transformedData.brandId;
    }
    if (transformedData.companyId == '' || transformedData.skuName) {
      delete transformedData.companyId;
    }
    if (transformedData.categoryId == null) {
      delete transformedData.categoryId;
    }

    return transformedData;
  }

  async downloadToExcel() {
    try {
      if (this.result == 0) {
        Swal.fire({
          title: 'No records found',
          icon: 'info',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
        return;
      }

      // const result = await Swal.fire({
      //   title: 'Do you want to proceed?',
      //   icon: 'question',
      //   showCancelButton: true,
      //   confirmButtonText: 'Yes',
      //   cancelButtonText: 'No',
      // });

      // if (!result.isConfirmed) {
      //   //add alert
      //   return;
      // }

      const blobData = await this.pricingService.exportMarginReportFilters(
        this.vendorId
      );
      const blob = new Blob([blobData], { type: 'application/xlsx' });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = 'MarginProductsReport.xlsx';
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error in downloadToExcel:', error);
    }
  }
}
