import { FirestoreService } from './../../services/firestore.service';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { RouterOutlet, RouterLink, Router, ActivatedRoute } from '@angular/router';
import { saveAs } from 'file-saver';
import { getDateYYYYMMDD, uuidv4 } from '../../utlity/utility';
import { MatDialog } from '@angular/material/dialog';
import { BulkImportPopUpComponent } from './bulk-import-pop-up/bulk-import-pop-up.component';
import { DB_PATH } from '../../constants/db-path';
import Swal from 'sweetalert2';
import { AppLoaderService } from '../../shared/app-loader/app-loader.service';
import { User } from '@angular/fire/auth';
import { AuthService } from '../../services/auth.service';
import { BehaviorSubject, takeUntil, timestamp } from 'rxjs';
import { BulkImportService } from './services/bulk-import.service';
import { MerchantBusinessType, bulkImportStatus } from '../../enums/enums';
import { ConfigService } from '../config/services/config.service';
import { BaseListComponent } from '../../shared/core/base.list.component';
import { PageId } from '../../constants/enums';
interface ImportData {
  id: string;
  name: string;
}

interface BulkImportEntity {
  jobId: string;
  vendorEmail: string;
  status: string;
  vendorId: string;
  vendorName: string;
  createdDate: string;
  jobType: string;
  fileName: string;
  jobName: string;
  fileUrl: string;
  timeStamp: number;
}



@Component({
  selector: 'app-bulk-import',
  standalone: true,
  imports: [MatFormFieldModule,
    MatButtonModule,
    RouterOutlet,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatCardModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    RouterLink,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    MatTableModule,
    MatTableModule,
    MatSidenavModule,
    MatPaginatorModule,
    CommonModule,
    RouterLink,
    ReactiveFormsModule],
  templateUrl: './bulk-import.component.html',
  styleUrl: './bulk-import.component.scss'
})

export class BulkImportComponent extends BaseListComponent {
  displayedColumns: string[] = ['name', 'download', 'upload'];
  listDisplayedColumns: string[] = ['jobType', 'fileName', 'userName', 'creationDate', 'status', 'action']
  dataSource: any;
  userSubject = new BehaviorSubject<User | null>(null);
  bulkImportList: BulkImportEntity[] = [];
  selectedRowId: string | null = null;
  filterOpen: boolean = false;
  constructor(
    auth: AuthService,
    router: Router,
    private fb: FormBuilder,
    route: ActivatedRoute,
    private dialog: MatDialog,
    private fireStoreService: FirestoreService,
    private egretLoader: AppLoaderService,
    private bulkImportService: BulkImportService,
  ) {
    super(auth, router, route, PageId.seller_bulk_import);
  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.egretLoader.open();
    this.dataSource = new MatTableDataSource<any>(this.getFilteredOptions());
    this.bulkImportList = await this.getAllBulkImportList();
    this.egretLoader.close();
  }


  private getFilteredOptions(): any {
    if (this.hasSellerAdmin && this.hasPricing) {
      return [{ id: '1102', name: 'Price' }];
    }
    if (this.hasSellerAdmin && this.hasInventory) {
      return [{ id: '1103', name: 'Stock' }];
    }
    if (this.hasSellerAdmin && this.BusinessType !== MerchantBusinessType.Inventory && this.BusinessType !== MerchantBusinessType.None) {
      return [{ id: '1101', name: 'Price & Stock' }];
    }
  }

  async getAllBulkImportList() {
    return await this.bulkImportService.getAllBulkImportData(this.vendorId);
  }

  async onDownload(id: number): Promise<void> {
    this.egretLoader.open();
    try {
      let response: Blob;
      let filename;
      switch (+id) {
        case 1101:
          response = await this.bulkImportService.downloadPriceAndStockExcel(this.vendorId);
          console.log("res", response);
          break;
        case 1102:
          response = await this.bulkImportService.downloadPricingExcel(this.vendorId);
          filename = 'Price.xlsx';
          break;
        case 1103:
          response = await this.bulkImportService.downloadStockExcel(this.vendorId);
          filename = 'Stock.xlsx';
          break;
        default:
          throw new Error('Invalid download ID');
      }
      saveAs(response, filename);
      Swal.fire("Success", "Excel File Downloaded Successfully", "success");
    } catch (error) {
      console.log("error in download excdwl :: ", error)
      Swal.fire("Error", "Failed to download Excel file", "error");
    } finally {
      this.egretLoader.close();
    }
  }

  private getCurrentTimestamp() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  getCurrentUnixTimestamp() {
    return Math.floor(Date.now() / 1000);
  }

  async prepareJson(file: any, row: any): Promise<void> {
    const jobId = uuidv4();

    
    if (this.vendor) {
      const insertEntity = {
        jobType: row.name,
        jobId: jobId,
        jobName: row.name,
        status: bulkImportStatus[1],
        vendorId: this.vendorId,
        vendorName: this.vendor.name,
        vendorEmail: this.vendor.email,
        fileName: file.fileName,
        fileUrl: file.excelURL,
        createdDate: this.getCurrentTimestamp(),
        timeStamp: this.getCurrentUnixTimestamp()
      };

      await this.fireStoreService.insertDocWithId(DB_PATH.BULK_IMPORT, jobId, insertEntity);
      this.bulkImportList = await this.getAllBulkImportList();
    }
  }

  openPopUp(row: ImportData): void {
    const dialogRef = this.dialog.open(BulkImportPopUpComponent, {
      width: "50%",
      height: "40%",
      data: {
        title: 'Upload Excel',
        row: row
      }
    });

    dialogRef.afterClosed().subscribe(async (response: any) => {
      if (response) {
        this.egretLoader.open();
        await this.prepareJson(response, row);
        this.egretLoader.close();
        Swal.fire("Success", " Successfully Uploaded", "success");
      }
    });
  }

  selectRow(row: ImportData): void {
    if (this.selectedRowId === row.id) {
      this.selectedRowId = null;
    } else {
      this.selectedRowId = row.id;
    }
  }

  isRowSelected(row: ImportData): boolean {
    return this.selectedRowId === row.id;
  }

  openFilter() {
    this.filterOpen = !this.filterOpen;
  }
  downloadUploadedFile(url: string) {
    window.open(url, '_blank');
  }

}
