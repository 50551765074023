import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CommonModule } from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { OrderService } from '../../../services/orderService.service';
import { AuthService } from '../../../services/auth.service';
import { ConfigService } from '../../config/services/config.service';
import { formatToCustomDate, getDateFormat, getEnumNameByValue } from '../../../utlity/utility';
import { MerchantBusinessType, OrderStatus } from '../../../enums/enums';
import { OrderFilters } from '../../../models/order';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { PageId } from '../../../constants/enums';
import { ReplaceCommasPipe } from "../../../shared/pipes/currency-pipe";
import { takeUntil } from 'rxjs';
import { AppStateService } from '../../../services/appstate.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-order-list-view',
  standalone: true,
  templateUrl: './order-list-view.component.html',
  styleUrl: './order-list-view.component.scss',
  imports: [
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    RouterLink,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    MatTableModule,
    MatTableModule,
    MatSidenavModule,
    MatPaginatorModule,
    CommonModule,
    ReplaceCommasPipe
  ]
})
export class OrderListViewComponent extends BaseListComponent {
  displayedColumns = [
    'createdOrderDate',
    'customerName',
    'customerBusinessName',
    'orderTotal',
    'orderNumber',
    'orderStatus',
    'action'
  ];
  totalOrders: number = 1;
  orderStatusIds: number[] = [OrderStatus.OrderPlaced];
  // searchSkuVendorInput: searchSkuVendorInput = this.initializeSearchInput();
  searchOrderInput: OrderFilters = this.initializeSearchInput();
  selectedRowData: any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  dataSource: any;
  // @Input() inputData: any;
  @Output() summaryEvent = new EventEmitter<any>();
  merchantBusinessTypeEnum: typeof MerchantBusinessType = MerchantBusinessType;
  constructor(
    private orderService: OrderService,
    route: ActivatedRoute,
    router: Router,
    auth: AuthService,
    private appStateService: AppStateService
  ) {
    super(auth, router, route, PageId.seller_orders);
  }
  override async ngOnInit() {
    await super.ngOnInit();
    this.appStateService.selectedOrderFilters$.pipe(takeUntil(this.unsubscribe$)).subscribe(async (value) => {
      //console.log("data passing service:- ", value);
      this.searchOrderInput.vendorId = value?.vendorId || '';
      this.searchOrderInput.startDate = value?.startDate || '';
      this.searchOrderInput.endDate = value?.endDate || '';
      this.searchOrderInput.orderStatusIds = value?.orderStatusIds || [];
      if (this.searchOrderInput.vendorId) {
        await this.loadData();
      }
    });
  }

  async loadData() {
    try {
      const filters = { ...this.searchOrderInput };
      if (this.BusinessType !== this.merchantBusinessTypeEnum.Inventory) {
        delete filters.startDate;
        delete filters.endDate;
      }
      const resultArray = await this.orderService.getOrdersByVendorId(filters);
      const mappedOrders = this.mapToVendorOrder(resultArray);
      this.dataSource = mappedOrders;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.totalOrders = mappedOrders.length > 0 ? mappedOrders[0].totalRows : 0;
      if (this.totalOrders == 0) {
        Swal.fire('Warning!', 'No Orders Found!', 'warning');
        return;
      }
      this.getSummery(mappedOrders);
    } catch (error) {
      console.error('Order list loadData :', error);
    }
  }

  getSummery(items: Array<any>) {
    this.summaryEvent.emit("Calculate Summary from orders");
  }
  sumOfProperty(arr: Array<any>, prop: keyof any): number {
    return arr.reduce((acc, curr) => +acc + +curr[prop], 0);
  }
  private initializeSearchInput(): OrderFilters {
    return {
      endDate: getDateFormat(new Date()),
      startDate: getDateFormat(new Date()),
      pageSize: this.pageSize,
      pageNumber: this.pageNumber,
      vendorClusterIds: [],
      orderStatusIds: [],
      vendorId: this.vendorId || "",
    };
  }
  viewOrderDetails(orderDetail: any) {
    if (orderDetail) {
      this.router.navigate(['/dashboard/vendor-order/order-detail'], {
        state: { orderDetails: orderDetail, from: 'vendor-orders' },
      });
    }
  }

  mapToVendorOrder(data: any[]) {
    return data.map((item) => ({
      orderNumber: item.order_number,
      customerId: item.customer_id,
      customerName: item.customer_name,
      customerBusinessName: item.customer_business_name || '',
      vendorId: item.vendor_id,
      vendorName: item.vendor_name || '',
      orderStatusId: item.order_status_id,
      orderStatus: getEnumNameByValue(OrderStatus, item.order_status_id),
      logisticsStatusId: item.logistics_status_id || '',
      orderTotal: +item.total_amount || 0,
      totalWeight: item.total_weight || undefined,
      orderDate: item.order_date || undefined,
      createdOrderDate: formatToCustomDate(item.order_date) || undefined,
      skuCount: item.sku_count || undefined,
      totalRows: item.total_rows || 0,
    }));
  }
  private async fetchDataAndWrapInArray<T>(promise: Promise<{ data: T }>): Promise<T[]> {
    const response = await promise;
    return Array.isArray(response.data) ? response.data : [response.data];
  }
  override async onPageChange(event: any): Promise<void> {
    await super.onPageChange(event);
    this.searchOrderInput.pageSize = this.pageSize;
    this.searchOrderInput.pageNumber = this.pageNumber;
    await this.loadData();
  }

  extractOrderIdsFromResult(): string[] {
    return this.dataSource?.map((item: { orderNumber: any }) => item.orderNumber) || [];
  }
}
