import { OrderService } from './../../../services/orderService.service';
import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { FormBuilder, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatSortModule } from '@angular/material/sort';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  getDateFormat,
  getDateYYYYMMDD,
  getEnumKeyValueList,
} from '../../../utlity/utility';
import { OrderFilters } from '../../../models/order';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { OrderListViewComponent } from '../order-list-view/order-list-view.component';
import { OrderProductViewComponent } from '../order-product-view/order-product-view.component';
import {
  DownloadBillType,
  MerchantBusinessType,
  OrderStatus,
  OrderStatusFilter,
  TransitMode,
} from '../../../enums/enums';
import { MatDividerModule } from '@angular/material/divider';
import Swal from 'sweetalert2';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { PageId } from '../../../constants/enums';
import { AppStateService } from '../../../services/appstate.service';
import { ReplaceCommasPipe } from '../../../shared/pipes/currency-pipe';

@Component({
  selector: 'app-order-list',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    CommonModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatDialogModule,
    MatFormFieldModule,
    NgSelectModule,
    ReactiveFormsModule,
    MatSortModule,
    MatTableModule,
    RouterLink,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatInputModule,
    FormsModule,
    RouterOutlet,
    MatTabsModule,
    ReplaceCommasPipe,
    MatDividerModule,
    OrderListViewComponent,
    OrderProductViewComponent,
    ReplaceCommasPipe,
  ],
  templateUrl: './order-list.component.html',
  styleUrl: './order-list.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class OrderListComponent extends BaseListComponent {
  pendingOrderLimit: number = 150;
  areaList: Array<any> = [];
  orderStatusList: Array<any> = [];
  orderDate: any;
  orders: any;
  rawOrderResponse: any;
  loading = false;
  dataSource!: MatTableDataSource<any>;
  displayedColumns: string[] = [
    'skuName',
    'totalQuantity',
    'pieces',
    'cases',
    'sellingPrice',
    'action',
  ];
  startDate: string = '';
  endDate: string = '';
  filters: OrderFilters = new OrderFilters();
  totalOrderWeight: number = 0;
  totalOrderAmount: number = 0;
  totalProductCount: number = 0;
  vendorClusterIds: number[] = [];
  orderStatusIds: number[] = [OrderStatus.OrderPlaced];
  defaultOrderStatusIds: number[] = [OrderStatus.OrderPlaced];
  statusOptions: string[] = ['Mark As Delivered', 'Mark As Canceled'];
  generateOptions: string[] = ['Invoice'];
  // downloadOptions: string[] = ['Invoice', 'E-Invoice Json', 'E-Way Bill Json', 'Indent Sheet'];
  downloadOptions: string[] = ['E-Way Bill Json'];

  revenue: number = 0;
  totalWeight: number = 0;
  isDisableFilter: boolean = false;
  today = new Date();
  summary: any;
  filterForm: any;
  @ViewChild('downloadOptionsMenu') downloadOptionsMenu!: MatMenuTrigger;
  @ViewChild('generateOptionsMenu') generateOptionsMenu!: MatMenuTrigger;
  merchantBusinessTypeEnum: typeof MerchantBusinessType = MerchantBusinessType;
  constructor(
    auth: AuthService,
    router: Router,
    private fb: FormBuilder,
    route: ActivatedRoute,
    private orderService: OrderService,
    private appStateService: AppStateService
  ) {
    super(auth, router, route, PageId.seller_orders);

    this.filterForm = this.fb.group({
      areas: [null],
      orderStatus: [this.defaultOrderStatusIds],
      startDate: [null],
      endDate: [null],
    });
  }
  override async ngOnInit() {
    await super.ngOnInit();
    this.orderStatusList = getEnumKeyValueList(OrderStatusFilter);
    if (this.vendorId) {
      await this.getOrderSummary();
    }
  }

  async setSummary(event: any) {
    this.summary = await this.fetchOrdersSummary();
    this.revenue = +(this.summary.sum_total_amount || 0).toFixed(2);
    this.totalWeight = (this.summary.sum_total_weight || 0).toFixed(2);
  }

  async getOrderSummary() {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    this.setFilterDates(yesterday, new Date());
    this.summary = await this.fetchOrdersSummary();
    this.revenue = +this.summary.sum_total_amount;
    this.totalWeight = this.summary.sum_total_weight;
    const placedOrders = parseInt(this.summary?.total_placed_orders) || 0;
    if (
      placedOrders >= this.pendingOrderLimit &&
      this.vendor?.admin_config?.fulfillment?.transitType ==
        TransitMode.VENDOR_DELIVERY
    ) {
      Swal.fire(
        '',
        'You have few pending orders to update the status!',
        'warning'
      );
      const newEndDate = new Date();
      newEndDate.setUTCFullYear(newEndDate.getFullYear() - 1);
      this.setFilterDates(newEndDate, yesterday);
      this.isDisableFilter = true;
      this.summary = await this.fetchOrdersSummary();
      this.revenue = +this.summary.sum_total_amount;
      this.totalWeight = this.summary.sum_total_weight;
    } else {
      this.isDisableFilter = false;
    }
    this.appStateService.setSelectedOrderFilters(this.filters);
  }

  private async fetchOrdersSummary() {
    this.filters.vendorId = this.vendorId;
    this.filters.orderStatusIds = this.defaultOrderStatusIds;
    if (this.BusinessType !== this.merchantBusinessTypeEnum.Inventory) {
      const tempFilters = { ...this.filters };
      delete tempFilters.startDate;
      delete tempFilters.endDate;
      return await this.orderService.getOrdersSummaryByVendorId(tempFilters);
    } else {
      return await this.orderService.getOrdersSummaryByVendorId(this.filters);
    }
  }

  private setFilterDates(startDate: Date, endDate: Date) {
    this.filters.startDate = getDateYYYYMMDD(startDate);
    this.filters.endDate = getDateYYYYMMDD(endDate);
    this.filterForm.get('startDate')?.setValue(startDate);
    this.filterForm.get('endDate')?.setValue(endDate);
  }

  searchOrders() {
    const startDate = this.filterForm.get('startDate')?.value;
    const endDate = this.filterForm.get('endDate')?.value;
    const orderStatusIds = this.filterForm.get('orderStatus')?.value;

    // Update filter values
    this.filters.startDate = getDateYYYYMMDD(startDate);
    this.filters.endDate = getDateYYYYMMDD(endDate);
    this.filters.vendorId = this.vendorId;
    this.filters.orderStatusIds = orderStatusIds;
    if (this.filters.endDate < this.filters.startDate) {
      this.summary = 0;
      this.revenue = 0;
      this.totalWeight = 0;
      Swal.fire({
        icon: 'warning',
        title: 'Invalid Date Range',
        text: 'End date must be greater than or equal to start date.',
      });
    }
    // Emit updated filter values
    this.appStateService.setSelectedOrderFilters(this.filters);
  }

  clearFilters() {
    // Reset form controls
    this.filterForm.get('startDate')?.reset();
    this.filterForm.get('endDate')?.reset();
    this.filterForm.get('orderStatus')?.reset();

    // Reset filter values
    this.filters.startDate = new Date().toISOString();
    this.filters.vendorId = this.vendorId;
    this.filters.orderStatusIds = this.defaultOrderStatusIds;
    this.appStateService.setSelectedOrderFilters(this.filters);
  }

  async onDownloadOptionClick(optionName: string) {
    // if (optionName === 'Indent Sheet') {
    //   //  await this.indentSheet();
    // }
    if (optionName === 'E-Way Bill Json') {
      this.downloadEWayBillJson();
    }
  }

  // async indentSheet() {
  //   const orderNumbers = await this.getOrderNumbersByFilters();
  //   if (orderNumbers.length === 0) {
  //     Swal.fire('', 'Orders not found', 'warning');
  //     return;
  //   }
  //   const request = {
  //     order_numbers: orderNumbers,
  //   };
  //   await this.orderService.indentSheet(request);
  // }

  private async downloadEWayBillJson() {
    try {
      const mappedOrderNumbers = await this.getOrderInvoicedOrderNumbers();
      if (mappedOrderNumbers.length === 0) {
        Swal.fire(
          '',
          `No data found From <b>${getDateFormat(
            new Date()
          )}</b> To <b>${getDateFormat(new Date())}</b>`,
          'warning'
        );
        return;
      }
      const body = {
        orderNumbers: mappedOrderNumbers,
        vendorId: this.vendorId,
        type: 2,
      };
      const response = await this.orderService.downloadEWayBillJson(body);
      const jsonString = JSON.stringify(response, null, 2);
      const blob = new Blob([jsonString], { type: 'application/json' });
      const link = document.createElement('a');
      link.download = 'ewaybill.json';
      link.href = window.URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      Swal.fire('', `Downloaded E Way Bill Json Successfully`, 'success');
    } catch (error) {}
  }

  private async getOrderInvoicedOrderNumbers(): Promise<any[]> {
    const invoiceStatuses = [
      OrderStatus.OrderInvoiced,
      OrderStatus.ReadyForShipment,
      OrderStatus.OutforDelivery,
      OrderStatus.Delivered,
    ];
    const filters = {
      endDate: getDateFormat(new Date()),
      startDate: getDateFormat(new Date()),
      pageSize: 1000,
      pageNumber: 1,
      vendorClusterIds: [],
      orderStatusIds: [
        OrderStatus.OrderInvoiced,
        OrderStatus.ReadyForShipment,
        OrderStatus.OutforDelivery,
        OrderStatus.Delivered,
      ],
      vendorId: this.vendorId || '',
    };
    const resultArray = await this.orderService.getOrdersByVendorId(filters);
    return resultArray
      .filter((order: { order_status_id: OrderStatus }) =>
        invoiceStatuses.includes(order.order_status_id)
      )
      .map((order: { order_number: any }) => order.order_number);
  }

  private async getOrderNumbersByFilters(): Promise<any[]> {
    const filters: OrderFilters = JSON.parse(JSON.stringify(this.filters));
    filters.pageNumber = 1;
    filters.pageSize = 1000;
    const resultArray = await this.orderService.getOrdersByVendorId(filters);
    return (
      resultArray?.map((item: { order_number: any }) => item.order_number) || []
    );
  }
}
