import { Component } from '@angular/core';
import { HeaderComponent } from '../../shared/header/header.component';
import { SidebarComponent } from '../../shared/sidebar/sidebar.component';
import { RouterOutlet } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AuthService } from '../../services/auth.service';

import { PageId } from '../../constants/enums';
import { MerchantBusinessType } from '../../enums/enums';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/dialogs/confirm-dialog/confirm-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { expand } from 'rxjs';
@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    MatSidenavModule,
    HeaderComponent,
    SidebarComponent,
    RouterOutlet,
    MatButtonModule,
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent {
  show: Boolean = false;
  isActiveUser: boolean = true;
  constructor(public authService: AuthService, public dialog: MatDialog) {
    this.authService.permission$.subscribe(() => {
      this.isActiveUser = this.checkVendorState();
      this.loadItems();
    });
  }

  private checkVendorState() {
    if (!this.authService?.vendorData) {
      return true;
    }

    const { business_type, status, admin_config } = this.authService.vendorData;
    const vendorStatus = status === 1;

    if (business_type === MerchantBusinessType.JitVendor) {
      return vendorStatus;
    }

    const vendorVrsMapping = admin_config?.vendorMapping || {};
    const hasValidVendorMapping =
      vendorVrsMapping.apiKey?.length > 0 &&
      vendorVrsMapping.apiSecret?.length > 0 &&
      vendorVrsMapping.apiUrl?.length > 0;

    return vendorStatus && hasValidVendorMapping;
  }

  items: any = []; //this.prepareMenuItems();

  private prepareMenuItems() {
    const sellerOrdersMenuItem = {
      show: false,
      name: 'Orders',
      icon: 'layers',
      queryParams: { refresh: 1 },
      link: '/dashboard/order',
      expand: false,
      id: PageId.seller_orders,
    };

    const companySubMenuItem = {
      name: 'Company',
      icon: 'domain',
      link: '/dashboard/company',
      expand: false,
      id: PageId.bms_company,
    };

    const brandSubMenuItem = {
      name: 'Brand',
      icon: 'local_play',
      link: '/dashboard/brand',
      expand: false,
      id: PageId.bms_brand,
    };

    const categorySubMenuItem = {
      name: 'Category',
      icon: 'category',
      link: '/dashboard/category',
      expand: false,
      id: PageId.bms_category,
    };

    const variantTypeSubMenuItem = {
      name: 'Variant Type',
      icon: 'layers',
      link: '/dashboard/variantType',
      expand: false,
      id: PageId.bms_variant,
    };

    const attributeSubMenuItem = {
      name: 'Attribute',
      icon: 'streetview',
      link: '/dashboard/attribute',
      expand: false,
      id: PageId.bms_attribute,
    };

    const bmsProductSettingMenuItem = {
      name: 'Product Setting',
      icon: 'rule',
      expand: true,
      id: PageId.bms_company,
      sub_two_level: [
        companySubMenuItem,
        brandSubMenuItem,
        categorySubMenuItem,
        variantTypeSubMenuItem,
        attributeSubMenuItem,
      ],
    };

    // const sellerMySkuMenuItem = {
    //   name: 'My SKU',
    //   icon: 'local_parking',
    //   link: '/dashboard/product/vendor_skus',
    //   expand: false,
    //   id: PageId.seller_my_sku,
    // };
    const sellerAddSkuMenuItem = {
      name: 'Add SKU',
      icon: 'layers',
      link: '/dashboard/product/published',
      expand: false,
      id: PageId.seller_add_sku,
    };
    const bmsManageSkuMenuItem = {
      name: 'Manage SKU',
      icon: 'local_parking',
      link: '/dashboard/product/skus/master_skus',
      expand: false,
      id: PageId.bms_manage_sku,
    };
    const sellerMyRequestsMenuItem = {
      name: 'My Requests',
      icon: 'layers',
      link: '/dashboard/my-requests',
      expand: false,
      id: PageId.seller_my_requests,
    };
    const bmsApprovalsMenuItem = {
      name: 'Approvals',
      icon: 'layers',
      link: '/dashboard/pending-approval',
      expand: false,
      id: PageId.bms_approvals,
    };
    const sellerPricingMenuItem = {
      name: 'My SKUs',
      icon: 'star',
      link: '/dashboard/pricing',
      expand: false,
      id: PageId.seller_price_and_stock,
    };
    const bmsProductMenuItem = {
      name: 'Product',
      icon: 'rule',
      expand: true,
      id: PageId.seller_my_sku,
      sub: [
        // sellerMySkuMenuItem,
        sellerAddSkuMenuItem,
        bmsManageSkuMenuItem,
        sellerMyRequestsMenuItem,
        bmsApprovalsMenuItem,
        sellerPricingMenuItem,
        bmsProductSettingMenuItem,
      ],
    };
    const sellerSupplierMenuItem = {
      name: 'Supplier',
      icon: 'layers',
      link: '/dashboard/supplier/supplier-list',
      expand: false,
      id: PageId.seller_suppliers,
    };
    const bmsPrMenuItem = {
      name: 'PO',
      icon: 'star',
      link: '/dashboard/pocreation',
      expand: false,
      id: PageId.seller_pr,
    };

    const inventoryMenuItem = {
      name: 'Inventory',
      icon: 'inventory',
      expand: true,
      id: PageId.seller_suppliers,
      sub: [sellerSupplierMenuItem, bmsPrMenuItem],
    };
    const bulkImportMenuItem = {
      name: 'Bulk Import',
      icon: 'layers',
      link: '/dashboard/bulk-import',
      expand: false,
      id: PageId.seller_bulk_import,
    };
    const bmsIncentiveMenuItem = {
      name: 'Incentives',
      icon: 'show_chart',
      link: '/dashboard/incentives/incentives-list',
      expand: false,
      id: PageId.bms_incentives,
    };
    const bmsPerformanceMenuItem = {
      name: 'Performance',
      icon: 'thumbs_up_down',
      link: '/dashboard/performance/performance-list',
      expand: false,
      id: PageId.bms_performance,
    };
    const bmsTargetsMenuItem = {
      name: 'Targets',
      icon: 'star_half',
      link: '/dashboard/targets/targets-list',
      expand: false,
      id: PageId.bms_targets,
    };

    const bmsIncentivesMenuItem = {
      name: 'Incentives',
      icon: 'unfold_more',
      expand: true,
      id: PageId.bms_incentives,
      sub_two_level: [
        bmsIncentiveMenuItem,
        bmsPerformanceMenuItem,
        bmsTargetsMenuItem,
      ],
    };

    const bmsErrorLogs = {
      name: 'Error Logs',
      icon: 'error',
      link: '/dashboard/error-log',
      expand: false,
      id: PageId.bms_error_logs,
    };

    const bmsProductBulkImport = {
      name: 'Bulk Import',
      icon: 'error',
      link: '/dashboard/product-bulk-import',
      expand: false,
      id: PageId.bms_bulk_import,
    };


    const bmsSupportMenuItem = {
      name: 'Support',
      icon: 'support',
      expand: true,
      id: PageId.bms_bulk_import,
      sub_two_level: [
        bmsProductBulkImport,
        bmsErrorLogs
      ],
    };

    const bmsInsightsMenuItem = {
      name: 'Insights',
      icon: 'star',
      link: '/dashboard/reports/insights-reports',
      expand: false,
      id: PageId.bms_insights,
    };
    const bmsReportsMenuItem = {
      name: 'Reports',
      icon: 'unfold_more',
      expand: true,
      id: PageId.bms_insights,
      sub_two_level: [bmsInsightsMenuItem],
    };
    const bmsCustomerMenuItem = {
      name: 'Customers',
      icon: 'star',
      link: '/dashboard/customers',
      expand: false,
      id: PageId.bms_customers,
    };
    const bmsVendorMenuItem = {
      name: 'Vendor',
      icon: 'star',
      link: '/dashboard/merchant',
      expand: false,
      id: PageId.bms_vendors,
    };
    const bmsOrdersMenuItem = {
      name: 'Order',
      icon: 'layers',
      link: '/dashboard/orders-list',
      expand: false,
      id: PageId.bms_orders,
    };
    const bmsPromotionsMenuItem = {
      name: 'Promotions',
      icon: 'star',
      link: '/dashboard/promotion',
      expand: false,
      id: PageId.bms_promotions,
    };
    const bmsDeepLinks = {
      name: 'Deep Links',
      icon: 'star',
      link: '/dashboard/deep-links',
      expand: false,
      id: PageId.deep_links,
    };

    const bmsNotifications = {
      name: 'Notification',
      icon: 'star',
      link: '/dashboard/notifications',
      expand: false,
      id: PageId.bms_notifications,
    };

    const bmsMasterGeoMenuItem = {
      name: 'Master Geo',
      icon: 'location_city',
      link: '/dashboard/master-geo-list',
      expand: false,
      id: PageId.bms_master_geo,
    };

    const bmsRoleMenuItem = {
      name: 'Roles',
      icon: 'rule',
      link: '/dashboard/roles',
      expand: false,
      id: PageId.bms_roles,
    };
    const bmsUserMenuItem = {
      name: 'Users',
      icon: 'rule',
      link: '/dashboard/users',
      expand: false,
      id: PageId.bms_users,
    };
    const bmsSecurityMenuItem = {
      name: 'Security',
      icon: 'list',
      expand: true,
      id: PageId.bms_roles,
      sub_two_level: [bmsRoleMenuItem, bmsUserMenuItem],
    };

    const bmsAdminMenuItem = {
      name: 'Admin',
      icon: 'admin_panel_settings',
      expand: true,
      id: PageId.bms_customers,
      sub: [
        bmsCustomerMenuItem,
        bmsOrdersMenuItem,
        bmsVendorMenuItem,
        bmsReportsMenuItem,
        bmsPromotionsMenuItem,
        bmsMasterGeoMenuItem,
        bmsIncentivesMenuItem,
        bmsSupportMenuItem,
        bmsSecurityMenuItem,
        bmsDeepLinks,
        bmsNotifications,
      ],
    };
    const sellerDownloadMenuItem = {
      name: 'Download',
      icon: 'rule',
      link: '/dashboard/seller-reports/download-reports',
      expand: false,
      id: PageId.seller_download,
    };

    const sellerReportsMenuItem = {
      name: 'Reports',
      icon: 'rule',
      expand: true,
      id: PageId.seller_download,
      sub: [] as any[],
    };

    if (
      this.authService?.vendorData?.business_type !==
      MerchantBusinessType.JitVendor
    ) {
      sellerReportsMenuItem.sub.push(sellerDownloadMenuItem);
    }

    const sellerShopSetting = {
      name: 'Store',
      icon: 'store',
      link: '/dashboard/shop-setting',
      expand: false,
      id: PageId.seller_shop_settings,
    };
    const sellerCouponsSetting = {
      name: 'Coupons',
      icon: 'local_offer',
      link: '/dashboard/coupon',
      expand: false,
      id: PageId.seller_coupons,
    };

    const sellerSettingMenuItem = {
      name: 'Setting',
      icon: 'settings',
      expand: true,
      id: PageId.seller_shop_settings,
      sub: [sellerShopSetting],
    };

    if (
      this.authService?.vendorData?.business_type !==
      MerchantBusinessType.JitVendor
    ) {
      sellerSettingMenuItem.sub.push(sellerCouponsSetting);
    }

    const planningMenuItem = {
      name: 'Planning',
      icon: 'rule',
      link: '/dashboard/logistics/planning-list',
      expand: false,
      id: PageId.logistics_planning,
    };

    const tripMenuItem = {
      name: 'Trip',
      icon: 'rule',
      link: '/dashboard/logistics/trip-list',
      expand: false,
      id: PageId.logistics_trips,
    };
    const reportsMenuItem = {
      name: 'Customer Returns',
      icon: 'rule',
      link: '/dashboard/logistics/returns-report-list',
      expand: false,
      id: PageId.logistics_reports,
    };
    const logisticsDailyReportsMenuItem = {
      name: 'Daily Reports',
      icon: 'rule',
      link: '/dashboard/logistics/logistic-reports',
      expand: false,
      id: PageId.logistics_reports,
    };

    const logisticsReportsMenuItem = {
      name: 'Reports',
      icon: 'rule',
      expand: true,
      id: '',
      sub_two_level: [logisticsDailyReportsMenuItem],
    };
    const logisticsLocationMenuItem = {
      name: 'Location',
      icon: 'rule',
      link: '/dashboard/logistics/location-list',
      expand: false,
      id: PageId.logistics_location,
    };
    const logisticsClusterMenuItem = {
      name: 'Cluster',
      icon: 'place',
      link: '/dashboard/areas',
      expand: false,
      id: PageId.logistics_clusters,
    };
    const logisticsVehicleMenuItem = {
      name: 'Vehicle',
      icon: 'rule',
      link: '/dashboard/logistics/vehicle-list',
      expand: false,
      id: PageId.logistics_vehicles,
    };
    const logisticsResourceMenuItem = {
      name: 'Resource',
      icon: 'rule',
      link: '/dashboard/logistics/resource-list',
      expand: false,
      id: PageId.logistics_resources,
    };
    const logisticsSuppliersMenuItem = {
      name: 'Supplier',
      icon: 'rule',
      link: '/dashboard/logistics/supplier-list',
      expand: false,
      id: PageId.logistics_suppliers,
    };
    const logisticsSettingsMenuItem = {
      name: 'Settings',
      icon: 'rule',
      expand: true,
      id: PageId.logistics_location,
      sub_two_level: [
        logisticsLocationMenuItem,
        logisticsClusterMenuItem,
        logisticsVehicleMenuItem,
        logisticsResourceMenuItem,
        logisticsSuppliersMenuItem,
      ],
    };
    const logisticsMenuItem = {
      name: 'Logistics',
      icon: 'local_shipping',
      expand: true,
      id: PageId.logistics_planning,
      sub: [
        planningMenuItem,
        tripMenuItem,
        logisticsReportsMenuItem,
        logisticsSettingsMenuItem,
      ],
    };

    let mainMenuItems = [
      sellerOrdersMenuItem,
      bmsProductMenuItem,
      inventoryMenuItem,
      bulkImportMenuItem,
      sellerReportsMenuItem,
      sellerSettingMenuItem,
      bmsAdminMenuItem,
      logisticsMenuItem,
    ];
    mainMenuItems = mainMenuItems.filter(
      (item) =>
        item !== sellerReportsMenuItem ||
        this.authService?.vendorData?.business_type !==
          MerchantBusinessType.JitVendor
    );

    return mainMenuItems;
  }

  private resolveMenuItems() {
    let menuItemsMaster: any = this.prepareMenuItems();
    let permission_obj = this.authService.permission$.value;
    menuItemsMaster.forEach(
      (item: { show: any; id: string | number; sub: any[] }) => {
        item.show =
          permission_obj[item.id]?.view !== undefined
            ? permission_obj[item.id]?.view
            : false;
        if (item.sub) {
          item.show = true;
          // Loop through sub menu items
          item.sub.forEach((subItem) => {
            // Check conditions for sub menu items and update show property
            subItem.show =
              permission_obj[subItem.id]?.view !== undefined
                ? permission_obj[subItem.id]?.view
                : false;
            // Loop through sub level 2 menu items
            if (subItem.sub_two_level) {
              subItem.show = true;
              subItem.sub_two_level.forEach(
                (subLevel2Item: { id: any; name: string; show: boolean }) => {
                  // Check conditions for sub level 2 menu items and update show property
                  subLevel2Item.show =
                    permission_obj[subLevel2Item.id]?.view !== undefined
                      ? permission_obj[subLevel2Item.id]?.view
                      : false;
                }
              );
            }
          });
        }
      }
    );

    this.removeItemsWithFalseSubItems(menuItemsMaster);
    return menuItemsMaster;
  }

  private removeItemsWithFalseSubItems(items: any) {
    for (let i = items.length - 1; i >= 0; i--) {
      const item = items[i];
      if (item.sub && item.sub.length > 0) {
        this.removeItemsWithFalseSubItems(item.sub);
        if (
          item.sub.every(
            (subItem: { show: boolean }) => subItem && subItem.show === false
          )
        ) {
          items.splice(i, 1);
        }
      }
      if (item.sub_two_level && item.sub_two_level.length > 0) {
        if (
          item.sub_two_level.every(
            (subLevel2Item: { show: boolean }) =>
              subLevel2Item && subLevel2Item.show === false
          )
        ) {
          items.splice(i, 1);
        }
      }
      // Remove parent item if both sub and sub_two_level arrays are empty
      if (
        (!item.sub || item.sub.length === 0) &&
        (!item.sub_two_level || item.sub_two_level.length === 0) &&
        item.show === false
      ) {
        items.splice(i, 1);
      }
    }
  }

  private loadItems() {
    if (
      this.authService.permission$ &&
      this.authService.permission$.value &&
      this.authService.isDataReady()
    ) {
      console.log('Auth Completed');
      let menuItems = this.resolveMenuItems();
      this.items = menuItems;
    }
  }

  logout() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Logout Confirmation',
        message: 'Are you sure you want to logout?',
      },
      panelClass: 'custom-dialog-class',
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result == 'yes') {
        this.authService.signOut();
      }
    });
  }
}
