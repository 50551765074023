@if(hasEdit){
<ng-container>
  <div>
    <div class="col-md-12 d-flex justify-content-end">
      <mat-icon (click)="dialogRef.close()" class="closeicon m-3"
        >close</mat-icon
      >
    </div>
  </div>
  <div>
    <div class="search-block">
      <section id="content">
        <div class="card">
          <form [formGroup]="dataSource">
            <div class="row">
              <div class="col-md-6">
                <p
                  style="
                    font-size: 21px;
                    text-align: left;
                    display: inline-block;
                    margin-top: 5px;
                  "
                >
                  {{ selectedItem.name }}
                </p>
              </div>

              <div class="col-md-6" style="text-align: right; margin-top: 5px">
                <span style="color: green"> UPC </span>
                <span style="color: green"> {{ selectedItem.upc }} </span>
              </div>

              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Ordered Qty Css</mat-label>
                  <input
                    type="number"
                    matInput
                    formControlName="orderedQtyCss"
                    name="orderedQtyCss"
                    readonly
                  />
                </mat-form-field>
              </div>

              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Ordered Qty Pcs</mat-label>
                  <input
                    type="number"
                    matInput
                    formControlName="orderedQtyPcs"
                    name="orderedQtyPcs"
                    readonly
                  />
                </mat-form-field>
              </div>

              <div class="col-md-6" *ngIf="!isCasesZero">
                <mat-form-field class="full-width">
                  <mat-label>Available Css</mat-label>
                  <input
                    type="number"
                    matInput
                    formControlName="availableQtyCss"
                    name="availableQtyCss"
                    [readonly]="isCasesZero"
                  />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Available Pcs</mat-label>
                  <input
                    type="number"
                    matInput
                    formControlName="availableQtyPcs"
                    name="availableQtyPcs"
                  />
                </mat-form-field>
              </div>

              @if(currentTrip?.source != 3){
              <div class="col-md-6" *ngIf="!isCasesZero">
                <mat-form-field class="full-width">
                  <mat-label>Damage Css</mat-label>
                  <input
                    type="number"
                    matInput
                    formControlName="damageQtyCss"
                    name="damageQtyCss"
                    [readonly]="isCasesZero"
                  />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Damage Pcs</mat-label>
                  <input
                    type="number"
                    matInput
                    formControlName="damageQtyPcs"
                    name="damageQtyPcs"
                  />
                </mat-form-field>
              </div>
              }
            </div>
            <div class="col-md-12 text-center m-2">
              <button
                mat-raised-button
                color="primary"
                (click)="save()"
                [disabled]="dataSource.invalid"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>
</ng-container>
} @else {
<ng-container>
  <p class="text-center">
    You dont have permission , Please contact administrator
  </p>
</ng-container>
}
