import { DownloadOrdersList, OrderSearchInput, OrderSourceType, OrderStatus, SwalMessageTypes } from './../../../enums/enums';
import { Component, ViewChild, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import Swal from 'sweetalert2';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { OrderSummaryDialogComponent } from '../order-summary-dialog/order-summary-dialog/order-summary-dialog.component';
import { BusinessTypes, OrdersReport, SalesPersonInfo } from '../../../enums/enums';
import { OrderService } from '../service/order.service';
import { AuthService } from '../../../services/auth.service';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { PageId } from '../../../constants/enums';
import { ReplaceCommasPipe } from '../../../shared/pipes/currency-pipe';
import { CustomersService } from '../../customers/services/customers.service';
import { formatToCustomDate, getDateFormat, getDateYYYYMMDD, orderStatus } from '../../../utlity/utility';
import saveAs from 'file-saver';
import { AppStateService } from '../../../services/appstate.service';

interface SearchItem {
  text: string;
  id: number;
  searchText: string;
}

interface Dealer {
  id: string;
  name: string;
}

interface IOrderResponse {
  orders: OrdersReport[];
  queryCount: number;
}


@Component({
  selector: 'app-order-list',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatCheckboxModule,
    ReplaceCommasPipe
  ],
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss'],
})
export class OrdersListComponent extends BaseListComponent {
  readonly DEFAULT_PAGE_SIZE = 25;
  searchItems: SearchItem[] = [];
  orderStatusList = orderStatus();
  orderSearchInput: OrderSearchInput = this.initializeSearchInput();
  ordersList: any;
  totalPages!: number;
  dataSource!: MatTableDataSource<OrdersReport>;
  form!: FormGroup;
  businessTypesList = BusinessTypes;
  selectedBusinessTypes: number[] = [];
  salesPersonList!: SalesPersonInfo[];

  keyLabelMap: { [key: string]: string } = {
    orderNumber: 'Order Number',
    customerId: 'Customer Id',
    businessTypeId: 'Business Type',
    customerMobileNumber: 'Mobile Number',
    dealerId: 'Dealers',
    salesperson: 'Salespersons',
    dateRange: 'Date Range',
    orderStatus: 'Order Status'
  };

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  displayedColumns = ['salesPerson', 'salesPersonMobileNumber', 'customerId', 'customerMobileNumber', 'areaCode', 'businessName',
    'sellerName', 'orderNumber', 'orderAmount', 'orderStatus', 'orderType', 'createdOrderDate', 'deliveredDate', 'cancelledBy', 'cancelledDate', 'action'
  ];
  startDate: any;
  endDate: any;
  selectedSalesperson: string[] = [];
  selectedOrderStatus: number[] = [];
  constructor(
    router: Router,
    auth: AuthService,
    route: ActivatedRoute,
    private readonly orderService: OrderService,
    private readonly dialog: MatDialog,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    public customerService: CustomersService,
    private appState: AppStateService


  ) {
    super(auth, router, route, PageId.bms_orders)
    this.form = this.fb.group({
      orderNumber: '',
      customerId: '',
      businessTypeId: '',
      customerMobileNumber: '',
      salesperson: '',
      startDate: [null],
      endDate: [null],
      orderStatus: '',
    });
  }

  override async ngOnInit() {
    super.ngOnInit()
    this.salesPersonList = await this.customerService.getSalesPersons();
    this.initializeSearchInput();
    await this.getOrders();
  }

  private initializeSearchInput(): OrderSearchInput {
    const searchInput = this.appState.getItem<OrderSearchInput>('adminOrdersFilter') || {
      businessTypeId: [],
      customerMobileNumber: "",
      orderNumber: "",
      customerId: "",
      salesperson: [],
      startDate: '',
      endDate: '',
      orderStatus: [],
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
    };

    this.form = this.fb.group({
      orderNumber: [searchInput.orderNumber || ''],
      customerId: [searchInput.customerId || ''],
      businessTypeId: [searchInput.businessTypeId || []],
      customerMobileNumber: [searchInput.customerMobileNumber ? searchInput.customerMobileNumber.toString().replace(/\s+/g, '') : ''],
      salesperson: [searchInput.salesperson || []],
      startDate: [searchInput.startDate || ''],
      endDate: [searchInput.endDate || ''],
      orderStatus: [searchInput.orderStatus || []],
    });
    return searchInput;
  }


  private mapToSalespersons(): { [key: string]: { salesPersonId: string; salespersonName: string } } {
    return this.salesPersonList.reduce((acc, cur) => {
      acc[cur.id] = {
        salesPersonId: cur.id,
        salespersonName: cur.name
      };
      return acc;
    }, {} as { [key: string]: { salesPersonId: string; salespersonName: string } });
  }



  async getOrders() {
    try {
      const response = await this.orderService.getOrdersList(this.orderSearchInput);
      if (response['orderList'].length == 0) {
        Swal.fire('', "No records found.", 'warning')
      }

      this.ordersList = this.mapToOrdersResponse(response['orderList']).map(order => {
        return {
          ...order,
          orderDate: new Date(order.orderDate).toISOString(),
          deliveryDate: order.deliveryDate ? new Date(order.orderDate).toISOString() : null,
          createdOrderDate: formatToCustomDate(order.orderDate) || undefined,
          deliveredDate: order.deliveryDate ? formatToCustomDate(new Date(order.deliveryDate)) : undefined,
        };
      });
      this.totalPages = response['ordersCount'];
      this.dataSource = this.ordersList;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } catch (error) {
      console.error("Error:", error);
    }
  }
  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }
  search() {
    this.orderSearchInput = this.form.value;

    if (this.form.invalid) {
      this.showMessage('Please Select Date.', SwalMessageTypes.Warning);

      return;
    }
    this.startDate = getDateYYYYMMDD(new Date(this.form.value.startDate));
    this.endDate = getDateYYYYMMDD(new Date(this.form.value.endDate));
    if (new Date(this.startDate) > new Date(this.endDate)) {
      this.showMessage('Please give valid dates', SwalMessageTypes.Warning);
      return;
    }

    const { orderNumber, customerId, customerMobileNumber } = this.form.value;
    this.pageNumber = 1;
    this.pageSize = this.DEFAULT_PAGE_SIZE;
    this.paginator.pageIndex = 0;
    this.orderSearchInput = this.createOrderListSearchInput(
      orderNumber?.trim(),
      customerId?.trim(),
      customerMobileNumber,
      this.startDate,
      this.endDate,
    );
    this.appState.setItem('adminOrdersFilter', this.orderSearchInput);
    this.getOrders()
  }


  private createOrderListSearchInput(orderNumber: string, customerId: string, customerMobileNumber: string, startDate: string, endDate: string): OrderSearchInput {
    console.log("createOrderListSearchInput::", customerMobileNumber);

    return {
      orderNumber: orderNumber || "",
      customerId: customerId || '',
      customerMobileNumber: customerMobileNumber ? customerMobileNumber.toString().replace(/\s+/g, '') : '',
      businessTypeId: this.selectedBusinessTypes,
      salesperson: this.getSelectedSalesPerson(),
      orderStatus: this.getSelectedOrderStatus(),
      startDate: this.form.value.startDate == "" ? "" : startDate,
      endDate: this.form.value.endDate == "" ? "" : endDate,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize
    };
  }

  clearFilters() {
    // Reset the form controls to their default values
    this.appState.clearAll();
    this.form.reset({
      orderNumber: '',
      customerId: '',
      businessTypeId: [],
      customerMobileNumber: '',
      salesperson: [],
      startDate: '',
      endDate: '',
      orderStatus: [],
    });

    // Update the OrderSearchInput to match the cleared form values
    this.orderSearchInput = {
      businessTypeId: [],
      customerMobileNumber: '',
      orderNumber: '',
      customerId: '',
      salesperson: [],
      startDate: '',
      endDate: '',
      orderStatus: [],
      pageNumber: this.pageNumber,  // You may want to reset these values too, depending on your use case
      pageSize: this.pageSize,
    };

    console.log("this.form::", this.form.value);
    console.log("this.orderSearchInput::", this.orderSearchInput);

    // Fetch orders with the cleared filters
    this.getOrders();
  }


  private mapToOrdersResponse(response: any[]) {

    const salespersons = this.mapToSalespersons();

    console.log("salespersons :----", salespersons)

    const res = response.map((item) => ({
      orderNumber: item.order_number,
      salesPersonName: salespersons[item.sales_person_id]?.salespersonName,//item.sales_person_name,
      salesPersonMobile: item.sales_person_mobile,
      salesPersonId: item.sales_person_id,
      customerAreaCode: item.customer_area_code,
      customerBusinessName: item.customer_business_name,
      customerMobile: item.customer_mobile,
      totalAmount: Math.round(item.total_amount * 100) / 100,
      orderDate: item.order_date,
      deliveryDate: item.delivery_date ? new Date(item.delivery_date) : null,
      orderStatusId: item.order_status_id,
      customerId: item.customer_id,
      orderSourceType: item.order_placed_by,
      vendorName: item.vendor_name,
      cancelledBy: item.user_name,
      cancelledDate: item.event_log_created_at == null ? '' : formatToCustomDate(item.event_log_created_at),
    }));

    return res;
  }

  getSelectedBusinessTypes() {
    this.selectedBusinessTypes = this.form.get("businessTypeId")?.value || [];
  }

  getSelectedSalesPerson() {
    return this.selectedSalesperson = this.form.get("salesperson")?.value || [];
  }

  getSelectedOrderStatus() {
    return this.selectedOrderStatus = this.form.get("orderStatus")?.value || [];
  }


  override async onPageChange(event: any): Promise<void> {
    await super.onPageChange(event);
    this.orderSearchInput.pageNumber = this.pageNumber;
    this.orderSearchInput.pageSize = this.pageSize;
    await this.getOrders();
  }


  async downloadToExcel() {
    try {
      const result = await Swal.fire({
        title: 'Would you like to download the Excel file?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });

      if (!result.isConfirmed) {
        return;
      }
      if (this.ordersList.length > 0) {
        console.log("this.orderSearchInput", this.orderSearchInput);
        const body = {
          orderNumber: this.orderSearchInput.orderNumber,
          customerId: this.orderSearchInput.customerId,
          businessTypeId: this.orderSearchInput.businessTypeId,
          customerMobileNumber: this.orderSearchInput.customerMobileNumber,
          salesperson: this.orderSearchInput.salesperson,
          startDate: this.orderSearchInput.startDate,
          endDate: this.orderSearchInput.endDate,
          orderStatus: this.orderSearchInput.orderStatus,

        }
        const response = await this.orderService.downloadOrderList(body)
        const filename = "Orders.xlsx"
        saveAs(response, filename);
        Swal.fire('', 'Downloaded orders Successfully', 'success');

      } else {
        Swal.fire('', 'No Data Found', 'warning');
      }
    } catch (error) {
      console.error('Error in downloadToExcel:', error);
      this.snackBar.open('Failed to export Orders', 'Dismiss', { duration: 3000 });
      // this.egretLoaderclose();
    }
  }

  navigateToOrderDetails(orderDetail: any) {
    if (orderDetail) {
      this.appState.setItem('adminOrdersFilter', this.orderSearchInput);
      this.router.navigate(['/dashboard/vendor-order/order-detail'], {
        state: { orderDetails: orderDetail, from: 'order-details' },
      });
    }
  }

  async orderSummaryPopUp() {
    const title = 'Orders Summary';
    const dialogRef = this.dialog.open(OrderSummaryDialogComponent, {
      width: '60%',
      height: '60%',
      disableClose: true,
      data: {
        title: title,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        searchFields: this.orderSearchInput,
        salesPersonList: this.salesPersonList
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //this.handleSearchDialogResult(result);
      }
    });
  }

  getOrderSourceType(value: number): string {
    switch (value) {
      case (OrderSourceType.CUSTOMER):
        return 'Customer';
      case (OrderSourceType.SALES_PERSON):
        return 'Salesperson';
      default:
        return 'Unknown Type';
    }
  }

  getOrderStatus(value: number): string {
    switch (value) {
      case (OrderStatus.OrderPlaced):
        return 'Order Placed';
      case (OrderStatus.OrderInvoiced):
        return 'Order Invoiced';
      case (OrderStatus.ReadyForShipment):
        return 'Ready For Shipment';
      case (OrderStatus.OutforDelivery):
        return 'Out For Delivery';
      case (OrderStatus.Delivered):
        return 'Delivered';
      case (OrderStatus.Cancelled):
        return 'Cancelled';
      //Todo add remaining order statuses
      default:
        return 'Unknown Type';
    }
  }
}
