<div>
  <div class="row m-0">
    <div class="col p-0">
      <h2 class="card-title m-3 text-violet">Pricing History</h2>
    </div>
    <div class="col text-right p-0">
      <mat-icon (click)="dialogRef.close()" class="m-3 closeicon">
        close
      </mat-icon>
    </div>
  </div>
  <div class="content">
    <mat-card class="p-0">
      <mat-card-content class="p-0">
        <mat-sidenav-container fxFlex="0 1 auto" style="height: 100%;">
          <div class="table-container" style="overflow: auto; height: 100%;">
            <mat-table [dataSource]="dataSource" *ngIf="dataSource.length > 0" matSort style="width: 100%;">
            <ng-container matColumnDef="sNo">
              <mat-header-cell *matHeaderCellDef>S.No </mat-header-cell>
              <mat-cell *matCellDef="let row; let i = index" data-label="sNo">
                {{ i + 1 }}.
              </mat-cell>
            </ng-container>
              <ng-container matColumnDef="dateTime">
                <mat-header-cell *matHeaderCellDef> Date & Time </mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="dateTime">
                  {{row.createdAt}}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="sellingPrice">
                <mat-header-cell *matHeaderCellDef>Selling Price
                </mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="sellingPrice">
                  {{row.sellingPrice}}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="mrp">
                <mat-header-cell *matHeaderCellDef>MRP
                </mat-header-cell>
                <mat-cell *matCellDef="let row" data-label="mrp">
                  {{row.maximumRetailPrice}}
                </mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
          </div>
        </mat-sidenav-container>
      </mat-card-content>
    </mat-card>
    <div class="text-bottom" *ngIf="dataSource.length === 0">
      <div > <h2>Pricing history data not found.</h2> </div>
    </div>
    <div class="text-center m-3">
      <button mat-raised-button color="primary" (click)="close()">Ok</button>
    </div>
  </div>
</div>
