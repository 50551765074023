export const config = {
  fileUrl: 'https://file-service.dev.bms.qwipo.com/api',
  backendUrl: 'https://item-service.dev.bms.qwipo.com/api',
  pricingApiUrl: 'https://pricing.dev.bms.qwipo.com/api',
  coreServiceUrl: 'https://core.dev.bms.qwipo.com',
  geoUrl: 'https://geo.dev.bms.qwipo.com/api/v1',
  logisticsUrl: 'https://logistics.dev.bms.qwipo.com',
  inventoryUrl: 'https://inventory.dev.bms.qwipo.com',
  cloudFunctionsUrl: 'https://us-central1-devqwipo.cloudfunctions.net',
  ssoUrl: 'https://sso.dev.bms.qwipo.com',
  messagingService: 'https://messaging-service.dev.bms.qwipo.com/api',
  bmsESApiUrl: 'https://es.dev.bms.qwipo.com/api',
  cacheServiceApiUrl: 'https://cache.dev.bms.qwipo.com',
  ordersServiceUrl: 'https://orders.dev.bms.qwipo.com/api',
  suggestionsApi: 'https://ml.dev.bms.qwipo.com/sku_name_recommendations',
  authServiceApiUrl: 'https://auth.dev.bms.qwipo.com',

  firebaseConfig: {
    apiKey: 'AIzaSyDvil7FitRz5FvCvqV_elC9e9877TtImMU',
    authDomain: 'devqwipo.firebaseapp.com',
    databaseURL: 'https://devqwipo.firebaseio.com',
    projectId: 'devqwipo',
    storageBucket: 'devqwipo.appspot.com',
    messagingSenderId: '95302609868',
    appId: '1:95302609868:web:57d524c8593ac77f2ecb69',
    measurementId: 'G-2G1N0P1XC9',
  },
};

export const testConfig = {
  fileUrl: 'https://file-service.test.bms.qwipo.com/api',
  backendUrl: 'https://item-service.test.bms.qwipo.com/api',
  pricingApiUrl: 'https://pricing.test.bms.qwipo.com/api',
  coreServiceUrl: 'https://core.test.bms.qwipo.com',
  geoUrl: 'https://geo.test.bms.qwipo.com/api/v1',
  logisticsUrl: 'https://logistics.test.bms.qwipo.com',
  inventoryUrl: 'https://inventory.test.bms.qwipo.com',
  cloudFunctionsUrl: 'https://us-central1-test-qwipo.cloudfunctions.net',
  ssoUrl: 'https://sso.test.bms.qwipo.com',
  messagingService: 'https://messaging-service.test.bms.qwipo.com/api',
  bmsESApiUrl: 'https://es.test.bms.qwipo.com/api',
  cacheServiceApiUrl: 'https://cache.test.bms.qwipo.com',
  ordersServiceUrl: 'https://orders.test.bms.qwipo.com/api',
  suggestionsApi: 'https://ml.test.bms.qwipo.com/sku_name_recommendations',
  authServiceApiUrl: 'https://auth.test.bms.qwipo.com',

  firebaseConfig: {
    apiKey: 'AIzaSyCQxan-AN2bk2GTyav2PfKU2raT_QYlNiA',
    authDomain: 'test-qwipo.firebaseapp.com',
    databaseURL: 'https://test-qwipo.firebaseio.com',
    projectId: 'test-qwipo',
    storageBucket: 'test-qwipo.appspot.com',
    messagingSenderId: '1002423689795',
    appId: '1:1002423689795:web:368c953cccebfe247b2af6',
    measurementId: 'G-V0VVNGXRZ9',
  },
};

export const stagingConfig = {
  fileUrl: 'https://file-service.staging.bms.qwipo.com/api',
  backendUrl: 'https://item-service.staging.bms.qwipo.com/api',
  pricingApiUrl: 'https://pricing.staging.bms.qwipo.com/api',
  coreServiceUrl: 'https://core.staging.bms.qwipo.com',
  geoUrl: 'https://geo.staging.bms.qwipo.com/api/v1',
  logisticsUrl: 'https://logistics.staging.bms.qwipo.com',
  inventoryUrl: 'https://inventory.staging.bms.qwipo.com',
  cloudFunctionsUrl:
    'https://us-central1-staging-qwipo-2015.cloudfunctions.net',
  ssoUrl: 'https://sso.staging.bms.qwipo.com',
  messagingService: 'https://messaging-service.staging.bms.qwipo.com/api',
  bmsESApiUrl: 'https://es.staging.bms.qwipo.com/api',
  cacheServiceApiUrl: 'https://cache.staging.bms.qwipo.com',
  ordersServiceUrl: 'https://orders.staging.bms.qwipo.com/api',
  suggestionsApi: 'https://ml.staging.bms.qwipo.com/sku_name_recommendations',
  authServiceApiUrl: 'https://auth.staging.bms.qwipo.com',

  firebaseConfig: {
    apiKey: 'AIzaSyDDMgqsy8EwbRHE7mRzO6jvZX0hwNXGJcs',
    authDomain: 'staging-qwipo-2015.firebaseapp.com',
    databaseURL: 'https://staging-qwipo-2015-default-rtdb.firebaseio.com',
    projectId: 'staging-qwipo-2015',
    storageBucket: 'staging-qwipo-2015.appspot.com',
    messagingSenderId: '1016985034148',
    appId: '1:1016985034148:web:8a64c128f2aaa815c47531',
    measurementId: 'G-RKTLZXSC6K',
  },
};

export const prodConfig = {
  fileUrl: 'https://files.bms.qwipo.com/api',
  backendUrl: 'https://items.bms.qwipo.com/api',
  pricingApiUrl: 'https://pricing.bms.qwipo.com/api',
  coreServiceUrl: 'https://core.bms.qwipo.com',
  geoUrl: 'https://geo.bms.qwipo.com/api/v1',
  logisticsUrl: 'https://logistics.bms.qwipo.com',
  inventoryUrl: 'https://inventory.bms.qwipo.com',
  cloudFunctionsUrl: 'https://us-central1-qwipo-82b7a.cloudfunctions.net',
  ssoUrl: 'https://sso.bms.qwipo.com',
  messagingService: 'https://messaging-queue.bms.qwipo.com/api',
  bmsESApiUrl: 'https://es.bms.qwipo.com/api',
  cacheServiceApiUrl: 'https://cache.bms.qwipo.com',
  ordersServiceUrl: 'https://orders.bms.qwipo.com/api',
  suggestionsApi: 'https://ml.bms.qwipo.com/sku_name_recommendations',
  authServiceApiUrl: 'https://auth.bms.qwipo.com',

  firebaseConfig: {
    apiKey: 'AIzaSyDbV-CkDA4kFmMdmjgl_MSWIfqUh9YNSpE',
    authDomain: 'qwipo-82b7a.firebaseapp.com',
    databaseURL: 'https://qwipo-82b7a.firebaseio.com',
    projectId: 'qwipo-82b7a',
    storageBucket: 'qwipo-82b7a.appspot.com',
    messagingSenderId: '719176192291',
    appId: '1:719176192291:web:0bd89bef14d848cc2a3c96',
    measurementId: 'G-BGYZX6JL0R',
  },
};
