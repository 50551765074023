import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { guid } from '../../../utlity/utility';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { LookUpTypeService } from '../../../services/lookup-type.service';
import { CommonModule } from '@angular/common';
import {
  ChargeType,
  DistributeCharges,
  ExpenseType,
  LookUpType,
  SwalMessageTypes,
} from '../../../enums/enums';
import { PurchaseReceiptService } from '../service/purchasereceipt.service';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
} from 'rxjs/operators';
import { AuthService } from '../../../services/auth.service';
import { ConfigService } from '../../config/services/config.service';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { PageId } from '../../../constants/enums';
import Swal from 'sweetalert2';
import { EditExpense } from '../models/purchasereceipt-model';

@Component({
  selector: 'app-add-expenses',
  standalone: true,
  imports: [
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatCardModule,
    MatTableModule,
    FormsModule,
    MatOptionModule,
    RouterLink,
    CommonModule,
    ReactiveFormsModule,
  ],
  templateUrl: './add-expenses.component.html',
  styleUrl: './add-expenses.component.scss',
})
export class AddExpensesComponent extends BaseListComponent {
  form: FormGroup;
  selectedExpensesType: number | undefined;
  subFilters: any;
  filters: any;
  setSupplier: any;
  prId: string = '';
  supplierId: string = '';
  supplierSearchControl = new FormControl();
  selectedSupplier: any;
  supplierSearchResults: any[] = [];
  searchPerformed: boolean = false;
  expenseType: number = 0;
  ExpenseType = ExpenseType;
  supplierNames: LookUpType = this.initializeSuppliers();
  public suppliersList: any[] = [];
  supplerTdsPercent: number = 0;
  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    private lookupService: LookUpTypeService,
    public authService: AuthService,
    private configService: ConfigService,
    public purchaseReceiptService: PurchaseReceiptService
  ) {
    super(auth, router, route, PageId.seller_pr);
    this.form = this.fb.group({
      expensesType: [null, Validators.required],
      supplierName: [null, Validators.required],
      tdsPercentage: [null],
      tdsValue: [null],
      rate: [null],
      chargeType: [{ value: null, disabled: true }, Validators.required],
      distributeCharge: [null, Validators.required],
      discountPercent: [null],
    });
  }
  expenses: { name: string; value: ExpenseType }[] = [
    { name: 'Loading', value: ExpenseType.Loading },
    { name: ' Unloading', value: ExpenseType.Unloading },
    { name: 'Transport', value: ExpenseType.Transport },
    { name: ' Brokerage', value: ExpenseType.Brokerage },
    { name: 'Discount', value: ExpenseType.Discount },
    { name: 'Purchase Credit', value: ExpenseType.PurchaseCredit },
    { name: 'Loading By Buyer', value: ExpenseType.LoadingByBuyer },
  ];
  charges: { name: string; value: ChargeType }[] = [
    { name: 'Total Value', value: ChargeType.TotalValue },
    { name: ' RatePerKg', value: ChargeType.RatePerKg },
  ];
  distributeCharges: { name: string; value: DistributeCharges }[] = [
    { name: 'Quantity', value: DistributeCharges.Quantity },
    { name: ' Weight', value: DistributeCharges.Weight },
  ];

  override async ngOnInit() {
    await super.ngOnInit();
    this.setupSupplierSearch();
    this.route.params.subscribe(async (params) => {
      this.prId = params['id'];
      this.supplierId = params['supplierId'];
    });
    await this.getSuppliers();
  }

  setupSupplierSearch() {
    this.supplierSearchControl.valueChanges
      .pipe(
        debounceTime(300), // Wait for 300 milliseconds after the last keystroke
        distinctUntilChanged(), // Only emit distinct values
        filter((value) => value.length >= 3), // Filter out search terms less than 3 characters
        switchMap((value) => this.searchSupplier(value)) // Perform the search and switch to the latest observable
      )
      .subscribe((results: any[]) => {
        this.supplierSearchResults = results;
        const selectSupplier = this.form.get('supplierName')?.value;
        if (!selectSupplier) {
          this.supplerTdsPercent;
        }
        this.searchPerformed = true;
      });
  }
  async searchSupplier(searchTerm: string) {
    const response = await this.purchaseReceiptService.supplierSearch(
      searchTerm,
      this.vendorId
    );
    console.log('response', response);
    return response;
  }

  async onSupplierSelectionChange(event: MatSelectChange) {
    // this.selectedSupplier = event.value;
    const supplier = event.value;
    this.selectedSupplier = await this.purchaseReceiptService.getSupplier(
      supplier
    );
    if (
      this.selectedSupplier &&
      this.selectedSupplier.tcsPercentage !== undefined
    ) {
      this.form
        .get('tdsPercentage')
        ?.setValue(this.selectedSupplier.tcsPercentage);
    }
  }

  async save() {
    try {
      if (this.form.invalid) {
        Swal.fire(
          'warning',
          'please enter the All mandatory fields',
          'warning'
        );

        return;
      }
      if (!this.validations()) {
        console.log('hiiiiii');

        return;
      }
      const charge_type = this.form.get('chargeType')?.value;
      const distributeCharge = this.form.get('distributeCharge')?.value;
      const expense = this.form.value;
      const supplier = this.form.get('supplierName')?.value;
      const expenseDetails = this.mapToExpense(
        expense,
        charge_type,
        distributeCharge,
        supplier
      );

      const res =
        await this.purchaseReceiptService.createPurchaseReceiptExpenses(
          expenseDetails
        );
      this.router.navigateByUrl(
        `/dashboard/pocreation/edit-purchase-order/${this.prId}`
      );
    } catch (error) {
      throw error;
    }
  }

  private validations(): boolean {
    const negativeFields = this.validateNegativeValues(this.form.value);

    if (negativeFields.length) {
      this.showMessage(
        `${negativeFields} ,have negative values Please Check`,
        SwalMessageTypes.Warning
      );

      return false;
    }
    if (this.form.value.expensesType === ExpenseType.Discount) {
      if (
        this.form.value.rate == null &&
        this.form.value.discountPercent == null
      ) {
        this.showMessage(
          `Please give the either Rate or Discount percent`,
          SwalMessageTypes.Warning
        );
        return false;
      }
    } else if (this.form.value.rate <= 0) {
      this.showMessage(
        ` Rate should be greater than zero`,
        SwalMessageTypes.Warning
      );
      return false;
    }

    return true;
  }
  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  private validateNegativeValues(data: EditExpense): string[] {
    const numericInputs = [
      { name: 'Rate', value: data.rate },
      { name: 'Tds Value', value: data.tdsValue },
      { name: 'Tds Percentage', value: data.tdsPercentage },
      { name: 'Discount Percentage', value: data.discountPercent },
    ];

    const negativeFields = numericInputs
      .filter((input) => input.value < 0)
      .map((input) => input.name);

    return negativeFields;
  }

  onExpensesTypeChange() {
    const selectedExpenseType = this.form.get('expensesType')?.value;
    this.expenseType = selectedExpenseType;
    console.log('selected expense type is', this.expenseType);

    const chargeTypeValue =
      selectedExpenseType === ExpenseType.Loading ||
      selectedExpenseType === ExpenseType.Unloading ||
      selectedExpenseType === ExpenseType.Brokerage ||
      selectedExpenseType === ExpenseType.LoadingByBuyer
        ? ChargeType.RatePerKg
        : ChargeType.TotalValue;

    const chargeTypeControl = this.form.get('chargeType');
    chargeTypeControl?.setValue(chargeTypeValue);
    chargeTypeControl?.disable();
    const masterDistribution: {
      [key in ExpenseType]: {
        enable: boolean;
        distributionCharges: DistributeCharges;
      };
    } = {
      [ExpenseType.Loading]: {
        enable: true,
        distributionCharges: DistributeCharges.Quantity,
      },
      [ExpenseType.LoadingByBuyer]: {
        enable: true,
        distributionCharges: DistributeCharges.Quantity,
      },
      [ExpenseType.Unloading]: {
        enable: true,
        distributionCharges: DistributeCharges.Quantity,
      },
      [ExpenseType.Brokerage]: {
        enable: false,
        distributionCharges: DistributeCharges.Weight,
      },
      [ExpenseType.Transport]: {
        enable: true,
        distributionCharges: DistributeCharges.Quantity,
      },
      [ExpenseType.Discount]: {
        enable: false,
        distributionCharges: DistributeCharges.Quantity,
      },
      [ExpenseType.PurchaseCredit]: {
        enable: true,
        distributionCharges: DistributeCharges.Weight,
      },
    };
    const distributionSettings =
      masterDistribution[selectedExpenseType as ExpenseType];
    const distributionTypeControl = this.form.get('distributeCharge');

    distributionTypeControl?.enable();
    if (!distributionSettings.enable) {
      distributionTypeControl?.setValue(
        distributionSettings.distributionCharges
      );
      distributionTypeControl?.disable();
    }
    const supplierNameControl = this.form.get('supplierName');
    const tdsControl = this.form.get('tdsPercentage');
    if (
      selectedExpenseType === ExpenseType.Loading ||
      selectedExpenseType === ExpenseType.Discount ||
      selectedExpenseType === ExpenseType.LoadingByBuyer
    ) {
      supplierNameControl?.setValue(this.supplierId);
      // this.setSupplier = this.getSupplierNameByID(this.supplierId);

      // tdsControl?.setValue(this.supplerTdsPercent);

      if (selectedExpenseType === ExpenseType.LoadingByBuyer) {
        tdsControl?.setValue(0);
      } else {
        this.searchPerformed = false;
        this.supplierSearchControl.setValue('');
        this.supplierSearchResults = [];
        tdsControl?.setValue(this.supplerTdsPercent);
      }

      supplierNameControl?.disable();
    } else {
      supplierNameControl?.enable();
    }
    if (selectedExpenseType === ExpenseType.PurchaseCredit) {
      supplierNameControl?.setValue(this.supplierId); // Need to create the qwipo supplier and bind here
      // this.setSupplier = this.getSupplierNameByID(this.supplierId);
      supplierNameControl?.disable();
    } else {
      // supplierNameControl?.enable();
    }
  }

  mapToExpense(
    expense: any,
    charge_type: number,
    distributeCharges: number,
    supplier: string
  ) {
    return {
      expense_id: guid(),
      receipt_id: this.prId,
      expense_type: expense.expensesType,
      total_value: expense.rate || 0,
      supplier_id: supplier,
      distribute_based_on: distributeCharges,
      charge_type: charge_type,
      tds_percent: expense.tdsPercentage || 0,
      tds_value: expense?.tdsValue || 0,
      discount_percent: expense?.discountPercent || 0,
      payout: 0,
    };
  }
  goBack() {
    this.router.navigateByUrl(
      `/dashboard/pocreation/edit-purchase-order/${this.prId}`
    );
  }
  async getSuppliers() {
    const response = await this.lookupService.getLookUpTypes(
      this.supplierNames
    );
    this.suppliersList = response.data[0].data;
    const supplier = await this.purchaseReceiptService.getSupplier(
      this.supplierId
    );
    this.supplerTdsPercent = supplier.tcsPercentage;
    console.log('supplier is the', this.supplerTdsPercent);
  }
  private initializeSuppliers(): LookUpType {
    return {
      tableName: 'suppliers',
      lookupType: 'suppliers',
      idColumnName: 'Id',
      valueColumnName: 'Name',
    };
  }
  getSupplierNameByID(supplierId: string) {
    for (const supplier of this.suppliersList) {
      if (supplier.id == supplierId) {
        return supplier;
      }
    }
    return null;
  }
}
