export const MESSAGE_QUEUE_TYPE = {
  VENDOR_GEO_MAPPING: 'VendorGeoMapping',
  CREATE_COUPON: 'CreateCoupon',
  DELETE_COUPON: 'DeleteCoupon',
  CREATE_PROMOTION: 'CreatePromotion',
  CREATE_SUPPLIER: 'CreateSupplier',
  UPDATE_SUPPLIER: 'UpdateSupplier',
  GENERATE_TRIPS: 'GenerateDeliveryTrips',
  CREATE_COMPANY: 'CompanyCreated',
  STOCK_UPDATE: 'StockUpdate',
  GENERATE_INVOICE_BY_TRIP_ID: 'GenerateInvoiceByTrips',
  NOTIFICATIONS_PROVIDER: 'NotificationsProvider',

};
