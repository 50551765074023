import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Auth } from '@angular/fire/auth';
import { AuthService } from '../../../services/auth.service';
import { lastValueFrom } from 'rxjs';
import {
  Firestore,
  addDoc,
  collection,
  collectionData,
  deleteDoc,
  doc,
  docData,
  getDoc,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from '@angular/fire/firestore';
import { CommonService } from '../../../services/common.service';
import {} from '@angular/fire/firestore';
import { getDocs } from 'firebase/firestore';
import {
  getMarginListResponse,
  getSkuListResponse,
  searchSkuInput,
} from '../../../entities/pricing';
import { VendorMarginSearch } from '../../settings/vendor-list/margin/models/companies.model';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  auth: Auth = inject(Auth);
  db: Firestore = inject(Firestore);
  constructor(
    public http: HttpClient,
    public commonService: CommonService,
    public authService: AuthService
  ) {}

  upload(body: any) {
    return this.http.put(
      `${environment.fileUploadBaseUrl}/images/product`,
      body
    );
  }
  uploadData(data: any) {
    return this.http.post(`${environment.backendUrl}/import`, data);
  }
  exportData(data: any) {
    return this.http.get(
      `${environment.backendUrl}/export?${this.objectToQueryString(data)}`,
      { responseType: 'blob' }
    );
  }

  exportVariantValues(data: any) {
    return this.http.get(
      `${environment.backendUrl}/export?${this.objectToQueryString(data)}`,
      { responseType: 'blob' }
    );
  }

  async addSKU(docId: any, data: any) {
    let aDoc = doc(this.db, `pending-approval/${docId}`);
    let product: any = ((await getDoc(aDoc)).data() as any).payload;
    if (!product.skus) {
      product.skus = [];
    }
    product.skus.push(data);
    console.log(product);
    await this.updatePendingApprovalDoc(docId, product);
    return true;
  }

  async deleteSku(docId: any, id: string) {
    let aDoc = doc(this.db, `pending-approval/${docId}`);
    let product: any = ((await getDoc(aDoc)).data() as any).payload;
    let index = product.skus.findIndex((item: any) => {
      return item.id == id;
    });
    product.skus.splice(index, 1);
    await this.updatePendingApprovalDoc(docId, product);
  }

  async updateSku(docId: any, id: string, data: any) {
    let aDoc = doc(this.db, `pending-approval/${docId}`);
    let product: any = ((await getDoc(aDoc)).data() as any).payload;
    let index = product.skus.findIndex((item: any) => {
      return item.id == id;
    });
    product.skus[index] = { ...product.skus[index], ...data };
    await this.updatePendingApprovalDoc(docId, product);
  }

  getAllDraftProduct() {
    let colRef = collection(this.db, 'pending-approval');
    let q = query(
      colRef,
      where('createdBy', '==', this.authService.userSubject.value?.uid)
    );
    q = query(q, where('status', 'in', ['draft', 'rejected']));
    q = query(q, where('type', 'in', ['add-product', 'update-product']));
    return collectionData(q, { idField: 'id' });
  }

  getMyDraftSkus() {
    let colRef = collection(this.db, 'pending-approval');
    let q = query(colRef, orderBy('lastUpdatedAt', 'desc'));
    q = query(
      q,
      where('createdBy', '==', this.authService.userSubject.value?.uid)
    );
    q = query(q, where('status', 'in', ['draft', 'rejected']));
    q = query(
      q,
      where('type', 'in', [
        'add-sku',
        'update-sku',
        'add-sku-to-catalog',
        'update-sku-to-catalog',
      ])
    );
    return collectionData(q, { idField: 'id' });
  }

  getAllCompanies() {
    return this.http.get(`${environment.backendUrl}/companies`);
  }

  getAllBrandsByCompany(companyId: any) {
    return this.http.get(
      `${environment.backendUrl}/brands?companies_id=${companyId}`
    );
  }

  getAllCategories() {
    return this.http.get(`${environment.backendUrl}/categories`);
  }
  getAllRootCategories() {
    return this.http.get(`${environment.backendUrl}/categories?root=true`);
  }

  getAllVariantTypes() {
    return this.http.get(`${environment.backendUrl}/variant_types`);
  }

  getAllVariantTypesByCategory(categories_id: any) {
    return this.http.get(
      `${environment.backendUrl}/variant_types?categories_id=${categories_id}`
    );
  }

  getAllAttributes() {
    return this.http.get(`${environment.backendUrl}/attribute_types`);
  }

  getAllCountries() {
    return this.http.get(`${environment.backendUrl}/countries`);
  }

  getAllTaxCategories() {
    return this.http.get(`${environment.backendUrl}/tax_categories`);
  }

  getAllVariantValues(variantId: any) {
    return this.http.get(
      `${environment.backendUrl}/variant_values?variants_id=${variantId}`
    );
  }

  publishProduct(body: any) {
    return this.http.post(`${environment.backendUrl}/upsert_product`, body);
  }

  getAllPublishedProducts(queryObj: any) {
    return this.http.get(
      `${environment.backendUrl}/all_products?${this.objectToQueryString(
        queryObj
      )}`
    );
  }

  getAllMasterSkus(queryObj: any) {
    return this.http.get(
      `${environment.backendUrl}/skus?${this.objectToQueryString(queryObj)}`
    );
  }

  getAllVendorSkus(queryObj: any) {
    const vendorId = this.authService.VendorId || this.authService.UserId;
    console.log(
      'url',
      `${
        environment.backendUrl
      }/vendor_skus?vendor_id=${vendorId}&${this.objectToQueryString(queryObj)}`
    );
    return this.http.get(
      `${
        environment.backendUrl
      }/vendor_skus?vendor_id=${vendorId}&${this.objectToQueryString(queryObj)}`
    );
  }

  upsertSkuToVendorSkuList(payload: any) {
    return this.http.post(
      `${environment.backendUrl}/upsert_sku_in_vendor_only`,
      payload
    );
  }
  // Update Product
  updateProduct(id: any, payload: any) {
    return lastValueFrom(
      this.http.patch(`${environment.backendUrl}/products/${id}`, payload)
    );
  }

  getSinglePublishedProducts(product_id: any) {
    return this.http.get(
      `${environment.backendUrl}/all_products?products_id=${product_id}`
    );
  }

  deletePublishedProduct(id: any) {
    return this.http.delete(`${environment.backendUrl}/delete_product/${id}`);
  }
  async getAllSkusByVendorId(vendorId: any): Promise<any[]> {
    const url = `${environment.backendUrl}/get_all_by_vendor/${vendorId}`;
    try {
      const response = await this.http.get<any>(url).toPromise();
      const responseData = response?.data || [];

      return responseData;
    } catch (error) {
      console.error('Error fetching vendor SKUs:', error);
      return [];
    }
  }

  async getVendorSkus(id: string, pageNumber: number, pageSize: number) {
    const inputObj = {
      vendorId: id,
    };
    const url = `${environment.backendUrl}/get_by_vendor_filters?pageNum=${pageNumber}&pageSize=${pageSize}`;

    const response: any = await lastValueFrom(this.http.post(url, inputObj));

    return {
      skusList: response.data.rows,
      totalCount: response.data.count,
    };
  }

  getVendorProduct(productId: any) {
    const vendorId = this.authService.VendorId || this.authService.UserId;
    return this.http.get(
      `${environment.backendUrl}/all_vendor_products?products_id=${productId}&vendor_id=${vendorId}&page=1&limit=10`
    );
  }

  getDraftSkuDocsForVendor(products_id: any) {
    let colRef = collection(this.db, `pending-approval`);
    let q = query(colRef, where('payload.product.id', '==', products_id));
    q = query(
      q,
      where('status', 'in', ['draft', 'pending-approval', 'rejected'])
    );
    q = query(
      q,
      where('createdBy', '==', this.authService.userSubject.value?.uid)
    );
    return collectionData(q, { idField: 'id' });
  }

  getDraftSkuDocsForAdmin(products_id: any) {
    let colRef = collection(this.db, `pending-approval`);
    let q = query(colRef, where('payload.product.id', '==', products_id));
    q = query(
      q,
      where('status', 'in', ['draft', 'pending-approval', 'rejected'])
    );
    q = query(
      q,
      where('createdBy', '==', this.authService.userSubject.value?.uid)
    );
    return collectionData(q, { idField: 'id' });
  }

  getPublishedProduct(productId: any) {
    return this.http.get(
      `${environment.backendUrl}/all_products?products_id=${productId}&page=1&limit=10`
    );
  }

  getMasterSkuFromProduct(productId: any) {
    return this.http.get(
      `${environment.backendUrl}/skus?products_id=${productId}`
    );
  }

  getVendorSkuFromProduct(productId: any) {
    const vendorId = this.authService.VendorId || this.authService.UserId;
    return this.http.get(
      `${environment.backendUrl}/vendor_skus?products_id=${productId}&vendor_id=${vendorId}`
    );
  }

  getSingleMasterSkuData(skuId: any) {
    return this.http.get(
      `${environment.backendUrl}/skus?id=${skuId}&page=1&limit=10`
    );
  }

  getSingleVendorSkuData(skuId: any) {
    return this.http.get(
      `${environment.backendUrl}/vendor_skus?id=${skuId}&page=1&limit=10`
    );
  }

  upsertSku(data: any) {
    console.log(data);
    return this.http.post(`${environment.backendUrl}/upsert_sku_v2`, data);
  }

  async addToPendingApproval(type: any, payload: any, status = 'draft') {
    let colRef = collection(this.db, `pending-approval`);
    let data: any = {
      type: type,
      createdBy: this.authService.userSubject.value?.uid,
      createdAt: new Date(),
      status: status,
      payload: payload,
      lastUpdatedAt: new Date(),
      vendor_name: this.authService.userData.email_id,
    };

    let res = await addDoc(colRef, data);
    if (status == 'pending-approval') {
      data.approval_doc_id = res.id;
      this.addNotification(
        'admin',
        'request',
        this.commonService.getNotify('you have a new approval request', data),
        data
      );
    }
    return res.id;
  }

  async updatePendingApprovalDoc(id: any, payload: any) {
    let status = 'draft';
    let aDoc = doc(this.db, `pending-approval/${id}`);
    return updateDoc(aDoc, {
      status: status,
      payload: payload,
      lastUpdatedAt: new Date(),
    });
  }

  async reSubmitRequestForApproval(id: any, payload: any) {
    let status = 'pending-approval';
    let aDoc = doc(this.db, `pending-approval/${id}`);
    await updateDoc(aDoc, {
      status: status,
      payload: payload,
      vendor_name: this.authService.userData.email_id,
      lastUpdatedAt: new Date(),
      createdBy: this.authService.userSubject.value?.uid,
    });
    let newDocData = (await getDoc(aDoc)).data() as any;
    newDocData.approval_doc_id = id;
    await this.addNotification(
      'admin',
      'request',
      this.commonService.getNotify('Request for approval', newDocData),
      newDocData
    );
  }

  async setPendingApprovalDoc(id: any, payload: any, status = 'draft') {
    let aDoc = doc(this.db, `pending-approval/${id}`);
    return updateDoc(aDoc, {
      status: status,
      payload: payload,
      lastUpdatedAt: new Date(),
    });
  }

  addCompany(data: any) {
    return this.http.post(`${environment.backendUrl}/companies`, data);
  }

  addBrand(data: any) {
    return this.http.post(`${environment.backendUrl}/brands`, data);
  }

  addVariantType(data: any) {
    return this.http.post(`${environment.backendUrl}/variant_types`, data);
  }

  addVariantValue(data: any) {
    return this.http.post(`${environment.backendUrl}/variant_values`, data);
  }

  addCategory(data: any) {
    return this.http.post(`${environment.backendUrl}/categories`, data);
  }

  addAttribute(data: any) {
    return this.http.post(`${environment.backendUrl}/attribute_types`, data);
  }

  async approveRequest(docData: any) {
    try {
      console.log(docData);
      if (
        docData.type == 'add-sku-to-catalog' ||
        docData.type == 'update-sku-to-catalog' ||
        docData.type == 'add-sku' ||
        docData.type == 'update-sku'
      ) {
        let res: any = await lastValueFrom(this.upsertSku(docData.payload));
        res.data.forEach((item: any) => {
          if (item.type == 'vendor_skus') {
            this.addToQueueForImageProcessing({
              sku_id: item.id,
              table: 'vendor',
            });
          }
          if (item.type == 'master_skus') {
            this.addToQueueForImageProcessing({
              sku_id: item.id,
              table: 'master',
            });
          }
        });
      }
      if (docData.type == 'add-company') {
        await lastValueFrom(this.addCompany(docData.payload));
      }
      if (docData.type == 'add-brand') {
        await lastValueFrom(this.addBrand(docData.payload));
      }
      if (docData.type == 'add-attribute') {
        await lastValueFrom(this.addAttribute(docData.payload));
      }
      if (docData.type == 'add-variant-type') {
        await lastValueFrom(this.addVariantType(docData.payload));
      }
      if (docData.type == 'add-variant-value') {
        await lastValueFrom(this.addVariantValue(docData.payload));
      }
      if (docData.type == 'add-category') {
        await lastValueFrom(this.addCategory(docData.payload));
      }
      await this.changeApprovalRequestStatus(docData, 'approved');
      await this.addNotification(
        docData.createdBy,
        'published',
        this.commonService.getNotify('your request is approved', docData),
        docData
      );
    } catch (err) {
      throw 'error';
    }
  }

  getSingleApprovalDoc(id: any) {
    let aDoc = doc(this.db, `pending-approval/${id}`);
    return docData(aDoc, { idField: 'id' });
  }

  changeApprovalRequestStatus(docData: any, status: any) {
    let aDoc = doc(this.db, `pending-approval/${docData.id}`);
    return updateDoc(aDoc, { status: status, lastUpdatedAt: new Date() });
  }

  rejectApprovalRequest(data: any, comment: any) {
    let aDoc = doc(this.db, `pending-approval/${data.id}`);
    data.admin_name = this.authService.userData.email_id;
    this.addNotification(
      data.createdBy,
      'reject',
      this.commonService.getNotify('your request is rejected', data),
      data
    );
    return updateDoc(aDoc, {
      status: 'rejected',
      comment: 'Your request is rejected :' + comment,
      lastUpdatedAt: new Date(),
    });
  }

  discardPendingDoc(id: any) {
    let aDoc = doc(this.db, `pending-approval/${id}`);
    return deleteDoc(aDoc);
  }

  getAllPendingDocs() {
    let colRef = collection(this.db, `pending-approval`);
    let q = query(colRef, where('status', '==', 'pending-approval'));
    q = query(q, orderBy('lastUpdatedAt', 'desc'));
    return collectionData(q, { idField: 'id' });
  }
  async getSinglePendingDoc(id: any) {
    let aDoc = doc(this.db, `pending-approval/${id}`);
    return await getDoc(aDoc);
  }
  getMyPendingDocs() {
    let colRef = collection(this.db, `pending-approval`);
    // let q = query(colRef, where("status", "==", "pending-approval"))
    let q = query(
      colRef,
      where('createdBy', '==', this.authService.userSubject.value?.uid)
    );
    q = query(q, orderBy('lastUpdatedAt', 'desc'));
    return collectionData(q, { idField: 'id' });
  }

  getSkuFromProduct(product: any, skuId: any) {
    return product.skus.find((sku: any) => {
      return sku.id == skuId;
    });
  }

  addNotification(to: any, type: any, message: any, payload: any) {
    let colRef = collection(this.db, `notifications`);
    addDoc(colRef, {
      to: to,
      type: type,
      message: message,
      payload: payload,
      timestamp: new Date(),
    });
  }

  getAdminNotifications() {
    let colRef = collection(this.db, `notifications`);
    let q = query(colRef, where('to', '==', 'admin'));
    q = query(q, orderBy('timestamp', 'desc'));
    return collectionData(q, { idField: 'id' });
  }

  getUnreadAdminNotifications(lastRead: any) {
    let colRef = collection(this.db, `notifications`);
    let q = query(colRef, where('to', '==', 'admin'));
    q = query(q, where('timestamp', '>', lastRead));
    return collectionData(q, { idField: 'id' });
  }

  getVendorNotifications() {
    let colRef = collection(this.db, `notifications`);
    let q = query(
      colRef,
      where('to', '==', this.authService.userSubject.value?.uid)
    );
    q = query(q, orderBy('timestamp', 'desc'));
    return collectionData(q, { idField: 'id' });
  }

  getUnreadVendorNotifications(lastRead: any) {
    let colRef = collection(this.db, `notifications`);
    let q = query(
      colRef,
      where('to', '==', this.authService.userSubject.value?.uid)
    );
    q = query(q, where('timestamp', '>', lastRead));
    return collectionData(q, { idField: 'id' });
  }

  deleteNotification(id: any) {
    let aDoc = doc(this.db, `notifications/${id}`);
    return deleteDoc(aDoc);
  }

  objectToQueryString(obj: any): string {
    let params = new HttpParams();

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] != null) {
          params = params.append(key, obj[key]);
        }
      }
    }

    return params.toString();
  }

  getDraftProductPreviewDetails(payload: any) {
    return this.http.post(`${environment.backendUrl}/draft_products`, payload);
  }

  getDraftSkuPreviewDetails(payload: any) {
    return this.http.post(`${environment.backendUrl}/draft_v2`, payload);
  }

  getBrandById(id: string) {
    return lastValueFrom(
      this.http.get(`${environment.backendUrl}/brands/${id}`)
    );
  }

  getCompanyById(id: string) {
    return lastValueFrom(
      this.http.get(`${environment.backendUrl}/companies/${id}`)
    );
  }

  getCategoryById(id: string) {
    return lastValueFrom(
      this.http.get(`${environment.backendUrl}/categories/${id}`)
    );
  }

  updateVendorSkuStatus(id: string, status: any) {
    return lastValueFrom(
      this.http.patch(`${environment.backendUrl}/vendor_skus/${id}/status`, {
        status: status,
      })
    );
  }

  updateMasterSkuStatus(id: string, status: any) {
    return lastValueFrom(
      this.http.patch(`${environment.backendUrl}/skus/${id}/status`, {
        status: status,
      })
    );
  }

  addToQueueForImageProcessing(data: any) {
    return this.http
      .post(`${environment.messagingService}/messagequeue/post`, {
        messageType: 'ImageProcessing',
        input: JSON.stringify(data),
      })
      .toPromise();
  }
  deleteSkuVariantValue(id: string) {
    return lastValueFrom(
      this.http.delete(`${environment.backendUrl}/sku_variant_values/${id}`)
    );
  }

  async getVendorMarginSkus(input: VendorMarginSearch) {
    const inputObj = {
      vendorId: input.vendorId,
      status: input.status,
      skuTerm: input.skuName,
    };
    const url = `${environment.backendUrl}/get_by_vendor_filters?pageNum=${input.pageNumber}&pageSize=${input.pageSize}`;

    const response: any = await lastValueFrom(this.http.post(url, inputObj));

    return {
      skusList: response.data.rows,
      totalCount: response.data.count,
    };
  }
}
