import { Database } from '@angular/fire/database';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Auth } from '@angular/fire/auth';
import { AuthService } from '../../../services/auth.service';
import { Observable, lastValueFrom, map } from 'rxjs';
import {
  PriceUpdateEntity,
  SkuPricingRule,
  UpdateMarginES,
  VendorSkuPricing,
  VendorSkuPricingList,
  getSkuListResponse,
  importMargin,
  importPrice,
  searchSkuInput,
} from '../../../entities/pricing';
import { DB_PATH } from '../../../constants/db-path';
import { FirebaseDbService } from '../../../services/firebase-db.service';

@Injectable({
  providedIn: 'root',
})
export class PricingService {
  auth: Auth = inject(Auth);
  constructor(
    public http: HttpClient,
    public authService: AuthService,
    private firebaseDbService: FirebaseDbService
  ) {}

  async getVendorAllSKUs(vendorId: string): Promise<VendorSkuPricing[]> {
    const response = await lastValueFrom(
      this.http.get<{ data: VendorSkuPricing[] }>(
        `${environment.backendUrl}/get_all_by_vendor/${vendorId}`
      )
    );

    return response.data;
  }
  async getVendorSKUsWithoutPn(vendorId: string): Promise<VendorSkuPricing[]> {
    console.log('vendorId', vendorId);

    const response = await lastValueFrom(
      this.http.get<{ data: VendorSkuPricing[] }>(
        `${environment.backendUrl}/get_all_vendor_skus/${vendorId}`
      )
    );

    return response.data;
  }

  async getVendorSkus(id: string, pageNumber: number, pageSize: number) {
    const inputObj = {
      vendorId: id,
    };
    const url = `${environment.backendUrl}/get_by_vendor_filters?pageNum=${pageNumber}&pageSize=${pageSize}`;

    const response: any = await lastValueFrom(this.http.post(url, inputObj));

      return response && response.data.rows

  }

  async getVendorSKUPricingRules(
    skuId: string,
    vendorId: string
  ): Promise<SkuPricingRule[]> {
    const response = await lastValueFrom(
      this.http.get<{ data: SkuPricingRule[] }>(
        `${environment.pricingApiUrl}/pricing_rule?sku_id=${skuId}&vendor_id=${vendorId}`
      )
    );

    return response.data;
  }

  async getVendorSKUPriceList(
    skuId: string,
    vendorId: string
  ): Promise<VendorSkuPricingList[]> {
    const response = await lastValueFrom(
      this.http.get<{ data: VendorSkuPricingList[] }>(
        `${environment.pricingApiUrl}/pricingList?sku_id=${skuId}&vendor_id=${vendorId}`
      )
    );

    return response.data;
  }

  async getVendorSkuPricingList(
    data: object,
    pageNumber: number,
    pageSize: number
  ): Promise<any> {
    const response = await lastValueFrom(
      this.http.post<any>(
        `${environment.backendUrl}/get_by_vendor_filters?pageNum=${pageNumber}&pageSize=${pageSize}`,
        data
      )
    );

    if (response && response.data) {
      return {
        priceList: response.data.rows,
        totalCount: response.data.count,
      };
    }
  }

  async updatePricing(data: PriceUpdateEntity) {
    return await lastValueFrom(
      this.http.post(`${environment.pricingApiUrl}/pricing_list`, data)
    );
  }

  async importPrices(data: importPrice[]) {
    return await lastValueFrom(
      this.http.post(
        `${environment.pricingApiUrl}/pricing_list/bulk_update`,
        data
      )
    );
  }
  async updateMarginCD(data: importMargin[]) {
    return await lastValueFrom(
      this.http.put(`${environment.pricingApiUrl}/pricing_list/bulk`, data)
    );
  }

  async updateMarginES(data: UpdateMarginES[]) {
    return await lastValueFrom(
      this.http.post(`${environment.eSApiUrl}/vendor_skus/update_pricing`, data)
    );
  }

  async insertPricingRule(data: any) {
    return await lastValueFrom(
      this.http.post(`${environment.pricingApiUrl}/pricing_rule`, data)
    );
  }

  async updatePricingRule(data: any) {
    return await lastValueFrom(
      this.http.put(`${environment.pricingApiUrl}/pricing_rule`, data)
    );
  }

  async searchCompany(company: string, vendorId: string) {
    const data = {
      vendorId: vendorId,
      companyText: company,
    };

    const res: any = await lastValueFrom(
      this.http.post(`${environment.eSApiUrl}/vendor_skus/company_search`, data)
    );

    return res.data;
  }

  async exportMarginReportFilters(vendorId: string) {
    const observable: Observable<Blob> = this.http.get(
      `${environment.coreServiceUrl}/api/pricing/margin/spreadsheet/${vendorId}`,
      { responseType: 'blob' }
    );

    try {
      const result = await lastValueFrom(observable);
      return result;
    } catch (error) {
      console.error('Error fetching orders:', error);
      throw error; // You can rethrow the error or handle it as needed
    }
  }

  async getPricingHistory(vendor_id: string, sku_id: string) {
    const response = await lastValueFrom(
      this.http.get<any>(`${environment.pricingApiUrl}/pricing_list/pricing_list_history?vendor_id=${vendor_id}&sku_id=${sku_id}&page_num=1&page_size=5`)
    );
    return response && response.data;
  }
}
