@if(hasView){
<div class="container-fluid p-2">
  <mat-card class="p-3">
    <form [formGroup]="form">
      <div class="container-fluid">
        <div class="row p-3">
          <div class="col-md-6 d-flex mb-3">
            <div class="me-3">
              <mat-icon role="button" [routerLink]="['/dashboard/pocreation/']">keyboard_backspace</mat-icon>
            </div>
            <h3 class="title">Purchase Order</h3>
          </div>

          <div class="col-md-6 right-list" *ngIf="hasExport">
            <button mat-raised-button type="button" class="text-center ml-w" (click)="downloadPr()" color="primary">
              Download
            </button>
          </div>
        </div>
        <!-- <form [formGroup]="form"> -->
        <div class="row p-3">
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <mat-label>Invoice Reference Number</mat-label>
              <input matInput formControlName="invoiceRfNo" />
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <mat-label>Created Date</mat-label>
              <input matInput formControlName="createdDate" readonly="true" />
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <mat-label>Supplier Name</mat-label>
              <input matInput type="string" formControlName="supplierName" name="supplierName"
                placeholder="Supplier Name" readonly="true" />
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <mat-label>Supplier GSTN</mat-label>
              <input matInput formControlName="supplierGstn" readonly="true" />
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <mat-label>TDS Percent</mat-label>
              <input matInput formControlName="tdsPercent" />
            </mat-form-field>
          </div>
          <!-- <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <mat-label>TDS Value</mat-label>
              <input matInput formControlName="tdsValue" readonly="true" />
            </mat-form-field>
          </div> -->
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <mat-label>Status</mat-label>
              <input matInput type="string" formControlName="status" name="status" placeholder="Status"
                readonly="true" />
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <mat-label>Product Tax Type</mat-label>
              <mat-select formControlName="productTaxType" name="productTaxType" placeholder="Product Tax Type">
                <mat-option *ngFor="let option of taxes" [value]="option.value">
                  {{ option.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <!-- </form>  -->
      </div>
      <div class="row p-3">
        <div class="col-md-6">
          <h3 class="title">Items</h3>
        </div>
        <div class="col-md-6 right-list" *ngIf="buttonShow.isAddProductShow && hasCreate && hasProcurement">
          <button mat-raised-button type="button" class="text-center ml-w" (click)="navigateToAddProduct()"
            color="primary">
            Add SKU
          </button>
        </div>
      </div>
      <div class="row p-3">
        <div class="col-md-12 table-wrapper">
          <table mat-table [dataSource]="itemsList">
            <ng-container matColumnDef="product">
              <th mat-header-cell *matHeaderCellDef>SKU Name</th>
              <td mat-cell *matCellDef="let element">{{ element.sku_name }}</td>
            </ng-container>

            <ng-container matColumnDef="mrp">
              <th mat-header-cell *matHeaderCellDef class="mrp-column">MRP</th>
              <td mat-cell *matCellDef="let element" class="mrp-column">{{ element.mrp | replaceCommas }}</td>
            </ng-container>

            <ng-container matColumnDef="tax">
              <th mat-header-cell *matHeaderCellDef>Tax</th>
              <td mat-cell *matCellDef="let element">
                {{ element.tax_type }}
              </td>
            </ng-container>
            <ng-container matColumnDef="uom">
              <th mat-header-cell *matHeaderCellDef>UOM</th>
              <td mat-cell *matCellDef="let element"> {{ getUomType(element.uom_type) }}</td>
            </ng-container>
            <ng-container matColumnDef="requestedUomQty">
              <th mat-header-cell *matHeaderCellDef>Requested UOM Qty</th>
              <td mat-cell *matCellDef="let element">{{ element.uom_requested_qty
                }}</td>
            </ng-container>
            <ng-container matColumnDef="requestedPcs">
              <th mat-header-cell *matHeaderCellDef>Requested PC's</th>
              <td mat-cell *matCellDef="let element">{{ element.requested_in_pcs }}</td>
            </ng-container>
            <ng-container matColumnDef="actualUomQty">
              <th mat-header-cell *matHeaderCellDef>Actual UOM Qty</th>
              <td mat-cell *matCellDef="let element">
                {{ element.uom_actual_qty }}
              </td>
            </ng-container>
            <ng-container matColumnDef="actualpcs">
              <th mat-header-cell *matHeaderCellDef>Actual PC's</th>
              <td mat-cell *matCellDef="let element">
                {{ element.actual_in_pcs }}
              </td>
            </ng-container>
            <ng-container matColumnDef="baseAmount">
              <th mat-header-cell *matHeaderCellDef>Base Amount</th>
              <td mat-cell *matCellDef="let element">
                {{ element.taxable_amount | replaceCommas}}
              </td>
            </ng-container>
            <ng-container matColumnDef="requestedInvoiceAmount">
              <th mat-header-cell *matHeaderCellDef>Requested Invoice Amount</th>
              <td mat-cell *matCellDef="let element">{{ element.requested_invoice_amount | replaceCommas}}</td>
            </ng-container>
            <ng-container matColumnDef="cgst">
              <th mat-header-cell *matHeaderCellDef>CGST</th>
              <td mat-cell *matCellDef="let element">{{ element.cgst_amount | replaceCommas}}</td>
            </ng-container>
            <ng-container matColumnDef="sgst">
              <th mat-header-cell *matHeaderCellDef>SGST</th>
              <td mat-cell *matCellDef="let element">{{ element.sgst_amount | replaceCommas}}</td>
            </ng-container>
            <ng-container matColumnDef="igst">
              <th mat-header-cell *matHeaderCellDef>IGST</th>
              <td mat-cell *matCellDef="let element">{{ element.igst_amount | replaceCommas}}</td>
            </ng-container>
            <ng-container matColumnDef="cess">
              <th mat-header-cell *matHeaderCellDef>CESS</th>
              <td mat-cell *matCellDef="let element">{{ element.cess_amount | replaceCommas}}</td>
            </ng-container>
            <ng-container matColumnDef="discount">
              <th mat-header-cell *matHeaderCellDef>Discount</th>
              <td mat-cell *matCellDef="let element">{{ element.discount | replaceCommas}}</td>
            </ng-container>
            <ng-container matColumnDef="netpayable">
              <th mat-header-cell *matHeaderCellDef>Net Payable</th>
              <td mat-cell *matCellDef="let element">{{ element.final_bill_value | replaceCommas}}</td>
            </ng-container>
            <ng-container matColumnDef="landingCost">
              <th mat-header-cell *matHeaderCellDef>Landing Cost</th>
              <td mat-cell *matCellDef="let element">{{ element.landing_cost | replaceCommas}}</td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>Action</th>
              <td mat-cell *matCellDef="let element">
                <div class="d-flex">
                  <button color="primary" mat-raised-button (click)="productEditComponent(element.id)"
                    *ngIf="buttonShow.isItemEditShow && hasEdit">
                    <mat-icon class="me-0">edit</mat-icon>
                  </button>
                  <button color="primary" mat-raised-button (click)="deleteSku(element.id)" class="button"
                    *ngIf="buttonShow.isItemDeleteShow && hasDelete">
                    <mat-icon class="me-0">delete</mat-icon>
                  </button>
                  @if(hasInventory){
                  <button type="button" color="primary" mat-raised-button (click)="addGrn(element.id)"
                    class="text-center ml-w button" *ngIf="buttonShow.isGrnShow ">GRN
                  </button>
                  }
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>

      <div class="row p-3">
        <div class="col-md-6">
          <h3 class="title">Expenses & Discounts</h3>
        </div>
        <div class="col-md-6 right-list" *ngIf="buttonShow.isAddExpenseShow && hasCreate">
          <button mat-raised-button type="button" class="text-center ml-w" (click)="navigateToAddExpenses()"
            color="primary">
            Add Expenses
          </button>
        </div>
      </div>

      <div class="row p-3">
        <div class="col-md-12 table-wrapper">
          <table mat-table mat-table [dataSource]="dataSource2">
            <!-- Order No Column -->
            <ng-container matColumnDef="expensestype">
              <th mat-header-cell *matHeaderCellDef>Expenses Type</th>
              <td mat-cell *matCellDef="let element">
                {{ getExpenseTypeNameByID(element.expense_type) }}
              </td>
            </ng-container>
            <ng-container matColumnDef="totalvalue">
              <th mat-header-cell *matHeaderCellDef>Total Value</th>
              <td mat-cell *matCellDef="let element">{{ element.total_value | replaceCommas}}</td>
            </ng-container>
            <ng-container matColumnDef="supplier">
              <th mat-header-cell *matHeaderCellDef>Supplier</th>
              <td mat-cell *matCellDef="let element">{{getSupplierNameByID( element.supplier_id) }}</td>
            </ng-container>
            <ng-container matColumnDef="payout">
              <th mat-header-cell *matHeaderCellDef>Payout</th>
              <td mat-cell *matCellDef="let element">{{element.payout | replaceCommas}}</td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>Action</th>
              <td mat-cell *matCellDef="let element">
                <button mat-raised-button color="primary" aria-label="Edit" (click)="expensesEditComponent(element.id)"
                  *ngIf="buttonShow.isExpenseEditShow && hasEdit">
                  <mat-icon>edit</mat-icon>
                </button>
                <button color="primary" mat-raised-button (click)="deleteExpense(element.id)" class="button"
                  *ngIf="buttonShow.isExpenseDeleteShow && hasDelete">
                  <mat-icon class="me-0">delete</mat-icon>
                </button>

              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnss"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnss"></tr>
          </table>
        </div>
      </div>

      <!-- <div class="row p-3">
        <div class="col-md-6">
          <h3 class="title">Tax</h3>
        </div>
        <div class="col-md-6 right-list" *ngIf="buttonShow.isCalculateLpShow">
          <button mat-raised-button type="button" class="mat-small" color="primary">
            Calculate LP
          </button>
        </div>
      </div> -->
      <div class="row p-3">
        <div class="col-md-6">
          <h3 class="title">Summary : {{id}} </h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 table-wrapper">
          <table mat-table [dataSource]="summary">
            <ng-container matColumnDef="supplier">
              <th mat-header-cell *matHeaderCellDef>Supplier</th>
              <td mat-cell *matCellDef="let element">{{ getSupplierNameByID(element.supplier) }} </td>
            </ng-container>
            <ng-container matColumnDef="expense_type">
              <th mat-header-cell *matHeaderCellDef>Expense Type</th>
              <td mat-cell *matCellDef="let element">
                {{
                element.expense_type === 'NetPayable'
                ? 'Net Payable'
                : element.expense_type
                ? getExpenseTypeNameByID(element.expense_type)
                : 'Procurement'
                }}
              </td>
            </ng-container>
            <ng-container matColumnDef="baseValue">
              <th mat-header-cell *matHeaderCellDef>Base Value</th>
              <td mat-cell *matCellDef="let element"> {{element.base_value | replaceCommas }}
              </td>
            </ng-container>
            <ng-container matColumnDef="cgst">
              <th mat-header-cell *matHeaderCellDef>CGST</th>
              <td mat-cell *matCellDef="let element"> {{element.cgst |replaceCommas}}
              </td>
            </ng-container>
            <ng-container matColumnDef="sgst">
              <th mat-header-cell *matHeaderCellDef>SGST</th>
              <td mat-cell *matCellDef="let element"> {{element.sgst |replaceCommas }}
              </td>
            </ng-container>
            <ng-container matColumnDef="igst">
              <th mat-header-cell *matHeaderCellDef>IGST</th>
              <td mat-cell *matCellDef="let element"> {{element.igst |replaceCommas}}
              </td>
            </ng-container>
            <ng-container matColumnDef="cess">
              <th mat-header-cell *matHeaderCellDef>CESS</th>
              <td mat-cell *matCellDef="let element"> {{element.cess |replaceCommas}}
              </td>
            </ng-container>
            <ng-container matColumnDef="tds">
              <th mat-header-cell *matHeaderCellDef>TDS</th>
              <td mat-cell *matCellDef="let element"> {{+element.tds |replaceCommas}}
              </td>
            </ng-container>
            <ng-container matColumnDef="rate">
              <th mat-header-cell *matHeaderCellDef>Rate</th>
              <td mat-cell *matCellDef="let element"> {{+element.rate |replaceCommas}}
              </td>
            </ng-container>
            <ng-container matColumnDef="payout">
              <th mat-header-cell *matHeaderCellDef>Payout</th>
              <td mat-cell *matCellDef="let element"> {{+element.payout |replaceCommas }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="summaryDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: summaryDisplayedColumns;"></tr>
          </table>
        </div>
      </div>
      <div class="row p-3">
        <div class="col-md-6">
          <h3 class="title">Bills </h3>
        </div>
      </div>
      <div class="table-block" style="margin: 5px">
        <div class="mat-elevation-z8">
          <!-- <form [formGroup]="dataSource">  -->
          <section id="content">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <!-- <span class="header"> -->
                    <h2 class="card-title">Upload</h2>
                    <!-- </span> -->
                  </div>
                  <div class="row" style="margin-bottom: 5px">
                    <div class="col-md-6">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <mat-label>Purchase Bill</mat-label>
                      <input formControlName="purchaseBill" name="purchaseBill" class="form-control"
                        (change)="onFileChange($event, 'purchase-bill')" type="file" />
                    </div>
                    <div class="col-md-6">
                      <mat-label>Other Bills</mat-label>
                      <input formControlName="otherBills" name="otherBills" class="form-control"
                        (change)="onFileChange($event, 'other-bills')" type="file" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 mt-2">
                      <div class="imgSupplier" *ngIf="purchaseBillUrl">
                        <a [href]="purchaseBillUrl" download>
                          <i class="fas fa-download"></i> Download PurchaseBill
                        </a>
                      </div>
                    </div>|
                    <div class="col-md-5 mt-2">
                      <div class="imgSupplier" *ngIf="otherBillsUrl">
                        <a [href]="otherBillsUrl" download>
                          <i class="fas fa-download"></i> Download OtherBill
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>

            <div class="col-md-6 mt-2">
              <mat-form-field class="example-full-width">
                <mat-label>Leave a comment</mat-label>
                <!-- <textarea matInput formControlName="comments"></textarea> -->
                <input matInput formControlName="comments" name="comments" />
              </mat-form-field>
            </div>

            <div class="col-md-12 custom-flex">
              <div class="text-center mt-2 flex-item" *ngIf="buttonShow.isSaveShow">
                <ng-container *ngIf="loading; else notLoading">
                  <mat-spinner [diameter]="30"></mat-spinner>
                </ng-container>
                <ng-template #notLoading>
                  <button mat-raised-button type="button" class="text-center ml-w" (click)="save()" color="primary">
                    Save
                  </button>
                </ng-template>
              </div>
              <div class="text-center mt-2 flex-item" *ngIf="buttonShow.isUploadFilesShow && hasProcurement">
                <ng-container *ngIf="loading; else notLoading">
                  <mat-spinner [diameter]="30"></mat-spinner>
                </ng-container>
                <ng-template #notLoading>
                  <button mat-raised-button color="primary" class="text-center ml-w" (click)="uploadBillofNauka()">
                    Upload Files
                  </button>
                </ng-template>
              </div>
              <div class="text-center mt-2 flex-item" *ngIf="buttonShow.isRequestForGrnShow && hasProcurement">
                <button mat-raised-button color="primary" class="text-center ml-w" (click)="requestGrn()">
                  Request For GRN
                </button>
              </div>
              <div class="text-center mt-2 flex-item" *ngIf="buttonShow.isCompleteGrnShow && hasInventory">
                <button mat-raised-button color="primary" class="text-center ml-w" (click)="completeGrn()">
                  Complete GRN
                </button>
              </div>
              <div class="text-center mt-2 flex-item" *ngIf="buttonShow.isRequestForApprovalShow && hasProcurement">
                <button mat-raised-button color="primary" class="text-center ml-w" (click)="requestApproval()">
                  Request For Approval
                </button>
              </div>
              <div class="text-center mt-2 flex-item" *ngIf="buttonShow.isApproveShow && hasAccounting">
                <ng-container *ngIf="loading; else notLoading">
                  <mat-spinner [diameter]="30"></mat-spinner>
                </ng-container>
                <ng-template #notLoading>
                  <button mat-raised-button color="primary" class="text-center ml-w" (click)="approvePr()">
                    Approve
                  </button>
                </ng-template>
              </div>
            </div>
          </section>
        </div>
      </div>
    </form>
  </mat-card>
</div>
}@else {
<ng-container>
  <p class="text-center">
    You don't have permission , Please contact administrator
  </p>
</ng-container>
}