<ng-container>
  <div class="heading-block">
    <div class="row">
      <div class="col-md-6">
        <mat-icon class="backicon" [routerLink]="['/dashboard/notifications']">arrow_back</mat-icon>
        <span class="header">
          <h2 class="card-title m-3">Create Notification</h2>
        </span>
      </div>
      <div class="col-md-6 text-right"></div>
    </div>
  </div>
  <div class="table-block">
    <form [formGroup]="dataSource">
      <section id="content">
        <div class="card">
          <div class="card-body card-padding">
            <div class="row">
              <div class="col-md-6">
                <mat-form-field class="example-full-width">
                  <mat-label>Notification Title<span class="asterisk">*</span></mat-label>
                  <input matInput formControlName="title" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="example-full-width">
                  <mat-label>Description<span class="asterisk">*</span></mat-label>
                  <input matInput formControlName="description" />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="full-width">
                  <mat-label>Promo Type <span class="asterisk">*</span></mat-label>
                  <mat-select formControlName="deepLinkType" (selectionChange)="onDeeplinkTypeChange($event)">
                    @for (deepLinkType of deepLinkTypeList; track $index) {
                    <mat-option [value]="deepLinkType.deepLinkTypeId">{{deepLinkType.deepLinkType}}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6" *ngIf="[1].includes(selectedDeeplinkType)">
                <mat-form-field class="full-width">
                  <mat-label>Select Promotion <span class="asterisk">*</span></mat-label>
                  <mat-select formControlName="promotionSelect">
                    <mat-option *ngFor="let item of promotionRes" [value]="item.id">
                      {{ item.promoName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6" *ngIf="[2].includes(selectedDeeplinkType)">
                <mat-form-field class="full-width">
                  <mat-label>Select Promotion KPI<span class="asterisk">*</span></mat-label>
                  <mat-select formControlName="promoKpiSelect">
                    <mat-option *ngFor="let item of promotionRes" [value]="item.id">
                      {{ item.promoName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6" *ngIf="[3].includes(selectedDeeplinkType)">
                <mat-form-field class="full-width">
                  <mat-label>Select Category<span class="asterisk">*</span></mat-label>
                  <mat-select formControlName="selectCategory" multiple>
                    <input matInput [formControl]="categoryControl" placeholder="Enter search text here"
                      class="editableSearch" (keydown.space)="$event.stopPropagation()" />
                    <mat-option [value]="" *ngIf="!SearchResults.length" disabled>
                      No Result Found
                    </mat-option>
                    <mat-option *ngFor="let item of SearchResults" [value]="item.id">
                      {{ item.attributes.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6" *ngIf="[4].includes(selectedDeeplinkType)">
                <mat-form-field class="full-width">
                  <mat-label>Select Brand<span class="asterisk">*</span></mat-label>
                  <mat-select formControlName="selectBrand" multiple>
                    <input matInput [formControl]="brandControl" placeholder="Enter search text here"
                      class="editableSearch" (keydown.space)="$event.stopPropagation()" />
                    <mat-option [value]="" *ngIf="!brandsResults.length" disabled>
                      No Result Found
                    </mat-option>
                    <mat-option *ngFor="let item of brandsResults" [value]="item.id">
                      {{ item.attributes.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6" *ngIf="[5].includes(selectedDeeplinkType)">
                <mat-form-field class="full-width">
                  <mat-label>Select Vendor<span class="asterisk">*</span></mat-label>
                  <mat-select formControlName="selectVendor" multiple>
                    <input matInput [formControl]="vendorControl" placeholder="Enter search text here"
                      (keyup)="onKey($event)" class="editableSearch" (keydown.space)="$event.stopPropagation()" />
                    <mat-option *ngFor="let item of merchantSearchList" [value]="item.id">
                      {{ item.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6" *ngIf="[6].includes(selectedDeeplinkType)">
                <mat-form-field class="full-width">
                  <mat-label>Select SKU<span class="asterisk">*</span></mat-label>
                  <mat-select formControlName="selectSku" multiple>
                    <input matInput [formControl]="skuControl" placeholder="Enter search text here"
                      (keyup)="onKey($event)" class="editableSearch" (keydown.space)="$event.stopPropagation()" />
                    <mat-option [value]="" *ngIf="!skuList.length" disabled>
                      No Result Found
                    </mat-option>
                    <mat-option *ngFor="let item of skuList" [value]="item.id">
                      {{ item.attributes.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-md-6">
                <mat-label>Upload Image <span class="text-danger">*</span></mat-label>
                <input class="form-control" (change)="onFileChange($event)" type="file" />
                @if(previewImage){
                <img [src]="previewImage" style="width: 100px; height: 100px" />
                }
              </div>

              <div class="row">
                <div class="buttonSection mt-3">

                  <button mat-raised-button color="primary" class="text-center ml" (click)="saveDeeplink()">
                    Create Notification
                  </button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  </div>
</ng-container>