import { style } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { RouterLink, RouterOutlet } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { FileUploadService } from '../../../services/file-upload.service';
import { AppLoaderService } from '../../../shared/app-loader/app-loader.service';
import Swal from 'sweetalert2';
import {
  ExcelIds,
  PricingRulePriceType,
  RequiredColumns,
  RequiredSheets,
  SwalMessageTypes,
} from '../../../enums/enums';
import { ExcelExportService } from '../../../services/excel.service';

interface validateSp {
  skuId: string;
  skuName: string;
  newMrp: number;
  newSp: number;
}
@Component({
  selector: 'bulk-import-pop-up',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatButtonModule,
    RouterOutlet,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatCardModule,
    MatIconModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    CommonModule,
    MatTableModule,
    RouterLink,
    ReactiveFormsModule,
  ],
  providers: [AppLoaderService],
  templateUrl: './bulk-import-pop-up.component.html',
})
export class BulkImportPopUpComponent implements OnInit {
  selectedFileName: any;
  isExcelFileInvalid: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<BulkImportPopUpComponent>,
    private fileUploadService: FileUploadService,
    private egretLoader: AppLoaderService,
    private excelService: ExcelExportService,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  async onSelectExcelFile(event: any) {
    try {
      const file: File = event.target.files[0];
      if (!file) {
        this.selectedFileName = null;
        return;
      }

      const excelData = await this.getExcelData(file);
      const mappedData = this.mapExcelData(excelData);

      const sellingPriceHigher = this.getSellingPriceHigher(mappedData);

      if (sellingPriceHigher.length > 0) {
        this.showWarningMessage(sellingPriceHigher);
        this.selectedFileName = null;
        this.egretLoader.close();
        return;
      }

      this.selectedFileName = file.name;
    } catch (error) {
      console.error('Error processing Excel file:', error);
      this.showMessage(
        'Failed to process the Excel file.',
        SwalMessageTypes.Error
      );
    }
  }

  private async getExcelData(file: File): Promise<any[]> {
    const binaryStr = await this.excelService.readExcelSheet(file);
    const headerRow: string[] = binaryStr[0];
    const dataRows: any[] = binaryStr.slice(1);

    return dataRows.map((row) => {
      const rowData: any = {};
      headerRow.forEach((header, index) => {
        rowData[header] = row[index];
      });
      return rowData;
    });
  }

  private mapExcelData(excelData: any[]): validateSp[] {
    return excelData.map((row: any) => ({
      skuId: row['SKUID'],
      skuName: row['SKUName'],
      newMrp: row['NewMRP'],
      newSp: row['NewSellingPrice'],
    }));
  }

  private getSellingPriceHigher(mappedData: validateSp[]): validateSp[] {
    return mappedData.filter((sp: validateSp) => +sp.newMrp < +sp.newSp);
  }

  private showWarningMessage(sellingPriceHigher: validateSp[]): void {
    console.log('sellingPriceHigher :-----', sellingPriceHigher);
    const skuNames = sellingPriceHigher
      .map((s: validateSp) => s.skuName)
      .join(', ');
    const msg = `<span style=color:red;>${skuNames} </span>- Some selling prices are higher than the MRP. Please correct them.`;
    this.showMessage(msg, SwalMessageTypes.Warning);
  }

  // async onSelectExcelFile(event: any) {
  //   const file: File = event.target.files[0];
  //   if (file) {
  //     const binaryStr = await this.excelService.readExcelSheet(file);

  //     const headerRow: string[] = binaryStr[0];
  //     const dataRows: any[] = binaryStr.slice(1);

  //     const excelData = dataRows.map((row) => {
  //       const rowData: any = {};
  //       headerRow.forEach((header, index) => {
  //         rowData[header] = row[index];
  //       });
  //       return rowData;
  //     });

  //     // Map to desired structure
  //     const mappedData: validateSp[] = excelData.map((row: any) => ({
  //       skuId: row['SKUID'],
  //       skuName: row['SKUName'],
  //       newMrp: row['NewMRP'],
  //       newSp: row['NewSellingPrice'],
  //     }));

  //     const sellingPriceHigher = mappedData.filter(
  //       (sp: validateSp) => +sp.newMrp < +sp.newSp
  //     );

  //     if (sellingPriceHigher.length > 0) {
  //       const skuNames = sellingPriceHigher
  //         .map((s: validateSp) => s.skuName)
  //         .join(', ');
  //       const msg = `${skuNames} - Some selling prices are higher than the MRP. Please correct them.`;
  //       this.showMessage(msg, SwalMessageTypes.Warning);

  //       this.egretLoader.close();

  //       return;
  //     }

  //     this.selectedFileName = file.name;
  //   } else {
  //     this.selectedFileName = null;
  //   }
  // }

  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  async uploadExcel(): Promise<void> {
    try {
      this.egretLoader.open();
      const fileInput = document.getElementById(
        'excelInput'
      ) as HTMLInputElement;
      if (!fileInput || !fileInput.files) {
        throw new Error('File input or files not found.');
      }

      const file: File = fileInput.files[0];
      if (!file) {
        Swal.fire('No file selected.');
        return;
      }

      const reader = new FileReader();
      reader.onload = async (e: any) => {
        try {
          const data = new Uint8Array(e.target.result);

          const workbook = XLSX.read(data, { type: 'array' });

          const currentDate = new Date();
          const dateString = currentDate.toISOString().split('T')[0];
          const fileName = `${dateString}-${this.selectedFileName}`;
          const fileNameWithoutExtension = fileName
            .split('.')
            .slice(0, -1)
            .join('.');

          const id: ExcelIds = +this.data.row.id as ExcelIds;

          let requiredSheets: RequiredSheets[] = [];
          let requiredColumns: RequiredColumns[][] = [];

          switch (id) {
            case ExcelIds.ID_1101:
              requiredSheets = [
                RequiredSheets.Price,
                RequiredSheets.PriceSlab,
                RequiredSheets.Stock,
              ];
              requiredColumns = [
                [RequiredColumns.NewMRP, RequiredColumns.NewSellingPrice],
                [RequiredColumns.NewRate],
                [
                  RequiredColumns.NewCases,
                  RequiredColumns.NewPieces,
                  RequiredColumns.NewDamageInCases,
                  RequiredColumns.NewDamageInPieces,
                ],
              ];
              break;
            case ExcelIds.ID_1102:
              requiredSheets = [RequiredSheets.Price, RequiredSheets.PriceSlab];
              requiredColumns = [
                [RequiredColumns.NewMRP, RequiredColumns.NewSellingPrice],
                [RequiredColumns.NewRate],
              ];
              break;
            case ExcelIds.ID_1103:
              requiredSheets = [RequiredSheets.Stock];
              requiredColumns = [
                [
                  RequiredColumns.NewCases,
                  RequiredColumns.NewPieces,
                  RequiredColumns.NewDamageInCases,
                  RequiredColumns.NewDamageInPieces,
                ],
              ];
              break;
            default:
              throw new Error(`Unsupported product ID: ${id}`);
          }

          const allSheetsExist = requiredSheets.every((sheetName) =>
            workbook.SheetNames.includes(sheetName)
          );
          if (!allSheetsExist) {
            Swal.fire(`Sheet Names Are Mismatch.`);
            this.egretLoader.close();
            return;
          }

          const additionalSheets = workbook.SheetNames.filter(
            (sheetName) => !requiredSheets.includes(sheetName as RequiredSheets)
          );
          if (additionalSheets.length > 0) {
            Swal.fire(
              `Additional sheets (${additionalSheets.join(
                ', '
              )}) are not allowed.`
            );
            this.egretLoader.close();
            return;
          }

          const allValidationsPassed = requiredSheets
            .map((sheet, index) => {
              const sheetData = workbook.Sheets[sheet];
              const columns = requiredColumns[index];

              return this.validateSheetColumns(sheetData, columns);
            })
            .every((valid) => valid);

          if (!allValidationsPassed) {
            Swal.fire(`One or more columns contain invalid values.`);
            this.egretLoader.close();
            return;
          }

          workbook.SheetNames.forEach((sheetName) => {
            const sheetData = workbook.Sheets[sheetName];
            const rows: any = XLSX.utils.sheet_to_json(sheetData, {
              header: 1,
            });
            if (rows.length == 0) {
              return;
            }

            const rateOrDiscountColumnIndex = rows[0].indexOf(
              RequiredColumns.RateOrDiscount
            );
            for (let i = 1; i < rows.length; i++) {
              const row = rows[i];
              if (typeof row[rateOrDiscountColumnIndex] === 'string') {
                row[rateOrDiscountColumnIndex] = this.convertRateOrDiscount(
                  row[rateOrDiscountColumnIndex]
                );
              }
            }
            const newSheetData = XLSX.utils.aoa_to_sheet(rows);
            workbook.Sheets[sheetName] = newSheetData;
          });

          const updatedExcelData = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'binary',
          });

          const mergedBase64Data = btoa(updatedExcelData);
          const apiResponse: any = await this.fileUploadService.uploadExcelFile(
            fileNameWithoutExtension,
            'xlsx',
            mergedBase64Data
          );
          const excelURL = apiResponse.data.url;

          this.egretLoader.close();
          this.dialogRef.close({ excelURL: excelURL, fileName: fileName });
        } catch (error) {
          console.error('Error processing Excel:', error);
          this.egretLoader.close();
        }
      };
      reader.readAsArrayBuffer(file);
      await new Promise((resolve) => (reader.onloadend = resolve));
    } catch (error) {
      console.error('Error uploading Excel:', error);
    } finally {
      this.resetFileInput();
      this.selectedFileName = null;
    }
  }

  private convertRateOrDiscount(value: string): PricingRulePriceType {
    switch (value) {
      case 'Rate':
        return PricingRulePriceType.Rate;
      case 'DiscountPercentage':
        return PricingRulePriceType.DiscountPercentage;
      case 'DiscountAmount':
        return PricingRulePriceType.DiscountAmount;
      default:
        throw new Error(`Invalid value for RateOrDiscount column: ${value}`);
    }
  }

  validateSheetColumns(
    sheetData: XLSX.WorkSheet,
    columns: RequiredColumns[]
  ): boolean {
    const rows: any = XLSX.utils.sheet_to_json(sheetData, { header: 1 });
    const alphanumericPattern = /^\d*\.?\d+$/;

    for (let i = 1; i < rows.length; i++) {
      const row: any = rows[i];

      if (
        row.every(
          (cell: string | null | undefined, index: number) =>
            index !== 0 && (cell === null || cell === undefined || cell === '')
        )
      ) {
        continue;
      }

      for (const columnName of columns) {
        const columnIndex = rows[0].indexOf(columnName);
        const cellValue = row[columnIndex];

        if (!alphanumericPattern.test(cellValue)) {
          return false;
        }

        if (cellValue < 0 || isNaN(cellValue)) {
          return false;
        }
      }
    }
    return true;
  }

  resetFileInput() {
    const fileInput = document.getElementById('excelInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
