import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FirestoreService } from '../../../services/firestore.service';
import { DB_PATH } from '../../../constants/db-path';
import { environment } from '../../../../environments/environment';
import { WhereFilterOp } from 'firebase/firestore';
import { lastValueFrom } from 'rxjs';

interface ImportData {
  createdDate: string;
  emailId: string;
  error: string;
  fileName: string;
  fileUrl: string;
  id: string;
  jobId: string;
  jobName: string;
  jobType: string;
  status: string;
  timeStamp?: number;
}
@Injectable({
  providedIn: 'root',
})

export class ProductBulkImportService {
  constructor(
    public fireStoreService: FirestoreService,
    private http: HttpClient
  ) { }

  mapFireStoreResponse(response: ImportData[]): any[] {
    return response.map((data: ImportData) => {
      return {
        createdDate: data.createdDate,
        emailId: data.emailId,
        error: data.error,
        fileName: data.fileName,
        fileUrl: data.fileUrl,
        id: data.id,
        jobId: data.jobId,
        jobName: data.jobName,
        jobType: data.jobType,
        status: data.status,
        timeStamp: data.timeStamp || 1727786779
      };
    }).sort((a, b) => b.timeStamp - a.timeStamp);
  }

  async getAllProductBulkImportData(): Promise<ImportData[]> {
    const filters = [
      { fieldName: 'jobType', fieldValue: 'Display Order', operator: '==' as WhereFilterOp }
    ];

    const response = await this.fireStoreService.queryCollectionByFilters(DB_PATH.BULK_IMPORT, filters);
    const result = this.mapFireStoreResponse(response);
    return result;
  }

  async downloadProductDataExcel() {
    const url = `${environment.backendUrl}/download_all_products_data`;
    const response = await lastValueFrom(this.http.get(url, { responseType: 'blob' }));
    return response
  }

}
