<div class="container-fluid py-2">

  <form [formGroup]="skuForm">
    <div formGroupName="sku">
      <!--   <mat-card class="p-3">
        <h3 class="title">Variants</h3>
        <div class="container-fluid">

          @for (item of selected_variant_values.controls; track item;let i = $index;) {
          <div class="d-lg-flex d-block align-items-center mt-2" [formGroup]="item">
            <div class=" mx-2">
              <mat-form-field class="example-full-width">
                <mat-label>Variant {{ i + 1 }}</mat-label>
                <mat-select formControlName="variant_types_id">
                  @for (option of variant_type_options; track $index) {
                    @if (shouldDisplayOption(option,item)) {
                      <mat-option [value]="option.id">{{
                        option.attributes.name
                      }}</mat-option>
                    }
                  }
                </mat-select>
              </mat-form-field>
            </div>
            <div class="mx-2">
              <mat-form-field class="example-full-width">
                <mat-label>Value</mat-label>
                <input matInput formControlName="variant_value" />
              </mat-form-field>
            </div>
            <div class="mx-2">
              <button type="button" (click)="removeSelectedVariantItem(i)" mat-raised-button color="warn" matTooltip="Remove">
                <mat-icon class="me-0">delete</mat-icon>
              </button>
            </div>
          </div>
          }

          <button type="button" mat-raised-button color="primary" class="m-2" (click)="addSelectedVariantItem()"
            matTooltip="Add More Attribute">

            <mat-icon>add</mat-icon> Add
          </button>
        </div>
      </mat-card> -->
      <mat-card class="p-3">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 d-flex my-3">
              <div class="me-3">
                <mat-icon role="button" (click)="back()">keyboard_backspace</mat-icon>
              </div>
              @if(operation_type=='add-sku'){
              <h3 class="title">
                {{'Add SKU'}}
              </h3>
              }
              @if(operation_type=='update-sku'){
              <h3 class="title">
                {{'Update SKU'}}
              </h3>
              }
              @if(operation_type=='add-sku-to-catalog'){
              <h3 class="title">
                {{'Add SKU to Catalog'}}
              </h3>
              }
              @if(operation_type=='update-sku-to-catalog'){
              <h3 class="title">
                {{'Update SKU'}}
              </h3>
              }
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <mat-form-field class="example-full-width">
                    <mat-label>Enter SKU Name<span class="text-danger">*</span>&nbsp;&nbsp;Eg: Bambino
                      Vermicelli</mat-label>
                    <input matInput formControlName="name" readonly={{isReadonly}} />
                  </mat-form-field>
                </div>
                <!-- <div class="col-md-12" [formGroupName]="'sku_packing_sections'">
                  <mat-form-field class="example-full-width">
                    <mat-label>Enter SKU MRP<span class="text-danger">*</span>&nbsp;&nbsp;Eg: 250</mat-label>
                    <input type="number" matInput formControlName="mrp" />
                  </mat-form-field>
                </div> -->

              </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-5">
              <mat-card class="p-3">
                <div class="row">
                  @if (hasBMSAdmin && operation_type=='update-sku') {
                  <div class="col-md-6">
                    <!-- <b>Product Name</b>
                    </div>
                    <div class="col-md-6">
                      <p> {{skuForm.value.product?.name}}</p> -->
                  </div>
                  <div class="col-md-6" *ngIf="hasBMSAdmin">
                    <button class="fr" mat-raised-button (click)="navigateToUpdateCategory()"
                      routerLinkActive="router-link-active" color="primary">
                      <mat-icon class="me-0">edit</mat-icon>
                    </button>
                  </div>
                  }
                  <div class="col-md-6">
                    <b>Brand Name</b>
                  </div>
                  <div class="col-md-6">
                    <p> {{this.productDisplayDetails['brand']}}</p>
                  </div>
                  <div class="col-md-6">
                    <b>Company Name</b>
                  </div>
                  <div class="col-md-6">
                    <p> {{this.productDisplayDetails['company']}}</p>
                  </div>
                  <div class="col-md-6">
                    <b>Category Browse Path</b>
                  </div>
                  <div class="col-md-6">
                    <p style="line-break: anywhere;"> {{this.productDisplayDetails['category']}}</p>
                  </div>
                </div>
              </mat-card>
              <div class="row mt-2">
                <div class="col-md-3 my-auto">
                  <mat-label>SKU Status</mat-label>
                </div>
                <div class="col-md">
                  <mat-form-field class="example-full-width">
                    <mat-select formControlName="status">
                      <mat-option [value]="'active'">
                        Active
                      </mat-option>
                      <mat-option [value]="'inactive'">
                        Inactive
                      </mat-option>

                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              @if
              (
              this.operation_type === 'add-sku' ||
              (this.hasInventory && this.BusinessType != 4)
              ){
              @for (item of selected_variant_values.controls; track item;let i = $index;) {
              <div class="d-block align-items-center mt-2" [formGroup]="item">
                <div>
                  <h3 class="title">{{item.value.variant_group_name | groupname}}</h3>
                </div>

                <div class="row mt-2">
                  <div class="col-md-5">
                    <mat-form-field class="example-full-width">
                      @if (item.value.variant_group_name == 'package_type'){
                      <mat-label>Select Package Type<span class="text-danger">*</span>&nbsp;&nbsp;Eg:
                        PC,Jar,Bag</mat-label>

                      }
                      @if (item.value.variant_group_name == 'uom'){
                      <mat-label>Select Unit of Measurement<span class="text-danger">*</span>&nbsp;&nbsp;Eg:
                        KG,Grm,Lit</mat-label>

                      }
                      @if (item.value.variant_group_name != 'uom' && item.value.variant_group_name != 'package_type'){
                      <mat-label>Select Variant</mat-label>
                      }

                      <mat-select formControlName="variant_types_id">
                        @for (option of variant_type_options[item.value.variant_group_name]; track $index) {
                        @if (shouldDisplayOption(option,item)) {

                        <mat-option [value]="option.id">{{
                          option.attributes.name
                          }}</mat-option>

                        }
                        }
                      </mat-select>
                    </mat-form-field>
                  </div>



                  <div class="col-md-5">
                    @if(getValueTypeForVariant(item.value.variant_group_name,item.value.variant_types_id)=='dropdown'){
                    <mat-form-field class="example-full-width">
                      <mat-label>Enter Value<span class="text-danger">*</span>&nbsp;&nbsp;Eg: 1,10</mat-label>
                      <mat-select formControlName="variant_value">
                        @for (option of variant_value_options[item.value.variant_types_id]; track $index) {
                        @if (shouldDisplayOption(option,item)) {
                        <mat-option [value]="option.attributes.name">{{
                          option.attributes.name
                          }}</mat-option>
                        }
                        }
                      </mat-select>
                    </mat-form-field>
                    }
                    @if(getValueTypeForVariant(item.value.variant_group_name,item.value.variant_types_id)=='text'){
                    <mat-form-field class="example-full-width">
                      <mat-label>Enter Value<span class="text-danger">*</span>&nbsp;&nbsp;Eg: 1,10</mat-label>
                      <input type="text" matInput formControlName="variant_value" readonly={{isReadonly}} />
                    </mat-form-field>
                    }
                    @if(getValueTypeForVariant(item.value.variant_group_name,item.value.variant_types_id)=='number'){
                    <mat-form-field class="example-full-width">
                      <mat-label>Enter Value<span class="text-danger">*</span>&nbsp;&nbsp;Eg: 1,10</mat-label>
                      <input type="number" matInput formControlName="variant_value" min="0"
                        oninput="validity.valid||(value=value.replace(/\-+/g, ''))" readonly={{isReadonly}} />
                    </mat-form-field>
                    }
                  </div>
                  <div class="col-md-2">
                    @if(item.value.variant_group_name=='other'){
                    <button type="button" (click)="deleteSkuVariant(item.value.id,i)" mat-raised-button color="warn"
                      matTooltip="Remove">
                      <mat-icon class="me-0">delete</mat-icon>
                    </button>
                    }
                  </div>
                </div>
              </div>
              }}
              @if
              (
              this.BusinessType == 4 && this.operation_type != 'add-sku'
              ){
              <div class="row" formGroupName="variants">
                <!-- <form [formGroup]="variants"> -->
                <div>
                  <h2 class="title">UOM</h2>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width">
                    <mat-label>Select Unit of Measurement<span class="text-danger">*</span>&nbsp;&nbsp;Eg:
                      KG,Grm,Lit</mat-label>
                    <input type="text" matInput formControlName="package_type"
                      [value]="selected_variants[0]?.variant_types_name" readonly />
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width">
                    <mat-label>Enter Value<span class="text-danger">*</span>&nbsp;&nbsp;Eg: 1,10</mat-label>
                    <input type="text" matInput formControlName="package_value"
                      [value]="selected_variants[0]?.variant_value" readonly />
                  </mat-form-field>
                </div>
                <div>
                  <h2 class="title">Package Type</h2>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width">
                    <mat-label>Select Package Type<span class="text-danger">*</span>&nbsp;&nbsp;Eg:
                      PC,Jar,Bag</mat-label>
                    <input matInput formControlName="uom" [value]="selected_variants[1]?.variant_types_name" readonly />
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width">
                    <mat-label>Enter Value<span class="text-danger">*</span>&nbsp;&nbsp;Eg: 1,10</mat-label>
                    <input matInput formControlName="uom_value" [value]="selected_variants[1]?.variant_value"
                      readonly />
                  </mat-form-field>

                </div>
                <!-- </form> -->
              </div>
              }
              @if(isMoreVariantTypeOptionAvailable()){
              <button type="button" mat-raised-button color="primary" class="m-2 mb-5"
                (click)="addSelectedVariantItem()" matTooltip="Add More Attribute">

                <mat-icon>add</mat-icon> Add
              </button>
              }
            </div>
            <div class="col-md-12">
              <div class="col-md-12 d-flex mb-3">
                <h3 class="title">Selling Unit</h3>
              </div>
              <mat-form-field class="example-chip-list w-100">
                <mat-label>Enter Selling Unit&nbsp;&nbsp;Eg: 20,360 (how Customer can
                  order SKU)</mat-label>
                <mat-chip-grid #chipGrid>
                  @for (unit of skuForm.value.sku.selling_units; track unit) {
                  <mat-chip-row (removed)="removeunit(unit)" [aria-description]="'press enter to edit ' + unit">
                    {{unit}}
                    <button matChipRemove [attr.aria-label]="'remove ' + unit">
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip-row>
                  }
                  <input oninput="this.value =this.value.replace(/[^0-9]/g, '');" placeholder="Enter Selling Unit"
                    class="w-100" [matChipInputFor]="chipGrid" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)" />
                </mat-chip-grid>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 d-flex mb-3">
              <h3 class="title">SKU Details</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="col-md-12">
                <mat-form-field class="example-full-width">
                  <mat-label>Enter Short Name<span class="text-danger"></span> &nbsp;&nbsp;Eg: BAM 250</mat-label>
                  <input matInput formControlName="print_name" />
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <mat-form-field class="example-full-width">
                  <mat-label>Enter Bar Code&nbsp;&nbsp;Eg: B25012443232W21</mat-label>
                  <input matInput formControlName="barcode" />
                </mat-form-field>
                <span class="text-danger mb-2" *ngIf="showBarcodeError">Barcode length should be 8 character.</span>

              </div>
              <div class="col-md-12">
                <mat-form-field class="example-full-width">
                  <mat-label>Short Description</mat-label>
                  <input matInput formControlName="short_description" />
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-6">
              <div class="col-md-12">
                <mat-label>Long Description</mat-label><br>
                <textarea class="form-control" formControlName="long_description" rows="7"></textarea>

              </div>
            </div>

          </div>

        </div>

        <div class="container-fluid" [formGroupName]="'sku_tax_details'">
          <h3 class="title">Tax Details</h3>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field class="example-full-width">
                <mat-label>Select Country<span class="text-danger">*</span>&nbsp;&nbsp;Eg: India</mat-label>
                <mat-select formControlName="countries_id">
                  @for (country of countryList; track country) {
                  <mat-option [value]="country.id">{{
                    country.attributes.name
                    }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field class="example-full-width">
                <mat-label>Select Tax Categories<span class="text-danger">*</span>&nbsp;&nbsp;Eg: Taxable,Non
                  Taxable</mat-label>
                <mat-select formControlName="tax_categories_id">
                  @for (category of tax_category_list; track category) {
                  <mat-option [value]="category.id">{{
                    category.attributes.name
                    }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              @if(tax_category_name == "Non-Taxable Supply"){
              <mat-form-field class="example-full-width">
                <mat-label>Select Exemption Reason<span class="text-danger">*</span>&nbsp;&nbsp;Eg: Nil
                  Rated,Exempted</mat-label>
                <mat-select formControlName="reason">
                  <mat-option value="Nil Rated">
                    Nil Rated
                  </mat-option>
                  <mat-option value="Exempted">
                    Exempted
                  </mat-option>
                </mat-select>
              </mat-form-field>
              }
            </div>
            <div class="col-md-4">
              @if(tax_category_name == "Taxable supply"){
              <mat-form-field class="example-full-width">
                <mat-label>Select Tax Rate<span class="text-danger">*</span>&nbsp;&nbsp;Eg: 5,12,18,28</mat-label>
                <mat-select formControlName="tax_percentage">
                  <mat-option [value]="0">
                    0%
                  </mat-option>
                  <mat-option [value]="5">
                    5%
                  </mat-option>
                  <mat-option [value]="12">
                    12%
                  </mat-option>
                  <mat-option [value]="18">
                    18%
                  </mat-option>
                  <mat-option [value]="28">
                    28%
                  </mat-option>

                </mat-select>
              </mat-form-field>
              }
              @if(tax_category_name != "Taxable supply"){
              <mat-form-field class="example-full-width">
                <mat-label>Tax Rate</mat-label>
                <input type="number" readonly matInput [value]="0" />
              </mat-form-field>
              }
            </div>
            <div class="col-md-4">
              @if(tax_category_name == "Taxable supply"){
              <mat-form-field class="example-full-width">
                <mat-label>Select Cess Rate&nbsp;&nbsp;Eg: 0.25,0.5,0,3,15,18,28</mat-label>
                <mat-select formControlName="cess_rate">
                  <mat-option [value]="0.25">
                    0.25%
                  </mat-option>
                  <mat-option [value]="0.5">
                    0.5%
                  </mat-option>

                  <mat-option [value]="0">
                    0%
                  </mat-option>

                  <mat-option [value]="3">
                    3%
                  </mat-option>
                  <mat-option [value]="12">
                    12%
                  </mat-option>
                  <mat-option [value]="15">
                    15%
                  </mat-option>
                  <mat-option [value]="18">
                    18%
                  </mat-option>
                  <mat-option [value]="28">
                    28%
                  </mat-option>


                </mat-select>
              </mat-form-field>
              }
              @if(tax_category_name != "Taxable supply"){
              <mat-form-field class="example-full-width">
                <mat-label>Cess Rate</mat-label>
                <input type="number" readonly matInput [value]="0" />
              </mat-form-field>
              }
            </div>

            <div class="col-md-4">
              <mat-form-field class="example-full-width">
                <mat-label>Enter HSN Code<span class="text-danger">*</span>&nbsp;&nbsp;Must be exactly 6 or 8 digits.Eg:
                  10023421</mat-label>
                <input type="text" oninput="this.value =this.value.replace(/[^0-9]/g, '');" matInput
                  formControlName="hsn_code" readonly={{isReadonly}} />
                <!-- <div *ngIf="this.skuForm?.get('hsn_code')?.hasError('hsnCodeInvalid')">
                    HSN Code must be 6 or 8 characters long.
                  </div> -->
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="container-fluid" [formGroupName]="'sku_packing_sections'">
          <h3 class="title">Packaging and Inventory</h3>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field class="example-full-width">
                <mat-label>Enter UPC (Unit per Case)&nbsp;&nbsp;Eg: 360</mat-label>
                <input type="number" matInput formControlName="upc" />
              </mat-form-field>
            </div>
            <div class="col-md-5">
              <mat-form-field class="example-full-width">
                <mat-label>Enter Inner Pack Qty (No. of packs in Case)&nbsp;&nbsp;Eg: 12</mat-label>
                <input type="number" matInput formControlName="inner_pack" readonly={{isReadonly}} />
              </mat-form-field>
            </div>
            <div class="col-md-3" formGroupName="weight_per_unit">

              <mat-form-field class="example-full-width">
                <mat-label>Enter Weight of SKU<span class="text-danger">*</span>&nbsp;&nbsp;Eg: 0.5,1,10</mat-label>
                <input type="number" matInput formControlName="kg" readonly={{isReadonly}} />
              </mat-form-field>

              <!--  <div class="col-md-6">
                  <mat-form-field class="example-full-width">
                    <mat-label>Grams</mat-label>
                    <input type="number" matInput formControlName="grams" />
                  </mat-form-field>
                </div> -->
            </div>


            <!--  <div class="col-md-12" formGroupName="dimensions">
              <div class="row">
                <div class="col-md-4">
                  <mat-form-field class="example-full-width">
                    <mat-label>Length</mat-label>
                  </mat-form-field>
                    <mat-label>Width</mat-label>
                    <input matInput formControlName="width" />
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field class="example-full-width">
                    <mat-label>Height</mat-label>
                    <input matInput formControlName="height" />
                  </mat-form-field>
                </div>
              </div>
            </div> -->

            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Enter Minimum Order Qty&nbsp;&nbsp;Eg: 1,5</mat-label>
                <input type="number" oninput="this.value =this.value.replace(/[^0-9]/g, '');" matInput
                  formControlName="min_order_qty" readonly={{isReadonly}} />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Enter Maximum Order Qty&nbsp;&nbsp;Eg: 1,5</mat-label>
                <input type="number" oninput="this.value =this.value.replace(/[^0-9]/g, '');" matInput
                  formControlName="max_order_qty" readonly={{isReadonly}} />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>UOM</mat-label>
                <input matInput formControlName="uom" readonly />
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Product Package Type</mat-label>
                <input matInput formControlName="product_package_type" readonly />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Display Order</mat-label>
                <input type="text" matInput formControlName="display_order" readonly={{isReadonly}} />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <mat-label>Pickup Order</mat-label>
                <input type="text" matInput formControlName="pickup_order" readonly={{isReadonly}} />
              </mat-form-field>
            </div>
          </div>
        </div>

      </mat-card>
      <mat-card class="p-3">
        <h3 class="title ms-3">Attributes</h3>
        <div class="container-fluid">

          @for (item of skuattributes.controls; track item;let i = $index;) {
          <div class="row mt-2" [formGroup]="item">
            <div class="col-md-5">
              <mat-form-field class="example-full-width w-100">
                <mat-label>Enter Attribute<span class="text-danger">*</span>&nbsp;&nbsp;Eg: Shelf life of
                  SKU</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Number" matInput formControlName="key"
                  [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                  @for (option of attributeOptions; track $index) {
                  <mat-option [value]="option.attributes.name">{{ option.attributes.name }}
                  </mat-option>
                  }
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="col-md-5">
              <mat-form-field class="example-full-width w-100">
                <mat-label>Enter Value<span class="text-danger">*</span>&nbsp;&nbsp;Eg: 6 Months</mat-label>
                <input matInput formControlName="value" />
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <button type="button" (click)="removeItem(i)" mat-raised-button color="warn" matTooltip="Remove">
                <mat-icon class="me-0">delete</mat-icon>
              </button>
            </div>
          </div>
          }

          <button type="button" mat-raised-button color="primary" class="m-2 mb-4" (click)="addAttributeItem()"
            matTooltip="Add More Attribute">

            <mat-icon>add</mat-icon> Add
          </button>
        </div>

        <div class="d-flex">
          <mat-form-field class="example-full-width">
            <mat-label>Need To select at least one From this List and Same SKU Name will be shown in Retailer Mobile
              App. @if(operation_type == 'add-sku'){
              @if(this.auth.isVendor()){
              <span class="text-danger">*</span>
              }
              }
            </mat-label>
            <mat-select [(ngModel)]="selectedSuggestion" [ngModelOptions]="{standalone: true}"
              (selectionChange)="onSuggestionSelected($event)">
              @for (suggestion of skuNameSuggestionOptions; track $index) {
              <mat-option [value]="suggestion">{{suggestion}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
          @if(!isReadonly){
          <button [disabled]="generatingSuggestions" type="button" mat-raised-button color="primary"
            class="m-2 px-2 py-1 h-100" (click)="fetchNameSuggestions()" matTooltip="Add More Attribute">

            <mat-icon>add</mat-icon> Generate Suggestions
          </button>
          }
        </div>


      </mat-card>



      <mat-card class="p-3 m-0">
        <h3 class="title">Images</h3>
        <p style="font-size: 16px;">
          <b>Note:</b>Permanent Image is required. Upload image should be 1280x720 size.
        </p>
        <!--  <span class="text-danger" *ngIf="skuForm.get('sku').get('sku_images').get('permanent_image').required && skuForm.get('sku').get('sku_images').get('permanent_image').touched">
        Please upload permanent Image.
        </span> -->
        <table class="table">
          <thead>
            <th>Image</th>
            <th style="width: 40%;">Upload</th>
            @if(this.auth.isAdmin()){
            <th style="width: 30%;">Remove Background</th>
            }

          </thead>
          <tbody>
            <tr>
              <td>
                <div class="d-flex align-items-center" *ngIf="sku_images?.permanent_image">
                  <img [src]="sku_images?.permanent_image" class="w-50">
                  <div>
                    <mat-icon aria-hidden="false" (click)="download(sku_images?.permanent_image)"
                      fontIcon="download"></mat-icon>
                    <mat-icon aria-hidden="false" fontIcon="close" (click)="remove('permanent_image')"></mat-icon>
                  </div>
                </div>

              </td>
              <td>
                <div>
                  Permanent Image<span class="text-danger">*</span>
                </div>
                <input type="file" class="form-control" accept="image/png, image/jpeg,image/jpg"
                  (change)="getFile($event,'permanent_image')">
              </td>
              @if(hasBMSAdmin){
              <td style="width: 30%;" formGroupName="sku_images">
                <div class="d-block" formGroupName="options">

                  <div class="d-flex justify-content-between" formGroupName="permanent_image_options">
                    <div class="w-50"> <input type="radio" value="yes" formControlName="background"> Yes
                    </div>
                    <div class="w-50"><input type="radio" value="no" formControlName="background"> No
                    </div>
                  </div>
                </div>
              </td>
              }
            </tr>
            <tr>
              <td>
                <div class="d-flex align-items-center" *ngIf="sku_images?.optional_image1">
                  <img [src]="sku_images?.optional_image1" class="w-50">
                  <div>
                    <mat-icon aria-hidden="false" (click)="download(sku_images?.optional_image1)"
                      fontIcon="download"></mat-icon>
                    <mat-icon aria-hidden="false" fontIcon="close" (click)="remove('optional_image1')"></mat-icon>
                  </div>
                </div>

              </td>
              <td>
                <div>
                  Optional Image 1
                </div>
                <input type="file" class="form-control" accept="image/png, image/jpeg,image/jpg"
                  (change)="getFile($event,'optional_image1')">
              </td>
              @if(hasBMSAdmin){
              <td style="width: 30%;" formGroupName="sku_images">
                <div class="d-block" formGroupName="options">

                  <div class="d-flex justify-content-between" formGroupName="optional_image1_options">
                    <div class="w-50"> <input type="radio" value="yes" formControlName="background"> Yes
                    </div>
                    <div class="w-50"><input type="radio" value="no" formControlName="background"> No
                    </div>
                  </div>
                </div>
              </td>
              }
            </tr>
            <tr>
              <td>
                <div class="d-flex align-items-center" *ngIf="sku_images?.optional_image2">
                  <img [src]="sku_images?.optional_image2" class="w-50">
                  <div>
                    <mat-icon aria-hidden="false" (click)="download(sku_images?.optional_image2)"
                      fontIcon="download"></mat-icon>
                    <mat-icon aria-hidden="false" fontIcon="close" (click)="remove('optional_image2')"></mat-icon>
                  </div>
                </div>

              </td>
              <td>
                <div>
                  Optional Image 2
                </div>
                <input type="file" class="form-control" accept="image/png, image/jpeg,image/jpg"
                  (change)="getFile($event,'optional_image2')">
              </td>
              @if(hasBMSAdmin){
              <td style="width: 30%;" formGroupName="sku_images">
                <div class="d-block" formGroupName="options">

                  <div class="d-flex justify-content-between" formGroupName="optional_image2_options">
                    <div class="w-50"> <input type="radio" value="yes" formControlName="background"> Yes
                    </div>
                    <div class="w-50"><input type="radio" value="no" formControlName="background"> No
                    </div>
                  </div>
                </div>
              </td>
              }
            </tr>
            <tr>
              <td>
                <div class="d-flex align-items-center" *ngIf="sku_images?.optional_image3">
                  <img [src]="sku_images?.optional_image3" class="w-50">
                  <div>
                    <mat-icon aria-hidden="false" (click)="download(sku_images?.optional_image3)"
                      fontIcon="download"></mat-icon>
                    <mat-icon aria-hidden="false" fontIcon="close" (click)="remove('optional_image3')"></mat-icon>
                  </div>
                </div>

              </td>
              <td>
                <div>
                  Optional Image 3
                </div>
                <input type="file" class="form-control" accept="image/png, image/jpeg,image/jpg"
                  (change)="getFile($event,'optional_image3')">
              </td>
              @if(hasBMSAdmin){
              <td style="width: 30%;" formGroupName="sku_images">
                <div class="d-block" formGroupName="options">

                  <div class="d-flex justify-content-between" formGroupName="optional_image3_options">
                    <div class="w-50"> <input type="radio" value="yes" formControlName="background"> Yes
                    </div>
                    <div class="w-50"><input type="radio" value="no" formControlName="background"> No
                    </div>
                  </div>
                </div>
              </td>
              }
            </tr>
            <tr>
              <td>
                <div class="d-flex align-items-center" *ngIf="sku_images?.optional_image4">
                  <img [src]="sku_images?.optional_image4" class="w-50">
                  <div>
                    <mat-icon aria-hidden="false" (click)="download(sku_images?.optional_image4)"
                      fontIcon="download"></mat-icon>
                    <mat-icon aria-hidden="false" fontIcon="close" (click)="remove('optional_image4')"></mat-icon>
                  </div>
                </div>

              </td>
              <td>
                <div>
                  Optional Image 4
                </div>
                <input type="file" class="form-control" accept="image/png, image/jpeg,image/jpg"
                  (change)="getFile($event,'optional_image4')">
              </td>
              @if(hasBMSAdmin){
              <td style="width: 30%;" formGroupName="sku_images">
                <div class="d-block" formGroupName="options">

                  <div class="d-flex justify-content-between" formGroupName="optional_image4_options">
                    <div class="w-50"> <input type="radio" value="yes" formControlName="background"> Yes
                    </div>
                    <div class="w-50"><input type="radio" value="no" formControlName="background"> No
                    </div>
                  </div>
                </div>
              </td>
              }
            </tr>
          </tbody>
        </table>
      </mat-card>
    </div>



    <div class="row my-3">
      <div class="col-md-12 text-center">
        @if(isSendRequestForApprovalVisible()){
        <button mat-raised-button [disabled]="isSendRequestForApprovalEnable()" (click)="requestApproval()"
          color="primary">
          {{ "Send Request for approval" }}
        </button>
        }
        @if(isPublishVisible()){
        <button mat-raised-button [disabled]="isPublishEnable()" (click)="publish()" color="primary" class="mx-2">
          {{ "Publish" }}
        </button>
        @if((operation_type=='add-sku' || operation_type=='update-sku') && !skuForm.value.vendor_id){
        <button [disabled]="!skuForm?.value?.sku?.name" mat-raised-button (click)="submit()" color="primary">
          {{ "Save as Draft" }}
        </button>
        }
        }

      </div>
    </div>
  </form>
</div>