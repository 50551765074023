import { Component } from '@angular/core';
import { BaseListComponent } from '../../shared/core/base.list.component';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import {
  RouterOutlet,
  RouterLink,
  Router,
  ActivatedRoute,
} from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { PageId } from '../../constants/enums';
import { AppLoaderService } from '../../shared/app-loader/app-loader.service';
import { MatDialog } from '@angular/material/dialog';
import { ProductBulkImportPopUpComponent } from './product-bulk-import-pop-up/product-bulk-import-pop-up.component';
import { uuidv4 } from '../../utlity/utility';
import { bulkImportStatus } from '../../enums/enums';
import Swal from 'sweetalert2';
import { DB_PATH } from '../../constants/db-path';
import { FirestoreService } from '../../services/firestore.service';
import { ProductBulkImportService } from './services/product-bulk-import.service';
import saveAs from 'file-saver';

interface ImportData {
  id: string;
  name: string;
}
@Component({
  selector: 'app-bulk-import',
  standalone: true,
  imports: [
    MatButtonModule,
    RouterOutlet,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    CommonModule,
    RouterLink,
    ReactiveFormsModule,
  ],
  templateUrl: './product-bulk-import.component.html',
  styleUrl: './product-bulk-import.component.scss',
})
export class ProductBulkImportComponent extends BaseListComponent {
  displayedColumns: string[] = ['name', 'download', 'upload'];
  listDisplayedColumns: string[] = ['jobType', 'fileName', 'userName', 'creationDate', 'status']
  bulkImportList: any[] = [];
  dataSource: any[] = [];
  userEmail: any;
  constructor(
    auth: AuthService,
    router: Router,
    private fb: FormBuilder,
    route: ActivatedRoute,
    private egretLoader: AppLoaderService,
    private dialog: MatDialog,
    private fireStoreService: FirestoreService,
    private productBulkImportService: ProductBulkImportService
  ) {
    super(auth, router, route, PageId.bms_bulk_import);
  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.egretLoader.open();
    this.dataSource = [
      { id: 1, name: 'Display Order' }
    ];
    this.userEmail = this.auth.userSubject.value?.email;
    await this.getAllBulkImportList();
    this.egretLoader.close();
  }

  private getCurrentTimestamp() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  getCurrentUnixTimestamp() {
    return Math.floor(Date.now() / 1000);
  }

  async getAllBulkImportList() {
    return this.bulkImportList = await this.productBulkImportService.getAllProductBulkImportData();
  }
  async prepareJson(file: any, row: any): Promise<void> {
    const jobId = uuidv4();
    const insertEntity = {
      jobType: row.name,
      jobId: jobId,
      jobName: row.name,
      status: bulkImportStatus[1],
      fileName: file.fileName,
      fileUrl: file.excelURL,
      emailId: this.userEmail,
      createdDate: this.getCurrentTimestamp(),
      timeStamp: this.getCurrentUnixTimestamp()
    };

    await this.fireStoreService.insertDocWithId(DB_PATH.BULK_IMPORT, jobId, insertEntity);
  }

  openPopUp(row: ImportData): void {
    const dialogRef = this.dialog.open(ProductBulkImportPopUpComponent, {
      width: "50%",
      height: "40%",
      data: {
        title: 'Upload Excel',
        row: row
      }
    });

    dialogRef.afterClosed().subscribe(async (response: any) => {
      if (response) {
        this.egretLoader.open();
        await this.prepareJson(response, row);
        await this.getAllBulkImportList();
        this.egretLoader.close();
        Swal.fire("Success", " Successfully Uploaded", "success");
      }
    });
  }

  async downloadProductsData(): Promise<void> {
    try {
      const response = await this.productBulkImportService.downloadProductDataExcel();
      const filename = 'Products data.xlsx';
      saveAs(response, filename);
      Swal.fire("Success", "Excel File Downloaded Successfully", "success");
    } catch (error) {
      console.log("error in download excel :: ", error)
      Swal.fire("Error", "Failed to download Excel file", "error");
    } finally {
      this.egretLoader.close();
    }

  }
}

