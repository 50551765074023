import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import Swal from 'sweetalert2';
import { AppLoaderService } from '../../../../../../shared/app-loader/app-loader.service';
import { AuthService } from '../../../../../../services/auth.service';
import { TripService } from '../../../services/trip.service';
import { MatDialog } from '@angular/material/dialog';
import { ProductEditComponent } from '../product-edit/product-edit.component';
import { HopItems } from '../../../models/trip.model';
import { TripDataService } from '../../../services/trip-data.service';
import { BaseListComponent } from '../../../../../../shared/core/base.list.component';
import { PageId } from '../../../../../../constants/enums';
import { AppStateService } from '../../../../../../services/appstate.service';
import { takeUntil } from 'rxjs';
import { MappedTrip } from '../../../../../../entities/trip-info';
import {
  MerchantBusinessType,
  OrderStatus,
  TripSource,
} from '../../../../../../enums/enums';
import { DB_HOP } from '../../../../../../entities/hop-db.model';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ReplaceCommasPipe } from '../../../../../../shared/pipes/currency-pipe';
import { ValidateStockUpdate } from '../../../../../../entities/order-modify.model';

interface ItemsView {
  itemId: string;
  itemName: string;
  imageUrl: string;
  upc: number;
  quantity: number;
  invoicedQty: number;
  saleValue: number;
  unitWeight: number;
  pieces: number;
  cases: number;
  vendorBusinessName: string;
  vendorId: string;
  vendorBusinessTypeId: MerchantBusinessType;
  orders: {
    orderNumber: string;
    orderDate: Date | string;
    qty: number;
    orderStatusId: OrderStatus;
  }[];
  itemKey: string;
}
interface OrderToModify {
  orderNumber: string;
  quantity: number;
  skuId: string;
}
@Component({
  selector: 'app-product-view',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    MatSidenavModule,
    ReactiveFormsModule,
    ReplaceCommasPipe,
  ],
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.scss'],
})
export class ProductViewComponent extends BaseListComponent {
  currentHop: DB_HOP | null = null;
  currentTrip: MappedTrip | null = null;
  hopItems: HopItems[] = [];
  items: ItemsView[] = [];
  hopId!: string;
  form: FormGroup;
  displayedColumns = [
    'productName',
    'sellerName',
    'cases',
    'pcs',
    'totalQuantity',
    'invoicedQty',
    'saleValue',
    'edit',
  ];
  enableEdit: boolean = false;
  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    public readonly tripService: TripService,
    private loader: AppLoaderService,
    private dialog: MatDialog,
    public tripDataService: TripDataService,
    private appState: AppStateService
  ) {
    super(auth, router, route, PageId.logistics_trips);
    this.form = this.fb.group({
      name: [null],
    });
    this.mapToCurrentTrip();
    this.mapToCurrentHop();
  }
  override async ngOnInit() {
    super.ngOnInit();
    this.route.params.subscribe(async (params: any) => {
      this.hopId = params['id'];
      if (this.hopId) {
        await this.getHopItems(this.hopId);
        this.searchHopItems();
      }
    });
  }

  private mapToCurrentTrip() {
    this.appState.selectedTrip$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((trip) => {
        if (trip) {
          this.currentTrip = trip;
        } else {
          const localStorageTrip: string | null =
            localStorage.getItem('tripDetails');
          const trip = localStorageTrip && JSON.parse(localStorageTrip);
          this.appState.setSelectedTrip(trip);
          this.currentTrip = trip;
        }
      });
  }

  private mapToCurrentHop() {
    this.appState.selectedHop$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((hop) => {
        if (hop) {
          this.currentHop = hop;
        } else {
          // TODO
        }
      });
  }
  async getHopItems(hopId: string) {
    try {
      this.hopItems = await this.tripService.getHopItems(hopId);
      this.items = this.mapProducts(this.hopItems).filter(
        (c) => c.quantity > 0
      );
    } catch (error) {
      throw error;
    }
  }

  private mapProducts(orderItems: HopItems[]) {
    const productsMap = new Map<string, ItemsView>();

    for (const item of orderItems) {
      if (productsMap.has(item.itemKey)) {
        const existingItem: ItemsView | any = productsMap.get(item.itemKey);
        existingItem.quantity += item.quantity;
        const { pieces, cases } = this.tripService.getItemQtyInCasesPieces(
          existingItem.upc,
          existingItem.quantity
        );
        existingItem.pieces = pieces;
        existingItem.cases = cases;

        if (item.orderStatusId >= OrderStatus.OrderInvoiced) {
          existingItem.invoicedQty += item.quantity;
        }
        (existingItem.saleValue += item.sellingPrice * item.quantity),
          existingItem.orders.push({
            orderNumber: item.orderNumber,
            orderDate: item.orderDate,
            qty: item.quantity,
            orderStatusId: item.orderStatusId,
          });
        productsMap.set(item.itemKey, existingItem);
      } else {
        const mappedItem: ItemsView = {
          itemId: item.itemId,
          itemName: item.itemName,
          imageUrl: item.imageUrl,
          upc: item.upc,
          quantity: item.quantity,
          unitWeight: item.unitWeight,
          saleValue: Math.round(item.sellingPrice * item.quantity),
          pieces: item.pieces,
          cases: item.cases,
          vendorBusinessName: item.vendorBusinessName,
          vendorId: item.vendorId,
          vendorBusinessTypeId: item.vendorBusinessTypeId,
          invoicedQty:
            item.orderStatusId >= OrderStatus.OrderInvoiced ? item.quantity : 0,
          orders: [
            {
              orderNumber: item.orderNumber,
              orderDate: item.orderDate,
              qty: item.quantity,
              orderStatusId: item.orderStatusId,
            },
          ],
          itemKey: item.itemKey,
        };
        productsMap.set(item.itemKey, mappedItem);
      }
    }
    return Array.from(productsMap.values());
  }

  editProduct(item: ItemsView) {
    const dialogRef = this.dialog.open(ProductEditComponent, {
      width: '80%',
      height: '50%',
      data: {
        upc: +item.upc || 0,
        name: item.itemName,
        orderedQty: item.quantity - item.invoicedQty,
      },
    });
    dialogRef
      .afterClosed()
      .subscribe(async (res: { availableQty: number; damageQty: number }) => {
        const response = await this.processModifyItem(
          item,
          res.availableQty,
          res.damageQty
        );
      });
  }

  private async processModifyItem(
    item: ItemsView,
    availableQty: number,
    damageQty: number = 0
  ) {
    try {
      /**
       * Modify Order
       * Update Hop Items
       * Update Stock
       */
      // damageQty NEED TO HANDEL THIS WHEN STOCK  UPDATING
      const qtyToDecrees = item.quantity - item.invoicedQty - availableQty;

      // Orders to be modified
      const orderToBeModified = this.ordersToModify(item, qtyToDecrees);

      let inventoryUpdateRequired = {
        inventoryQty: 0,
        tripsQty: 0,
        updateRequired: false,
      };

      if (this.currentTrip?.source === TripSource.Qwipo) {
        const inventoryCheckPayload: ValidateStockUpdate = {
          vendor_id: item.vendorId,
          sku_id: item.itemId,
          date: this.currentTrip?.targetDate ?? new Date()
        };
         inventoryUpdateRequired = await this.tripService.inventoryUpdateCheck(inventoryCheckPayload);
      }
      // Modify Order
      const orderUpdateRes = await this.modifyOrders(orderToBeModified);

      // Update Hop Items
      const updateHopRes = await this.modifyHopItems(orderToBeModified, item);

      // Update Stock
      if (this.currentTrip?.source === TripSource.Qwipo && inventoryUpdateRequired.updateRequired) {
        const updateStockRes = await this.updateItemStock(
          item.itemId,
          item.vendorId,
          // availableQty,
          qtyToDecrees,
          damageQty
        );
      }

      this.showMessage(` Modified Successfully`, 'success');
      await this.getHopItems(this.hopId);
    } catch (error) {
      this.showMessage('Modify Failed', 'warning');
      throw error;
    }
  }

  private ordersToModify(item: ItemsView, qtyToDecrees: number) {
    const sortedOrders = item.orders
      .filter((o) => o.orderStatusId < OrderStatus.OrderInvoiced)
      .sort((a, b) => b.qty - a.qty); // Sort orders by quantity (descending)
    let remainingQty = qtyToDecrees;
    const fulfilledOrders: OrderToModify[] = [];

    for (const order of sortedOrders) {
      if (order.qty <= remainingQty) {
        remainingQty -= order.qty;
        fulfilledOrders.push({
          orderNumber: order.orderNumber,
          skuId: item.itemId,
          quantity: order.qty,
        });
        // order.qty = 0; // Set order quantity to 0 if fully fulfilled
      } else {
        fulfilledOrders.push({
          orderNumber: order.orderNumber,
          skuId: item.itemId,
          quantity: remainingQty,
        });
        // order.qty -= remainingQty;
        remainingQty = 0;
        break; // Stop iterating when available quantity is depleted
      }
    }

    item.quantity -= qtyToDecrees; // Update overall item quantity
    return fulfilledOrders; // Return an array of fulfilled orders
  }

  private async modifyOrders(ordersToBeModified: OrderToModify[]) {
    try {
      const mappedOrderModify = ordersToBeModified.map((order) => {
        return {
          orderNumber: order.orderNumber,
          modifiedItems: [
            {
              reason: `Modified by ${this.userId} for hop item`,
              quantity: order.quantity,
              sku_id: order.skuId,
            },
          ],
        };
      });
      const response = await this.tripService.orderModifyBulk(
        mappedOrderModify
      );

      return response;
    } catch (error) {
      throw error;
    }
  }

  private async modifyHopItems(
    ordersToBeModified: OrderToModify[],
    item: ItemsView
  ) {
    try {
      const payload = ordersToBeModified.map((order) => {
        const itemOrder = item.orders.find(
          (o) => o.orderNumber == order.orderNumber
        );

        const qty: number = itemOrder?.qty
          ? itemOrder?.qty - order.quantity
          : itemOrder?.qty || order.quantity;
        return {
          item_id: order.skuId,
          quantity: qty,
          order_number: order.orderNumber,
          weight: item.unitWeight * qty,
        };
      });

      const updateHopItemsRes = await this.tripService.updateHopItems(
        this.hopId,
        payload
      );
      return updateHopItemsRes;
    } catch (error) {
      // TODO log the error
      throw error;
    }
  }

  private async updateItemStock(
    skuId: string,
    vendorId: string,
    // availableQty: number,
    qtyToDecrees: number,
    damage: number
  ) {
    try {
      // Not handling Damage now.
      // SKU
      const response = await this.tripService.decrementQtyByOrderModify(
        skuId,
        vendorId,
        qtyToDecrees
        // availableQty
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  private showMessage(message: string, SwalMessageTypes: any) {
    Swal.fire('', message, SwalMessageTypes);
  }

  async reset() {
    this.form.patchValue({
      name: null,
    });
    await this.getHopItems(this.hopId);
  }

  searchHopItems(): void {
    this.form.valueChanges.subscribe((value: any) => {
      if (value.name && value.name.length > 0) {
        const result = this.items.filter(
          (hopOrder: any) =>
            hopOrder.itemName &&
            hopOrder.itemName.toLowerCase().includes(value.name.toLowerCase())
        );
        this.items = result;
      } else {
        this.items;
      }
    });
  }
}
