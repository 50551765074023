import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import * as XLSX from 'xlsx';
import {
  ChargeType,
  DistributeCharges,
  ExpenseType,
  LookUpType,
  PurchaseOrderStatus,
  TaxDeductionType,
  UOM,
} from '../../../enums/enums';
import { LookUpTypeService } from '../../../services/lookup-type.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PurchaseReceiptService } from '../service/purchasereceipt.service';
import { AddGrnComponent } from '../add-product/add-grn/add-grn.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../../services/auth.service';
import { ConfigService } from '../../config/services/config.service';
import { CompleteGrnComponent } from '../complete-grn/complete-grn.component';
import Swal from 'sweetalert2';
import { Expense, Item } from '../models/purchasereceipt-model';
import imageCompression from 'browser-image-compression';
import { SupplierPayoutComponent } from '../supplier-payout/supplier-payout.component';
import { PageId } from '../../../constants/enums';
import { BaseListComponent } from '../../../shared/core/base.list.component';
import { takeUntil } from 'rxjs/operators';
import { ReplaceCommasPipe } from '../../../shared/pipes/currency-pipe';
import { AppLoaderService } from '../../../shared/app-loader/app-loader.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { sum } from 'lodash';
import { BrowserModule } from '@angular/platform-browser';
import { time } from '@rxweb/reactive-form-validators';
import { getDateFormat } from '../../../utlity/utility';

// import { TaxDeductionType, ExpenseType, } from '../models/purchasereceipt-model';

@Component({
  selector: 'app-purchase-order-edit',
  standalone: true,
  imports: [
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatCardModule,
    MatTableModule,
    RouterLink,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatOptionModule,
    ReplaceCommasPipe,
    MatProgressSpinnerModule,
    MatFormFieldModule,
  ],
  templateUrl: './purchase-order-edit.component.html',
  styleUrl: './purchase-order-edit.component.scss',
})
export class PurchaseOrderEditComponent extends BaseListComponent {
  form: FormGroup;
  id: string = '';
  supplierId: string = '';
  uploadedFiles: { [key: string]: any } = {};
  purchaseBillUrl: string = '';
  otherBillsUrl: string = '';
  loading: boolean = true;
  otherBillsFileName: string = '';
  finalPayouts: Array<any> = [];
  hashCode = 0;
  displayedColumns: string[] = [
    'product',
    'uom',
    'mrp',
    'tax',
    'requestedUomQty',
    'requestedPcs',
    'actualUomQty',
    'actualpcs',
    'requestedInvoiceAmount',
    'baseAmount',
    'cgst',
    'sgst',
    'igst',
    'cess',
    'discount',
    'netpayable',
    'landingCost',
    'action',
  ];
  PrItems: any = [];
  itemsList: any = [];
  dataSource2: any = [];
  res: any;
  supplierNames: LookUpType = this.initializeSuppliers();
  purchaseOrderStatus: any = Object.values(PurchaseOrderStatus).filter(
    (value) => typeof value === 'number'
  );
  expensesType: any = Object.values(ExpenseType).filter(
    (value) => typeof value === 'number'
  );
  excelData: any = { items: [], expenses: [] };

  public suppliersList: any[] = [];
  displayedColumnss: String[] = [
    'expensestype',
    'totalvalue',
    'supplier',
    'payout',
    'action',
  ];
  summaryDisplayedColumns: string[] = [
    'supplier',
    'expense_type',
    'baseValue',
    'cgst',
    'sgst',
    'igst',
    'cess',
    'tds',
    'rate',
    'payout',
  ];

  dataSource = [];
  summary: any[] = [];
  constructor(
    auth: AuthService,
    router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    private lookupService: LookUpTypeService,
    public authService: AuthService,
    public configService: ConfigService,
    public purchaseReceiptService: PurchaseReceiptService,
    public dialog: MatDialog,
    private loader: AppLoaderService
  ) {
    super(auth, router, route, PageId.seller_pr);
    this.form = this.fb.group({
      supplierName: [null, Validators.required],
      status: [null, Validators.required],
      productTaxType: '',
      createdDate: [null, Validators.required],
      purchaseBill: '',
      otherBills: '',
      invoiceRfNo: '',
      supplierGstn: '',
      tdsPercent: '',
      comments: '',
    });
  }
  status: { name: string; value: PurchaseOrderStatus }[] = [
    { name: 'Created', value: PurchaseOrderStatus.Created },
    { name: 'Requested For GRN', value: PurchaseOrderStatus.RequestedForGRN },
    { name: 'GRN Completed', value: PurchaseOrderStatus.GRNCompleted },
    {
      name: 'Requested For Approval',
      value: PurchaseOrderStatus.RequestedForApproval,
    },
    { name: 'Approved', value: PurchaseOrderStatus.Approved },
  ];

  taxes: { name: string; value: TaxDeductionType }[] = [
    { name: 'Inclusive', value: TaxDeductionType.Inclusive },
    { name: ' Exclusive', value: TaxDeductionType.Exclusive },
  ];
  buttonShow = {
    isAddProductShow: false,
    isGrnShow: false,
    isItemEditShow: false,
    isExpenseEditShow: false,
    isItemDeleteShow: false,
    isExpenseDeleteShow: false,
    isAddExpenseShow: false,
    isCalculateLpShow: false,
    isSaveShow: false,
    isRequestForGrnShow: false,
    isCompleteGrnShow: false,
    isRequestForApprovalShow: false,
    isApproveShow: false,
    isUploadFilesShow: false,
  };

  override async ngOnInit() {
    await super.ngOnInit();
    this.loading = false;
    if (this.vendorId) {
      await this.getSuppliers();
      this.route.params
        .pipe(
          takeUntil(this.unsubscribe$) // Unsubscribe when the component is destroyed
        )
        .subscribe(async (params) => {
          this.id = params['id'];
        });
      await this.getPurchaseReceiptDetails();
      this.disableTaxType();
      // this.calculateSummary();
    }
  }
  async getPurchaseReceipt(id: string) {
    this.res = await this.purchaseReceiptService.getPurchaseReceiptById(id);
    this.supplierId = this.res.supplier_id;
    this.purchaseBillUrl = this.res.purchase_bill;
    this.otherBillsUrl = this.res.other_bills;
    this.form = this.mapToEditInput(this.res);
  }
  getSupplierNameByID(supplierId: string) {
    for (const supplier of this.suppliersList) {
      if (supplier.id == supplierId) {
        return supplier.value;
      }
    }
    return null;
  }
  async getSuppliers() {
    const response = await this.lookupService.getLookUpTypes(
      this.supplierNames
    );
    this.suppliersList = response.data[0].data;
  }

  private initializeSuppliers(): LookUpType {
    return {
      tableName: 'suppliers',
      lookupType: 'suppliers',
      idColumnName: 'Id',
      valueColumnName: 'Name',
    };
  }

  async getPurchaseReceiptSkus(prId: string) {
    this.itemsList = await this.purchaseReceiptService.getPurchaseReceiptItems(
      prId
    );

    this.PrItems = this.mapToItems(this.itemsList);

    return this.PrItems;
  }

  async getExpenses(id: string) {
    const res = await this.purchaseReceiptService.getPrExpenses(id);

    this.dataSource2 = this.mapToExpense(res);
    return this.dataSource2;
  }
  async navigateToAddProduct() {
    await this.checkAnyPRChanges();
    this.router.navigateByUrl(`/dashboard/pocreation/addproduct/${this.id}`);
  }

  async navigateToAddExpenses() {
    await this.checkAnyPRChanges();
    this.router.navigateByUrl(
      `/dashboard/pocreation/addexpenses/${this.id}/${this.supplierId}`
    );
  }

  navigateBackToList() {
    this.router.navigate(['/dashboard/pocreation/createpurchaseorder']);
  }

  async productEditComponent(id: string) {
    await this.checkAnyPRChanges();
    this.router.navigateByUrl(
      `/dashboard/pocreation/producteditComponent/${this.id}/${id}`
    );
  }

  async expensesEditComponent(id: string) {
    await this.checkAnyPRChanges();
    this.router.navigateByUrl(
      `/dashboard/pocreation/edit-expenses/${this.id}/${id}`
    );
  }

  async deleteExpense(id: string) {
    const res = await this.purchaseReceiptService.deleteExpense(id);
    await this.getExpenses(this.id);
  }
  async deleteSku(id: string) {
    const res = await this.purchaseReceiptService.deletePrSkus(id);
    await this.getPurchaseReceiptSkus(this.id);
  }

  getStatusName(status: number): string {
    return PurchaseOrderStatus[status];
  }
  getExpenseType(status: number): string {
    return ExpenseType[status];
  }

  getTaxName(status: number): string {
    return TaxDeductionType[status];
  }
  getUomType(type: number): string {
    return UOM[type];
  }
  mapToEditInput(res: any): FormGroup {
    const supplierName = new FormControl(
      this.getSupplierNameByID(res.supplier_id) || ''
    );
    const status = new FormControl(this.getPrStatusText(res.status) || '');
    let initialValues = {
      invoiceNumber: res.invoice_reference_number,
      tdsPercent: res.tds_percent || 0,
      tdsValue: (res.tds_value && +res.tds_value) || 0,
      taxDeductionType: res.tax_deduction_type,
    };
    this.hashCode = this.generateHashCode(initialValues);
    return this.fb.group({
      supplierName: supplierName,
      status: status,
      productTaxType: new FormControl(
        res.tax_deduction_type || TaxDeductionType.Inclusive
      ),
      createdDate: new FormControl(res.purchase_date || ''),
      // purchaseBill: new FormControl(res.purchase_bill || ''),
      // otherBills: new FormControl(res.other_bills || ''),
      invoiceRfNo: new FormControl(res.invoice_reference_number || ''),
      supplierGstn: new FormControl(res.supplier_gst || ''),
      tdsPercent: new FormControl(res.tds_percent || ''),
      comments: new FormControl(res.comments || ''),
    });
  }
  private async checkAnyPRChanges() {
    const prDetails = this.form.value;
    const newHashCode = this.generateHashCode(this.mapToHashObject(prDetails));
    if (newHashCode !== this.hashCode) {
      await this.partialSave();
      this.hashCode = newHashCode;
    }
  }
  private mapToHashObject(pr: any) {
    return {
      invoiceReferenceNumber: pr.invoiceRfNo,
      taxDeductionType: pr.productTaxType,
      tdsPercent: pr.tdsPercent || 0,
      tdsValue: pr.tdsValue || 0,
    };
  }

  private generateHashCode(obj: any) {
    // Initialize a hash variable
    let hash = 0;

    // Iterate through object properties
    for (const key in obj) {
      // Get the value of the property
      const value = obj[key];

      // Convert the value to a string (handles different data types)
      const stringValue = JSON.stringify(value);

      // Add the string value's hash code to the overall hash
      hash += stringValue
        .split('')
        .reduce((acc, char) => acc + char.charCodeAt(0), 0);
    }

    // Return the final hash value
    return hash;
  }
  addGrn(id: string) {
    const dialogRef = this.dialog.open(AddGrnComponent, {
      width: '35%',
      height: '50%',
      data: id,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      await this.getPurchaseReceiptDetails();
    });
  }

  private async partialSave() {
    try {
      // await this.uploadFiles();
      const taxDeductionType = this.form.get('productTaxType')?.value;
      const prDetails = this.form.value;
      const pr = this.mapPr(prDetails);
      const skus = await this.getPurchaseReceiptSkus(this.id);
      const expenses = await this.getExpenses(this.id);
      const finalPrObject = this.mapToObject(
        pr,
        skus,
        expenses,
        taxDeductionType
      );
      const res = await this.purchaseReceiptService.calculateValues(
        finalPrObject,
        this.vendor
      );
    } catch (error) {}
  }

  async save() {
    try {
      this.loading = true;
      await this.uploadFiles();
      const taxDeductionType = this.form.get('productTaxType')?.value;
      const prDetails = this.form.value;
      const pr = this.mapPr(prDetails);
      const skus = await this.getPurchaseReceiptSkus(this.id);

      const expenses = await this.getExpenses(this.id);
      const finalPrObject = this.mapToObject(
        pr,
        skus,
        expenses,
        taxDeductionType
      );
      const res = await this.purchaseReceiptService.calculateValues(
        finalPrObject,
        this.vendor
      );
      this.loading = false;
      if (res.success) {
        Swal.fire(
          'Success',
          'Successfully Saved the Purchase Receipt',
          'success'
        );
        await this.getPurchaseReceiptDetails();
      }
    } catch (error) {
      Swal.fire(
        'Warning',
        'Saving Purchase Receipt Failed, Please try again later',
        'warning'
      );
    }
  }

  async requestApproval() {
    const taxDeductionType = this.form.get('productTaxType')?.value;
    const prDetails = this.form.value;
    const pr = this.mapPr(prDetails);
    const skus = await this.getPurchaseReceiptSkus(this.id);
    const expenses = await this.getExpenses(this.id);
    const finalPrObject = this.mapToObject(
      pr,
      skus,
      expenses,
      taxDeductionType
    );
    const res = await this.purchaseReceiptService.calculateValues(
      finalPrObject,
      this.vendor
    );

    const dialogRef = this.dialog.open(SupplierPayoutComponent, {
      width: '50%',
      maxHeight: '80vh',
      data: this.id,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      await this.getPurchaseReceiptDetails();
      this.disableTaxType();
    });
  }

  mapToObject(pr: any, skus: any, expenses: any, tax_deduction_type: any) {
    return {
      receipt_id: this.id,
      internal_receipt_number: '',
      invoice_reference_number: pr.invoiceRfNo,
      purchase_date: pr.createdDate,
      received_date: this.res.received_date,
      supplier_id: this.supplierId,
      status: pr.status,
      tax_deduction_type: tax_deduction_type,
      tcs_percent: 0,
      tcs_value: 0,
      payout: 0,
      supplier_gst: pr.supplierGstn,
      tds_percent: pr.tdsPercent,
      tds_value: 0,
      comments: pr.comments,
      created_by: this.res.created_by,
      grn_by: this.res.grn_by,
      approval_by: this.res.approval_by,
      files: [],
      items: skus,
      expenses: expenses,
      purchase_bill: this.purchaseBillUrl || pr.purchaseBill,
      other_bills: this.otherBillsUrl || pr.otherBills,
    };
  }
  mapPr(pr: any) {
    return {
      ...pr,
      ...this.parseNumericFieldsOfPr(pr),
      status: this.res.status,
    };
  }
  private parseNumericFieldsOfPr(obj: any): any {
    const numericFields = [
      'status',
      'taxDeductionType',
      'tcsPercent',
      'tcsValue',
      'tdsPercent',
      'tdsValue',
    ];

    const parsedObj: any = { ...obj };

    numericFields.forEach((field) => {
      if (typeof parsedObj[field] === 'string') {
        parsedObj[field] = +parsedObj[field];
      }
    });

    return parsedObj;
  }

  async requestGrn() {
    try {
      if (this.PrItems.length > 0) {
        const body = this.mapToGrnBody(PurchaseOrderStatus.RequestedForGRN);
        const res = await this.purchaseReceiptService.updatePrAttributes(
          body,
          this.id
        );
        await this.getPurchaseReceiptDetails();
        this.disableTaxType();
        Swal.fire('success', 'GRN request submitted successfully', 'success');
        this.router.navigateByUrl('/dashboard/pocreation');
      } else {
        Swal.fire(
          'warning',
          'Please Add the items before request for GRN',
          'warning'
        );
      }
    } catch (error) {}
  }
  mapToGrnBody(status: any) {
    return [
      {
        status: status,
      },
    ];
  }

  private isDataEmpty(data: any) {
    return Object.keys(data).every((key) => data[key].length === 0);
  }
  async downloadPr() {
    const prDetails = this.form.value;
    const pr = this.mapPr(prDetails);
    const taxDeductionType = this.form.get('productTaxType')?.value;
    const items = await this.getPurchaseReceiptSkus(this.id);
    const expenses = await this.getExpenses(this.id);
    const finalPrObject = this.mapToObject(
      pr,
      items,
      expenses,
      taxDeductionType
    );
    // const res = await this.purchaseReceiptService.calculateValues(
    //   finalPrObject,
    //   this.vendor
    // );
    const summary = await this.calculateSummary();

    const expensePayouts = this.mapToExpensePayout(expenses);

    const prPayout = this.mapToPrPayout(finalPrObject);
    expensePayouts.push(prPayout);
    this.excelData.items = items.map((item: Item) => ({
      supplier_invoice_number: pr.invoiceRfNo,
      ...item,
      uom_type: UOM[item.uom_type],
    }));
    this.excelData.expenses = expenses.map((expense: Expense) => ({
      ...expense,
      expense_type: ExpenseType[expense.expense_type],
      charge_type: ChargeType[expense.charge_type],
      supplier_id: this.suppliersList.find(
        (supplier) => supplier.id === expense.supplier_id
      )?.value,
    }));

    const transformedSummary = summary.map((record) => {
      const supplier = this.suppliersList.find(
        (supplier) => supplier.id === record.supplier
      )?.value;
      const expenseType =
        record.expense_type !== undefined
          ? typeof record.expense_type === 'number'
            ? ExpenseType[record.expense_type]
            : record.expense_type
          : 'Procurement';

      return {
        supplier: supplier,
        expense_type: expenseType,
        base_value: record.base_value,
        cgst: record.cgst,
        sgst: record.sgst,
        igst: record.igst,
        cess: record.cess,
        tds: record.tds,
        rate: record.rate,
        payout: record.payout,
      };
    });
    this.excelData.Summary = transformedSummary;
    let isDataNotEmpty = this.isDataEmpty(this.excelData);

    if (isDataNotEmpty) {
      Swal.fire('warning', 'There is no data to download', 'warning');

      return;
    }
    const workbook = XLSX.utils.book_new();
    Object.keys(this.excelData).forEach((key) => {
      const sheet = XLSX.utils.json_to_sheet(this.excelData[key]);
      XLSX.utils.book_append_sheet(workbook, sheet, key);
    });
    XLSX.writeFile(workbook, 'PurchaseReceipt.xlsx');
  }

  mapToExpensePayout(res: Array<any>) {
    return res.map((expense) => {
      return {
        supplier: expense.supplier_id,
        expense_type: expense.expense_type,
        payout: expense.payout,
      };
    });
  }

  mapToPrPayout(res: any) {
    return {
      supplier: res.supplier_id,
      expense_type: 0,
      payout: res.payout,
    };
  }

  async approvePr() {
    try {
      const body = this.mapToApporveBody();
      console.log('approve body is the', body);

      this.loading = true;
      const res = await this.purchaseReceiptService.updatePrAttributes(
        body,
        this.id
      );
      this.loading = false;
      await this.getPurchaseReceiptDetails();
      Swal.fire(
        'success',
        'You Successfully Approved the Purchase receipt',
        'success'
      );
    } catch (error) {}
  }
  mapToApporveBody() {
    const todayDate = new Date();
    const date = getDateFormat(todayDate);
    return [
      {
        status: PurchaseOrderStatus.Approved,
      },
      {
        approval_by: this.vendorId,
      },
      {
        approved_date: date,
      },
    ];
  }

  async completeGrn() {
    const dialogRef = this.dialog.open(CompleteGrnComponent, {
      width: '55%',
      maxHeight: '80vh',
      data: this.id,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        // await this.getPurchaseReceiptDetails();
        this.router.navigateByUrl('/dashboard/pocreation');
      } else {
        await this.getPurchaseReceiptDetails();
      }
    });
  }
  mapToItems(res: Array<any>) {
    return res.map((item) => {
      return {
        id: item.id,
        receipt_id: item.receipt_id,
        sku_id: item.sku_id,
        sku_name: item.sku_name,
        upc: item.upc,
        uom_type: item.uom_type,
        uom_requested_qty: item.uom_requested_qty,
        uom_actual_qty: item.uom_actual_qty,
        requested_in_pcs: item.requested_in_pcs,
        actual_in_pcs: item.actual_in_pcs,
        mrp: item.mrp,
        tax_type: item.tax_type,
        gst: item.gst,
        cess: item.cess,
        requested_invoice_amount: item.requested_invoice_amount,
        tax_amount: item.tax_amount,
        taxable_amount: item.taxable_amount,
        tcs_percent: item.tcs_percent,
        tcs_amount: item.tcs_amount,
        cess_amount: item.cess_amount,
        net_payable: item.net_payable,
        invoiced_amount: item.invoiced_amount,
        final_bill_value: item.final_bill_value,
        weight: item.weight,
        rate_per_pc: item.rate_per_pc,
        unloading_charges: item.unloading_charges,
        loading_charges: item.loading_charges,
        brokerage: item.brokerage,
        discount: item.discount,
        transport_charges: item.transport_charges,
        purchase_credit: item.purchase_credit,
        landing_cost: item.landing_cost,
        cgst_amount: item.cgst_amount,
        sgst_amount: item.sgst_amount,
        igst_amount: item.igst_amount,
      };
    });
  }

  mapToVrsItems(skus: Array<any>) {
    return skus.map((item) => {
      return [
        {
          item_code: item.sku_id,
          qty: item.actual_in_pcs,
          rate: item.rate_per_pc,
        },
      ];
    });
  }

  mapToExpense(res: Array<any>) {
    return res.map((expense) => {
      return {
        id: expense.id,
        expense_id: expense.expense_id,
        receipt_id: expense.receipt_id,
        expense_type: expense.expense_type,
        total_value: expense.total_value,
        supplier_id: expense.supplier_id,
        distribute_based_on: expense.distribute_based_on,
        charge_type: expense.charge_type,
        payout: expense.payout,
        tds_percent: expense.tds_percent,
        tds_value: expense.tds_value,
        discount_percent: expense.discount_percent,
      };
    });
  }

  isButtonShow() {
    const status = this.res.status;
    switch (status) {
      case PurchaseOrderStatus.Created:
        this.buttonShow.isSaveShow = true;
        this.buttonShow.isRequestForGrnShow = true;
        this.buttonShow.isAddExpenseShow = true;
        this.buttonShow.isAddProductShow = true;
        this.buttonShow.isItemEditShow = true;
        this.buttonShow.isExpenseEditShow = true;
        this.buttonShow.isItemDeleteShow = true;
        this.buttonShow.isExpenseDeleteShow = true;
        break;
      case PurchaseOrderStatus.RequestedForGRN:
        this.buttonShow.isGrnShow = true;
        this.buttonShow.isCompleteGrnShow = true;
        this.buttonShow.isItemDeleteShow = false;
        this.buttonShow.isRequestForGrnShow = false;
        this.buttonShow.isAddProductShow = false;
        this.buttonShow.isSaveShow = true;
        this.buttonShow.isRequestForGrnShow = false;
        this.buttonShow.isAddExpenseShow = false;
        this.buttonShow.isAddProductShow = false;
        this.buttonShow.isItemEditShow = false;
        this.buttonShow.isExpenseEditShow = false;
        this.buttonShow.isItemDeleteShow = false;

        break;
      case PurchaseOrderStatus.GRNCompleted:
        this.buttonShow.isSaveShow = true;
        this.buttonShow.isRequestForApprovalShow = true;
        this.buttonShow.isAddExpenseShow = true;
        this.buttonShow.isItemEditShow = true;
        this.buttonShow.isExpenseEditShow = true;
        this.buttonShow.isCompleteGrnShow = false;
        this.buttonShow.isExpenseDeleteShow = true;
        this.buttonShow.isGrnShow = false;

        break;
      case PurchaseOrderStatus.RequestedForApproval:
        this.buttonShow.isApproveShow = true;
        this.buttonShow.isRequestForApprovalShow = false;
        this.buttonShow.isSaveShow = false;
        this.buttonShow.isAddExpenseShow = false;
        this.buttonShow.isExpenseDeleteShow = false;
        this.buttonShow.isItemEditShow = false;
        this.buttonShow.isExpenseEditShow = false;
        this.buttonShow.isUploadFilesShow = true;
        break;
      case PurchaseOrderStatus.Approved:
        this.buttonShow.isApproveShow = false;
        this.buttonShow.isUploadFilesShow = false;

        break;
      default:
        // Default case
        break;
    }
  }
  getExpenseTypeNameByID(id: number): string {
    switch (id) {
      case ExpenseType.Loading:
        return 'Loading';
      case ExpenseType.Unloading:
        return 'Unloading';
      case ExpenseType.Transport:
        return 'Transport';
      case ExpenseType.Brokerage:
        return 'Brokerage';
      case ExpenseType.Discount:
        return 'Discount';
      case ExpenseType.PurchaseCredit:
        return 'Purchase Credit';
      case ExpenseType.LoadingByBuyer:
        return 'Loading By Buyer';
      default:
        return 'Unknown';
    }
  }
  isVisible(): boolean {
    if (!this.res) {
      return false;
    }
    const status = this.res.status;
    return (
      status === PurchaseOrderStatus.Created ||
      status === PurchaseOrderStatus.RequestedForApproval
    );
  }
  async uploadFiles() {
    const documentTypes = ['purchase-bill', 'other-bills'];
    const urlEndpoints = ['purchase_bill', 'other_bills'];
    await Promise.all(
      documentTypes.map(async (documentType, index) => {
        const urlEndpoint = urlEndpoints[index];
        if (this.uploadedFiles.hasOwnProperty(documentType)) {
          const requestImageBody = this.uploadedFiles[documentType];
          await this.uploadAndSetUrl(
            requestImageBody,
            documentType,
            urlEndpoint
          );
        }
      })
    );
  }
  private async uploadAndSetUrl(
    requestImageBody: any,
    documentType: string,
    urlEndpoint: string
  ) {
    try {
      const res: any = await this.purchaseReceiptService.billsUpload(
        requestImageBody,
        urlEndpoint
      );

      switch (documentType) {
        case 'purchase-bill':
          this.purchaseBillUrl = res.data.url;
          break;
        case 'other-bills':
          this.otherBillsUrl = res.data.url;
          break;
        default:
          break;
      }
    } catch (error) {}
  }
  async onFileChange(event: any, documentType: string) {
    const input = event.target;
    if (event.target.files) {
      let file = event.target.files[0];
      const timestamp = Date.now();
      console.log('timestamp is the', timestamp);

      let filename = file.name;
      const fileExtension = this.getFileExtension(file.name);
      const updatedfilename = filename.replace(
        `.${fileExtension}`,
        `_${timestamp}.${fileExtension}`
      );
      console.log('updated file name is the', updatedfilename);

      file = new File([file], updatedfilename, {
        type: file.type,
        lastModified: file.lastModified,
      });
      console.log('file is the', file);

      filename = file.name;
      const reader = new FileReader();
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      reader.onload = async (event) => {
        const result = event?.target?.result;

        if (typeof result === 'string') {
          const base64String: string = await this.compressAndConvertToBase64(
            file,
            options
          );
          this.uploadedFiles[documentType] = {
            fileName: filename,
            fileExtension: this.getFileExtension(filename),
            fileData: base64String,
          };
        } else if (result instanceof ArrayBuffer) {
          // Handle this case if needed
        }
      };

      reader.readAsDataURL(file);
    }
  }

  async compressAndConvertToBase64(file: File, options: any): Promise<string> {
    try {
      const fileExtension = this.getFileExtension(file.name);

      if (fileExtension != 'pdf') {
        const compressedFile = await imageCompression(file, options);
        const base64String: string = await imageCompression.getDataUrlFromFile(
          compressedFile
        );
        return base64String.split(',')[1];
      } else {
        const base64String: string = await imageCompression.getDataUrlFromFile(
          file
        );
        return base64String.split(',')[1];
      }
    } catch (error) {
      throw error;
    }
  }
  private getFileExtension(filename: any) {
    return filename.split('.').pop();
  }
  async getPurchaseReceiptDetails() {
    this.loader.open();
    await this.getPurchaseReceipt(this.id);
    await this.getPurchaseReceiptSkus(this.id);
    await this.getExpenses(this.id);
    this.isButtonShow();
    this.calculateSummary();
    this.loader.close();
  }
  disableTaxType() {
    if (
      this.res.status === PurchaseOrderStatus.RequestedForGRN ||
      this.res.status == PurchaseOrderStatus.RequestedForApproval
    ) {
      this.form.get('productTaxType')!.disable();
    } else {
      this.form.get('productTaxType')!.enable();
    }
  }
  getPrStatusText(value: number): string {
    switch (value) {
      case PurchaseOrderStatus.Created:
        return 'Created';
      case PurchaseOrderStatus.RequestedForGRN:
        return 'Requested For GRN';
      case PurchaseOrderStatus.GRNCompleted:
        return 'GRN Completed';
      case PurchaseOrderStatus.RequestedForApproval:
        return 'Requested For Approval';
      case PurchaseOrderStatus.Approved:
        return 'Approved';
      default:
        return 'Unknown Type';
    }
  }

  async calculateSummary() {
    this.finalPayouts = [];
    const procurementSupplierId = this.res.supplier_id;
    let cgst = 0;
    let sgst = 0;
    let igst = 0;
    let base_amount = 0;
    let tds_amount = 0;
    let item_discount = 0;
    let item_loading = 0;
    let cess = 0;
    this.itemsList.forEach((item: any) => {
      cgst += item.cgst_amount;
      sgst += item.sgst_amount;
      igst += item.igst_amount;
      base_amount += item.taxable_amount;
      tds_amount += +item.tds_amount;
      item_loading += item.loading_charges;
      item_discount += item.discount;
      cess += item.cess_amount;
    });
    cgst = parseFloat(cgst.toFixed(4));
    sgst = parseFloat(sgst.toFixed(4));
    igst = parseFloat(igst.toFixed(4));
    cess = parseFloat(cess.toFixed(4));
    base_amount = parseFloat(base_amount.toFixed(4));
    tds_amount = parseFloat(tds_amount.toFixed(4));
    cess = parseFloat(cess.toFixed(4));
    item_loading = +item_loading.toFixed(4);
    item_discount = +item_discount.toFixed(4);
    const base_value = base_amount;
    const prPayout = this.mapToProcurementPayout(
      this.res,
      cgst,
      sgst,
      igst,
      base_amount,
      tds_amount,
      cess,
      base_value
    );

    this.finalPayouts.push(prPayout);

    const expensePayouts = this.mapToExpPayout(this.dataSource2);

    const procurementExpenses = expensePayouts.filter(
      (expense) => expense.supplier === procurementSupplierId
    );
    let totalPayout = 0;
    let LoadingByBuyerCharges = 0;
    let purchaseCreditCharges = 0;
    for (let e = 0; e < procurementExpenses.length; e++) {
      const expense = procurementExpenses[e];
      const { expense_type, payout } = expense;

      switch (expense_type) {
        case ExpenseType.LoadingByBuyer:
          LoadingByBuyerCharges = payout;
          break;
        case ExpenseType.PurchaseCredit:
          purchaseCreditCharges = payout;
          break;
        default:
          totalPayout += payout;
          break;
      }
    }

    const netPayable =
      prPayout.payout - totalPayout + LoadingByBuyerCharges - tds_amount;
    const netPayableObj = this.mapTonetpayable(netPayable);
    this.finalPayouts = this.finalPayouts.concat(procurementExpenses);
    this.finalPayouts.push(netPayableObj);
    const supplierExpenses = expensePayouts.filter(
      (expense) => expense.supplier != procurementSupplierId
    );
    this.finalPayouts = this.finalPayouts.concat(supplierExpenses);
    this.summary = this.finalPayouts;
    return this.finalPayouts;
  }
  mapToProcurementPayout(
    res: any,
    cgst: number,
    sgst: number,
    igst: number,
    base_value: number,
    tds_amount: number,
    cess: number,
    tds_base_value: number
  ) {
    return {
      supplier: res.supplier_id,
      base_value: base_value,
      cgst: cgst,
      sgst: sgst,
      igst: igst,
      tds: tds_amount,
      cess: cess,
      rate: 0,
      payout: +res.payout,
    };
  }
  mapToExpPayout(res: Array<any>) {
    return res.map((expense) => {
      return {
        supplier: expense.supplier_id,
        expense_type: expense.expense_type,
        base_value: expense.payout,
        cgst: 0,
        sgst: 0,
        igst: 0,
        cess: 0,
        tds: 0,
        rate: expense?.discount_percent
          ? expense.discount_percent
          : expense.total_value,
        payout: expense.payout,
      };
    });
  }
  mapTonetpayable(netPayable: number) {
    return {
      expense_type: 'NetPayable',
      base_value: 0,
      cgst: 0,
      igst: 0,
      sgst: 0,
      cess: 0,
      tds: 0,
      rate: 0,
      payout: netPayable,
    };
  }

  async uploadBillofNauka() {
    try {
      this.loading = true;
      await this.uploadFiles();
      const prDetails = this.form.value;
      const body = this.mapToUploadFiles(prDetails);
      const res = await this.purchaseReceiptService.updatePrAttributes(
        body,
        this.id
      );
      this.loading = false;

      if (res.success) {
        Swal.fire('Success', 'Successfully Uploaded', 'success');
      }
      await this.getPurchaseReceiptDetails();
    } catch (error) {
      this.loading = false;
      Swal.fire(
        'Warning',
        'Uploading bills Failed, Please try again later',
        'warning'
      );
    }
  }
  mapToUploadFiles(prDetails: any) {
    return [
      {
        invoice_reference_number: prDetails.invoiceRfNo,
      },
      {
        purchase_bill: this.purchaseBillUrl || '',
      },

      {
        other_bills: this.otherBillsUrl || '',
      },
    ];
  }
}
