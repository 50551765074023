import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';

export interface DialogData {
  pricingHistory?: any;
}

@Component({
  selector: 'app-pricing-history-dialog',
  standalone: true,
  templateUrl: './pricing-history-dialog.component.html',
  styleUrls: ['./pricing-history-dialog.component.scss'],
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterLink,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatCheckboxModule,
  ],
})
export class PricingHistoryDialogComponent implements OnInit, OnDestroy {
  dataSource: any;
  private ngUnsubscribe = new Subject<void>();
  displayedColumns = [
    'sNo',
    'dateTime',
    'sellingPrice',
    'mrp'
  ];

  constructor(
    public dialogRef: MatDialogRef<PricingHistoryDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {

  }

  async ngOnInit() {
    this.dataSource = this.mapPricingData(this.data);
  }

  async ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  close(){
    this.dialogRef.close()
  }

  mapPricingData(data: any){
  return data.map((item: any) => ({
      skuId: item.sku_id,
      vendorId: item.vendor_id,
      sellingPrice: item.selling_price,
      maximumRetailPrice: item.maximum_retail_price,
      margin: item.margin,
      createdAt: new Date(item.created_at).toLocaleString('en-GB', { hour12: false }).replace(',', ''),
      prevSp: item.prev_sp,
      prevMrp: item.prev_mrp
  }));
  }


}
